























































































































































































































import i18n from 'vue-i18n';
import EventBus from '@/eventBus';

import axios from 'axios';
import { get, uniq, map } from 'lodash';
import { addHours, format } from 'date-fns';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoTagList from '@/components/Organisms/co-TagList/coTagList.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoEventTicketCreateEdit from '@/components/Organisms/co-event-ticket-create-edit/CoEventTicketCreateEdit.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import { tr } from 'date-fns/locale';

export default {
    name: 'EventCreate',
    i18n: {
        messages: {
            en: {
                addaddress: 'Add a new address',
                saveasdraft: 'Save as Draft',
                saveandpublish: 'Save and Publish',
                failed: "Couldn't create event",
            },
            de: {
                addaddress: 'Neue Adresse hinzufügen',
                saveasdraft: 'Als Entwurf speichern',
                saveandpublish: 'Speichern und veröffentlichen',
                failed: 'Event konnte nicht erstellt werden',
            },
        },
    },
    components: {
        CoButton,
        CoRoundButton,
        CoHeadline,
        CoCard,
        CoFormGroup,
        CoInput,
        CoSelect,
        CoTagList,
        CoTable,
        CoText,
        CoAlert,
        CoEventTicketCreateEdit,
        CoDropdown,
        CoDropdownItem,
    },
    props: {
        me: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        eventTicketsOn: {
            type: Boolean,
            required: false,
            default: false,
        },
        isPaymentsEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            event: {},

            types: [],
            selectedType: null,
            locations: [],
            selectedLocation: '',
            customLocation: {
                Name: '',
                Address: '',
                ZipCode: '',
                City: '',
                Country: '',
            },

            todayDate: new Date().toISOString().split('T')[0],
            startDate: '',
            startTime: '',

            endDate: '',
            endTime: '',

            taxRates: [],

            loading: false,
            loadingTaxRate: false,
            ticketsLoading: false,
        };
    },
    watch: {
        startDate(val) {
            // if end date is before start date, set end date to start date

            // convert to timestamp
            const start = new Date(val);
            const end = new Date(this.endDate);

            if (end < start) {
                this.endDate = val;
            }
        },

        startTime(val) {
            // if end time is before start time, set end time to start time
            // compose start date and time
            const start = new Date(`${this.startDate}T${this.startTime}`);
            const end = new Date(`${this.endDate}T${this.endTime}`);
            // convert to timestamp

            if (end <= start) {
                const tmp = addHours(start, 1);
                this.endTime = format(tmp, 'HH:mm');
            }
        },
    },
    computed: {
        ticketColumns() {
            if (this.$isMobile) {
                return [
                    { key: 'Name', title: this.$t('labels.name') },
                    { key: 'actions', title: '' },
                ];
            }

            return [
                { key: 'Name', title: this.$t('labels.name') },
                { key: 'actions', title: '' },
            ];
        },
        amIAdmin() {
            return get(this.me, 'Permissions', '').includes('space_admin');
        },
        canICreate() {
            if (this.loading) {
                return false;
            }
            // check if name is set
            // check if start date is set and in the future
            // check if end date is set and in the future and after start date
            if (!this.event.Title) {
                return false;
            }

            if (!this.startDate) {
                return false;
            }

            if (!this.startTime) {
                return false;
            }

            if (!this.endDate) {
                return false;
            }

            if (!this.endTime) {
                return false;
            }

            // compose start date and time
            const start = new Date(`${this.startDate}T${this.startTime}`);
            const end = new Date(`${this.endDate}T${this.endTime}`);

            if (end < start) {
                return false;
            }
            return true;
        },
    },
    created() {
        this.getEventTypes();
        this.getLocations();
        this.getTaxRates();
    },
    mounted() {
        this.startDate = this.todayDate;
        this.endDate = this.todayDate;

        // start time is now
        const now = new Date();
        const nowPlusOneHour = addHours(new Date(), 1);

        this.startTime = format(now, 'HH:mm');
        // end time is now + 1 hour
        this.endTime = format(nowPlusOneHour, 'HH:mm');

        // set focus to title
        this.$refs.title.focus();
    },
    methods: {
        get,
        updateEventTicket(newTicket) {
            // replace ticket in event.Tickets with new ticket by ID
            this.event.Tickets = this.event.Tickets.map((ticket) => {
                if (ticket.ID === newTicket.ID) {
                    return newTicket;
                }
                return ticket;
            });

            this.ticketsLoading = true;
            this.ticketsLoading = false;
            this.$refs.updateEventTicket.close();
        },
        createEventTicket(ticket) {
            this.ticketsLoading = true;
            if (!this.event.Tickets) {
                this.event.Tickets = [];
            }
            this.event.Tickets.push(ticket);
            this.ticketsLoading = false;
        },

        getTaxRateForTicket(ticket) {
            const taxrate = this.taxRates.find((taxrate) => taxrate.ID === ticket.TaxRateID);

            if (!taxrate) {
                return '-';
            }

            if (!taxrate.Percentage) {
                return '0%';
            }

            return `${taxrate.Percentage}%`;
        },

        // open payment settings page in new tab
        openPaymentSettings() {
            window.open('/admin/monetization/payments/stripe', '_blank');
        },
        discard() {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        saveAsDraft() {
            this.event.Draft = true;
            this.createEvent();
        },
        saveAndPublish() {
            this.event.Draft = false;
            this.createEvent();
        },
        selectType(type) {
            this.selectedType = type;
        },
        selectLocation(location) {
            this.selectedLocation = location;
        },
        tagsChanged(tags) {
            const tmp = uniq(tags);
            this.event.Tags = tmp;
        },
        isEnabled(feature) {
            if (!this.$unleash) {
                console.error('Unleash not available');
                return false;
            }
            return this.$unleash.isEnabled(feature);
        },

        createEvent() {
            this.loading = true;
            if (!this.$store) {
                console.error('Store not available');
                this.loading = false;
                return;
            }

            if (this.selectedType) {
                this.event.EventType = [this.selectedType.Value];
            }

            if (get(this.selectedLocation, 'Value', '') === 'custom') {
                this.event.Location = '';
                if (this.customLocation.Name) {
                    this.event.Location += `${this.customLocation.Name}`;
                }
                if (this.customLocation.Address) {
                    this.event.Location += `${this.event.Location ? ', ' : ''}${this.customLocation.Address}`;
                }
                if (this.customLocation.ZipCode) {
                    this.event.Location += `${this.event.Location ? ', ' : ''}${this.customLocation.ZipCode}`;
                }
                if (this.customLocation.City) {
                    if (this.customLocation.ZipCode) {
                        this.event.Location += ` `;
                    } else {
                        this.event.Location += `, `;
                    }
                    this.event.Location += `${this.customLocation.City}`;
                }
                if (this.customLocation.Country) {
                    this.event.Location += `${this.event.Location ? ', ' : ''}${this.customLocation.Country}`;
                }
            } else {
                this.event.Location = this.selectedLocation.Name;
            }

            const start = new Date(`${this.startDate}T${this.startTime}`);
            const end = new Date(`${this.endDate}T${this.endTime}`);
            // convert to unix timestamp
            this.event.StartDate = start.getTime();
            this.event.EndDate = end.getTime();

            // devide by 1000 to get seconds and round to integer and covert to string
            this.event.StartDate = Math.round(this.event.StartDate / 1000).toString();
            this.event.EndDate = Math.round(this.event.EndDate / 1000).toString();

            this.$store
                .dispatch('createEvent', this.event)
                .then((response) => {
                    const message = {
                        Message: this.$t('labels.eventcreated'),
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.$router.push(`/event/${response.Slug}`);

                    this.loading = false;
                })
                .catch((error) => {
                    const message = {
                        Message: this.$t('failed'),
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },

        getEventTypes() {
            axios
                .get('/event/type/list')
                .then((response) => {
                    if (response && response.data && response.data.objects) {
                        this.types = response.data.objects.map((type) => ({
                            Name: type.Value,
                            Value: type.Slug,
                            Disabled: false,
                        }));
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {});
        },

        getLocations() {
            axios
                .get('/booking/v2/locations')
                .then((response) => {
                    if (response && response.data && response.data.Locations) {
                        this.locations = response.data.Locations.map((location) => ({
                            Name: location.Address ? `${location.Name}, ${location.Address}` : location.Name,
                            Value: location.Address ? `${location.Name}, ${location.Address}` : location.Name,
                            Disabled: false,
                            Address: location.Address ? location.Address : '',
                        }));

                        // if no custom location in list then
                        //  add custom location
                        if (!this.locations.find((l) => l.Value === 'custom')) {
                            this.locations.push({
                                Name: this.$t('labels.addaddress'),
                                Value: 'custom',
                                Disabled: false,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {});
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/space/tax/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
    },
};
