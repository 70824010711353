















































import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoDropdown from '../co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../co-dropdown-item/CoDropdownItem.vue';

interface EventTicket {
    ID?: string;
    Name: string;
    Description?: string;
    PriceInCents: number;
    TaxRateID?: string;
    Limit: number;
    CreatedAt?: number;
    UpdatedAt?: number;
    CancellationNotice?: number;
    CancellationFee?: number;
    TermsAndConditions?: string;
    AssociatedSessionID?: string;
    PaymentURL: string;
}

export default {
    name: 'CoMyTicket',
    components: {
        CoIcon,
        CoCard,
        CoHeadline,
        CoText,
        CoDropdown,
        CoDropdownItem,
        CoRoundButton,
    },
    props: {
        ticket: {
            type: Object as () => EventTicket,
            required: true,
        },
        myName: {
            type: String,
            required: true,
        },
    },
    computed: {
        iconName() {
            return this.selected ? 'circle-fill' : 'circle';
        },
        classes() {
            return {
                default: !this.ticket.SoldOut,
                'sold-out': this.ticket.SoldOut,
            };
        },
    },
    watch: {},
    data() {
        return {};
    },
    methods: {
        toInvoices() {
            this.$router.push('/account/billing/invoices');
        },
    },
};
