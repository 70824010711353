<template>
    <!-- plans -->
    <div class="d-flex flex-column justify-content-center align-items-center w-100">
        <div class="w-100">
            <CoHeadline class="mb-4 px-3" :level="1">
                {{ $t('messages.jointhecommunity', { space: this.$store.state.space.Name }) }}
            </CoHeadline>
        </div>
        <div class="py-3 w-100">
            <CoButton @onClick="$router.push('/plans')" type="text" class="pl-0">
                <CoIcon name="arrow-left" fill="co-darkgrey" />
                <CoText variant="mute" class="ml-1 mb-0">{{ $t('labels.back') }}</CoText>
            </CoButton>
        </div>

        <coPlanDetail :plan="plan" class="pb-5">
            <template v-slot:header-button>
                <coButton @click="choosePlan" variant="primary" :label="$t('labels.planchoose')" />
            </template>
        </coPlanDetail>
    </div>
</template>

<script>
import axios from 'axios';
import Vuei18n from 'vue-i18n';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoPlanDetail from '@/components/Organisms/co-plan-detail/CoPlanDetail.vue';
import { get } from 'lodash';

export default {
    name: 'PlanDetails',
    i18n: {
        messages: {
            en: {
                messages: {
                    accountexists: 'Already have an account?',
                    jointhecommunity: 'Join the {space} Community',
                    noplanavailable: 'There are currently no plans available for you.',
                },
            },
            de: {
                messages: {
                    accountexists: 'Hast du schon einen Account?',
                    jointhecommunity: 'Trete der {space} Community bei',
                    noplanavailable: 'Derzeit sind keine Tarife verfügbar.',
                },
            },
        },
    },
    data() {
        return {
            plan: null,
            spaceName: get(this, '$store.state.space.Name', null),
        };
    },
    mounted() {
        this.getPlan(this.$route.params.id);
    },
    methods: {
        calculatePrice(priceInCents, taxRate) {
            if (taxRate && taxRate.Inclusive) {
                const price = priceInCents / 100.0;
                return price.toFixed(2);
            }
            const price = priceInCents / 100.0;
            const totalPrice = (price * (taxRate.Percentage / 100 + 1)).toFixed(2);
            return totalPrice;
        },
        choosePlan() {
            this.$store.commit('SET_PLAN', this.plan);
            this.$router.push(`/registerv2`);
        },
        getPlan(id) {
            this.loading = true;

            axios({
                method: 'GET',
                url: `/space/plan/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plan = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
};
</script>
