var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CoModal',{ref:"postmodal",attrs:{"closeOnClickAway":false,"fullScreen":_vm.isMobile,"size":"md"},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_c('div',{staticClass:"d-flex"},[_c('CoIcon',{staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.$t('labels.close'),"name":"x-lg"},nativeOn:{"click":function($event){return _vm.discard($event)}}}),_c('div',{staticClass:"d-flex align-items-center ml-auto"},[_c('CoThumbnail',{attrs:{"imageURL":_vm.user.ImageURL,"alt":_vm.user.Name,"clickable":false,"content-type":"user","round":""}})],1),_c('CoButton',{staticClass:"ml-2",attrs:{"disabled":!_vm.hasContent || _vm.loading,"label":_vm.editMode ? _vm.$t('labels.save') : _vm.$t('labels.postbtn'),"loading":_vm.loading,"variant":"primary"},on:{"click":function($event){_vm.hasContent && !_vm.loading ? _vm.postSubmit(_vm.editMode) : null}}})],1)]}},{key:"body",fn:function(){return [(_vm.parent && _vm.parentType)?_c('div',{staticClass:"post-parent-wrapper mb-3",class:{
                'is-mobile': _vm.isMobile,
            }},[_c('CoContentPreview',{attrs:{"content":Object.assign({}, _vm.parent,
                    {Type: _vm.parentType}),"variant":"compact"}})],1):_vm._e(),_c('div',{staticClass:"co-editor__post d-flex align-content-stretch",class:{
                'is-mobile': _vm.isMobile,
            }},[_c('CoEditor',{ref:"posteditor",staticClass:"flex-fill",staticStyle:{"min-height":"0"},attrs:{"actionsPosition":_vm.isMobile ? 'top-left' : 'bottom-left',"allowStyling":false,"border":false,"loading":_vm.loading,"prefilledContent":_vm.prefilledContent,"focus-on-start":"","hide-loading-indicator":"","hide-send-button":"","multiple-files":"","variant":"full"},on:{"onEscape":_vm.discard},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),((_vm.links && _vm.links.length > 0) || (_vm.featured && _vm.featured.length > 0))?_c('div',{staticClass:"featured-content-wrapper mt-3",class:{
                'is-mobile': _vm.isMobile,
            },staticStyle:{"margin-left":"-1rem","margin-right":"-1rem"}},[_vm._l((_vm.featured),function(link,index){return _c('div',{key:("post-editor-featured-" + index),staticClass:"content-preview-wrapper"},[(index === 0 && !(_vm.content.Files && _vm.content.Files.length > 0))?_c('CoContentPreview',{attrs:{"fetch":{
                        Slug: link.href,
                    }}}):_c('CoLinkPreview',{attrs:{"url":link.href,"prevent-embedding":""}})],1)}),_vm._l((_vm.links),function(link,index){return _c('div',{key:("post-editor-link-" + index)},[_c('CoLinkPreview',{staticClass:"mt-3",attrs:{"preventEmbedding":(_vm.content.Files && _vm.content.Files.length > 0) || _vm.featured.length > 0,"url":link.href}})],1)})],2):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }