<template>
    <div>
        <!-- OG/meta -->
        <vue-headful title="Create your Community - coapp" description="" />
        <div class="py-4 d-flex justify-content-center align-items-md-center">
            <div class="step-wrapper login-form p-4 bg-white round-unify text-center">
                <h2>{{ $t('success') }}</h2>
                <h3 class="mt-1">{{ $t('wait') }}</h3>
                <div v-if="!error">
                    <coLoadingIndicator />

                    <div class="w-100 mt-4">
                        <illustration type="welcome" class="my-0 illu"></illustration>
                    </div>
                </div>
                <CoAlert v-else variant="yellow" class="my-4">
                    <CoText type="p1">{{ $t('errorcreation') }}</CoText>
                </CoAlert>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import i18n from 'vue-i18n';
import { get } from 'lodash';
import Bugsnag from '@bugsnag/js';

import coLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';

export default {
    name: 'CreateSpaceAfterPayment',
    components: {
        CoAlert,
        coLoadingIndicator,
    },
    i18n: {
        messages: {
            en: {
                success: 'Great, you created your community!',
                wait: 'Please wait a second to be redirected...',
                errorcreation: 'There was an error creating your community. Please contact support via help.coapp.io',
            },
            de: {
                success: 'Super, du hast deine Community erstellt!',
                wait: 'Bitte warte einen Moment, um weitergeleitet zu werden...',
                errorcreation:
                    'Es gab einen Fehler beim Erstellen deiner Community. Bitte kontaktiere den Support über help.coapp.io',
            },
        },
    },
    data() {
        return {
            sessionID: this.$route.query.session_id,
            error: false,
        };
    },
    beforeCreate() {
        window.onload = (event) => {
            // log matomo event
            if (window && window._paq) {
                window._paq.push(['trackEvent', 'community-creation', 'community-creation-completed']);
            }
        };
    },
    async created() {
        const newSpace = JSON.parse(sessionStorage.getItem('new-space'));
        let response = {};
        let createdSpace = {};
        try {
            if (!this.sessionID || !newSpace) {
                throw new Error('No sessionId or newSpace object provided');
            }
            const request = {
                first_name: newSpace.FirstName,
                last_name: newSpace.LastName,
                email: newSpace.Email,
                password: newSpace.Password,
                space_name: get(newSpace, 'Space.Name', ''),
                timezone: get(newSpace, 'Space.Timezone', ''),
                plan_slug: newSpace.Plan,
                currency: 'EUR',
                stripe_session_id: this.sessionID,
            };
            if (request.plan_slug === 'free') {
                request.plan_slug = '';
            }
            response = await axios({
                method: 'POST',
                url: '/new-space/create',
                data: request,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            createdSpace = response.data;
        } catch (error) {
            // error handling
            Bugsnag.notify(error);
            this.error = true;
            console.warn('Error while creating new space', error);
        }
        const token = response.data.Token;
        sessionStorage.removeItem('new-space');
        this.$router.push({
            name: 'CreateSpaceSuccess',
            params: { domain: `${createdSpace.DomainName}?apptoken=${token}` },
        });
    },
};
</script>
