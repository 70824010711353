
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































import i18n from 'vue-i18n';
import EventBus from '@/eventBus';

import axios from 'axios';
import { get, uniq, map, uniqBy, upperFirst, toLower } from 'lodash';
import { format, addHours } from 'date-fns';
import JSConfetti from 'js-confetti';
import CoImage from '@/components/Atoms/co-image/CoImage.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoPill from '@/components/Atoms/co-pill/CoPill.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoPeople from '@/components/Molecules/co-people/CoPeople.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoDragDropFileUpload from '@/components/Organisms/co-drag-drop-file-upload/CoDragDropFileUpload.vue';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoContentSection from '@/components/Organisms/сo-content-section/CoContentSection.vue';
import CoShare from '@/components/Organisms/co-share/CoShare.vue';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import CoPostEditor from '@/components/Organisms/co-post-editor/coPostEditor.vue';
import CoMatch from '@/components/Molecules/co-match/CoMatch.vue';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';
import CoEventTicketCheckout from '@/components/Organisms/co-event-ticket-checkout/CoEventTicketCheckout.vue';
import CoMyTicket from '@/components/Molecules/co-my-ticket/CoMyTicket.vue';

const jsConfetti = new JSConfetti();

export default {
    name: 'EventView',
    i18n: {
        messages: {
            en: {
                labels: {
                    seeattendees: 'See Attendees',
                    updateEvent: 'Update Event Details',
                    drafteventheadline: 'This event is in a draft mode',
                    eventVisability: 'Visibility',
                    publiceventheadline: 'This event is public and visible to everyone',
                    communityeventheadline: 'This event is visible to community members only',
                    draft: 'Draft',
                    public: 'Public',
                    communityOnly: 'Community Only',
                    closePreviewAsMember: 'Close Preview',
                    previewAsMember: 'Preview as Member',
                    addsection: 'Add Section',
                    textSection: 'Text Section',
                    imageSection: 'Image Section',
                    cancelattendance: 'Cancel attendance',
                    addaddress: 'Add a new address',
                    ticketrequiredwarning: 'This event requires a ticket.',
                    getticket: 'Get Ticket',
                    google: 'Google',
                    outlook: 'Outlook',
                    ics: 'ICS',
                    dragdropimage: 'Drag an image here or click to select.',
                    suggestedsizes: 'Suggested size 1280x720px (16:9)',
                    savetocalendar: 'save to calendar',
                    noupdatesyet: 'Be the first to post something',
                    posts: 'Posts',
                },

                messages: {
                    cancelmessage: 'Are you sure about this? This event would be much nicer with you!',
                    makePublic: 'Event will be visible to everyone outside the community.',
                    communityOnly: 'Event will be visible only to community members.',
                    deleteImageHeaderWarning: 'Are you sure you want to delete the header image?',
                    draftEvent: 'Event will be saved as a draft making it hidden.',
                    eventdeleted: 'Event has been deleted.',
                    noupdatesyet: 'Be the first to post something',
                    vieweventdetailsincommunity: 'View event',
                },
            },
            de: {
                labels: {
                    seeattendees: 'Teilnehmer:innen anzeigen',
                    addaddress: 'Neue Adresse hinzufügen',
                    updateEvent: 'Veranstaltungsdetails aktualisieren',
                    drafteventheadline: 'Dieses Event ist im Entwurfsmodus',
                    eventVisability: 'Sichtbarkeit',
                    publiceventheadline: 'Dieses Event ist öffentlich und für alle sichtbar',
                    communityeventheadline: 'Dieses Event ist nur für Community-Mitglieder sichtbar',
                    draft: 'Entwurf',
                    public: 'Öffentlich',
                    communityOnly: 'Nur für die Community',
                    closePreviewAsMember: 'Vorschau schließen',
                    previewAsMember: 'Vorschau als Mitglied',
                    addsection: 'Abschnitt hinzufügen',
                    textSection: 'Textabschnitt',
                    imageSection: 'Bildabschnitt',
                    cancelattendance: 'Teilnahme absagen',
                    ticketrequiredwarning: 'Für diese Veranstaltung ist ein Ticket erforderlich.',
                    getticket: 'Tickets kaufen',

                    dragdropimage: 'Ziehe ein Bild hierher oder klicke zum Auswählen',
                    suggestedsizes: 'Empfohlene Größe 1280x720px (16:9)',
                    savetocalendar: 'im Kalender speichern',
                    noupdatesyet: 'Sei die erste Person, die etwas postet',
                    posts: 'Beiträge',
                },

                messages: {
                    cancelmessage:
                        'Bist du dir sicher, dass du das willst? Diese @:labels.event wäre viel schöner mit dir!',
                    makePublic: 'Die Veranstaltung wird für alle außerhalb der Community sichtbar.',
                    communityOnly: 'Die Veranstaltung wird nur für Community-Mitglieder sichtbar.',
                    deleteImageHeaderWarning: 'Möchtest du das Titelbild wirklich löschen?',
                    draftEvent: 'Die Veranstaltung wird als Entwurf gespeichert und versteckt.',
                    eventdeleted: 'Die Veranstaltung wurde gelöscht.',
                    noupdatesyet: 'Sei die erste Person, die etwas postet',
                    vieweventdetailsincommunity: 'Veranstaltung anzeigen',
                },
            },
        },
    },
    components: {
        CoPost,
        CoPostEditor,
        CoIcon,
        CoImage,
        CoText,
        CoButton,
        CoRoundButton,
        CoHeadline,
        CoThumbnail,
        CoDate,
        CoPill,
        CoCard,
        CoPeople,
        CoLink,
        CoTippy,
        CoSkeleton,
        CoConfirmation,
        CoDropdown,
        CoDropdownItem,
        CoDragDropFileUpload,
        CoLoadingIndicator,
        CoFormGroup,
        CoInput,
        CoSelect,
        CoContentSection,
        CoModal,
        CoShare,
        CoMatch,
        CoEventTicketCheckout,
        CoMyTicket,
    },
    props: {
        slug: {
            type: String,
            required: false,
            default: 'test',
        },
        me: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        eventTicketsOn: {
            type: Boolean,
            required: false,
            default: false,
        },
        isPaymentsEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            newImage: null,
            newImageUploading: false,
            event: {},
            createdBy: {},
            preview: false,

            todayDate: new Date().toISOString().split('T')[0],
            startDate: '',
            startTime: '',

            endDate: '',
            endTime: '',

            loadingParticipants: false,
            participants: [],

            sectionsRerenderKey: 0,

            feed: [],

            updatesNextPage: null,
            loadingUpdates: false,
            isCirclesOn: this.$store.state.circlesOn,
            nextParams: null,
            updatesRenderKey: 0,

            coOrganizersCandidates: [],
            loadingEvent: false,
            loading: false,

            types: [],
            selectedType: null,
            locations: [],
            selectedLocation: '',
            customLocation: {
                Name: '',
                Address: '',
                ZipCode: '',
                City: '',
                Country: '',
            },
            tags: [],

            taxRates: [],
            loadingTaxRates: false,
            ticketsLoading: false,

            myTicket: null,
            loadingMyTicket: false,

            ticketPurchaseState: this.$route.query.tp,
            ticketPurchaseTicketID: this.$route.query.ticket_type_id,
        };
    },
    watch: {
        startDate(val) {
            // if end date is before start date, set end date to start date

            // convert to timestamp
            const start = new Date(val);
            const end = new Date(this.endDate);

            if (end < start) {
                this.endDate = val;
            }
        },

        startTime(val) {
            // if end time is before start time, set end time to start time
            // compose start date and time
            const start = new Date(`${this.startDate}T${this.startTime}`);
            const end = new Date(`${this.endDate}T${this.endTime}`);
            // convert to timestamp

            if (end <= start) {
                const tmp = addHours(start, 1);
                this.endTime = format(tmp, 'HH:mm');
            }
        },
        types(val) {
            if (this.event.EventType && this.event.EventType.length > 0 && val) {
                this.selectedType = val.find((t) => t.Value === this.event.EventType[0]);
            }
        },

        locations(val) {
            if (this.event.Location && val) {
                this.selectedLocation = val.find((t) => t.Value === this.event.Location);
            }
        },
    },
    computed: {
        ticketColumns() {
            if (this.$isMobile) {
                return [
                    { key: 'Name', title: this.$t('labels.name') },
                    { key: 'Sold', title: this.$t('labels.sold') },
                    { key: 'actions', title: '' },
                ];
            }

            return [
                { key: 'Name', title: this.$t('labels.name') },
                { key: 'price', title: this.$t('labels.price') },
                { key: 'VAT', title: this.$t('labels.vat') },
                { key: 'Status', title: this.$t('labels.status') },
                { key: 'Sold', title: this.$t('labels.sold') },
                { key: 'actions', title: '' },
            ];
        },
        noContent() // if no sections and no image and no tags return true
        {
            return (
                (!this.event.Sections || this.event.Sections.length === 0) &&
                !this.event.ImageURL &&
                (!this.event.Tags || this.event.Tags.length === 0) &&
                (!this.feed || this.feed.length === 0)
            );
        },
        eventurl() {
            return `${window.location.origin}/events/${this.event.Slug}`;
        },
        isEventPast() {
            return this.event.EndDate < new Date().getTime() / 1000;
        },
        tagsAsText() {
            let tags = '';
            if (this.event.Tags) {
                this.event.Tags.forEach((tag) => {
                    tags += `<span data-type="cohashtag" class="cohashtag" data-id="${tag}" data-label="${tag}">${tag}</span> `;
                });
            }
            return tags;
        },
        allParticipants() {
            const p = get(this.event, 'Participants', []);
            const o = get(this.event, 'Organizers', []);
            // concat participants and organizers and remove duplicates
            let res = [...p, ...o];
            // add createdBy
            res = res.concat(this.event.CreatedBy);

            return uniq(res);
        },
        canIEditIgnorePreview() {
            return (this.amIorganising || this.amIAdmin) && this.event.SpaceID === this.me.SpaceID;
        },
        canIEdit() {
            return (this.amIorganising || this.amIAdmin) && !this.preview && this.event.SpaceID === this.me.SpaceID;
        },
        amIattending() {
            return this.event.Participants && this.event.Participants.some((p) => p === this.me.ID);
        },
        amIorganising() {
            return this.event.Organizers && this.event.Organizers.some((o) => o === this.me.ID);
        },
        amIAdmin() {
            return get(this.me, 'Permissions', '').includes('space_admin');
        },
        isSameDay() {
            const start = new Date(this.event.StartDate * 1000);
            const end = new Date(this.event.EndDate * 1000);
            return start.toDateString() === end.toDateString();
        },
        loactionWithNewLine() {
            if (!this.event || !this.event.Location) {
                return '';
            }
            return this.event.Location.replaceAll(', ', '<br/>');
        },
    },
    created() {
        this.getEvent();

        this.getTaxRates();
    },

    methods: {
        getMyTicket() {
            this.loadingMyTicket = true;
            axios
                .get(`/event/ticket/my/for-event-id/${this.event.ID}`)
                .then((response) => {
                    this.myTicket = get(response, 'data.ticket', null);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingMyTicket = false;
                });
        },

        archiveTicket(ticket) {
            this.ticketsLoading = true;
            ticket.Deleted = true;
            this.updateEventTicket(ticket);
        },
        unarchiveTicket(ticket) {
            this.ticketsLoading = true;
            ticket.Deleted = false;
            this.updateEventTicket(ticket);
        },
        updateEventTicket(newTicket) {
            // replace ticket in event.Tickets with new ticket by ID
            this.event.Tickets = this.event.Tickets.map((ticket) => {
                if (ticket.ID === newTicket.ID) {
                    return newTicket;
                }
                return ticket;
            });

            this.ticketsLoading = true;
            this.ticketsLoading = false;
            this.$refs.updateEventTicket.close();
        },
        createEventTicket(ticket) {
            this.ticketsLoading = true;
            if (!this.event.Tickets) {
                this.event.Tickets = [];
            }
            this.event.Tickets.push(ticket);
            this.ticketsLoading = false;
            this.$refs.updateEventTicket.close();
        },

        getTaxRateForTicket(ticket) {
            const taxrate = this.taxRates.find((taxrate) => taxrate.ID === ticket.TaxRateID);

            if (!taxrate) {
                return '-';
            }

            if (!taxrate.Percentage) {
                return '0%';
            }

            return `${taxrate.Percentage}%`;
        },

        // open payment settings page in new tab
        openPaymentSettings() {
            window.open('/admin/monetization/payments/stripe', '_blank');
        },
        addToCalendar(cal = 'google') {
            const calendarEvent = {
                richText: {
                    title: this.event.Title,
                    location: this.event.Location ? this.event.Location : null,
                    description: this.convertToPlain(this.event.Description),
                    start: new Date(this.event.StartDate * 1000).toISOString(),
                    end: new Date(this.event.EndDate * 1000).toISOString(),
                },
                plainText: {
                    title: this.event.Title,
                    location: this.event.Location ? this.event.Location.replaceAll(',', '\\,') : null,
                    description: this.convertToPlain(this.event.Description),
                    start: new Date(this.event.StartDate * 1000).toISOString(),
                    end: new Date(this.event.EndDate * 1000).toISOString(),
                },
            };
            // add event url to the description
            if (this.eventurl) {
                calendarEvent.richText.description += `<hr />🔗 <a href="${this.eventurl}" target="_blank">${this.$t(
                    'messages.vieweventdetailsincommunity'
                )}</a>`;
                calendarEvent.plainText.description += `
                ----------------
                🔗 ${this.$t('messages.vieweventdetailsincommunity')}: ${this.eventurl}`;
            }
            // Then fetch the link
            if (cal === 'google') {
                window.open(google(calendarEvent.richText), '_blank');
            } else if (cal === 'outlook') {
                window.open(outlook(calendarEvent.richText), '_blank');
            } else if (cal === 'ics') {
                window.open(ics(calendarEvent.plainText), '_blank');
            }
        },
        convertToPlain(html) {
            if (!html || typeof html !== 'string') {
                return '';
            }
            const tempDivElement = document.createElement('div');
            tempDivElement.innerHTML = html;
            return tempDivElement.textContent.trim() || tempDivElement.innerText.trim() || '';
        },
        openTicketURL() {
            window.open(this.event.FullURL, '_blank');
        },
        discardEventChanges() {
            this.getEvent();
        },
        isEnabled(feature) {
            if (!this.$unleash) {
                console.error('Unleash not available');
                return false;
            }
            return this.$unleash.isEnabled(feature);
        },
        selectType(type) {
            this.selectedType = type;
        },

        selectLocation(location) {
            this.selectedLocation = location;
        },
        tagsChanged(tags) {
            const tmp = uniq(tags);
            this.event.Tags = tmp;
        },
        removeOrganizer(id) {
            this.event.Organizers = this.event.Organizers.filter((o) => o !== id);
            this.participants = this.participants.filter((p) => p.ID !== id);
            this.updateEvent();
        },
        discardOrganizerCandidates() {
            this.coOrganizersCandidates = [];
        },
        saveOrganizerCandidates() {
            this.coOrganizersCandidates.forEach((candidate) => {
                // if candidate is already in organizers, skip
                if (this.event.Organizers && this.event.Organizers.includes(candidate.ID)) {
                    return;
                }

                if (!this.event.Organizers) {
                    this.event.Organizers = [];
                }
                this.event.Organizers.push(candidate.ID);

                if (!this.participants) {
                    this.participants = [];
                }

                this.participants.push(candidate);
            });

            // remove duplicates from participants by ID
            this.participants = uniqBy(this.participants, 'ID');

            // sort participants by role creator, organizer, participant
            this.participants = this.participants.sort((a, b) => {
                if (a.Role === 'creator') {
                    return -1;
                }
                if (b.Role === 'creator') {
                    return 1;
                }
                if (a.Role === 'organizer') {
                    return -1;
                }
                if (b.Role === 'organizer') {
                    return 1;
                }
                return 0;
            });

            this.updateEvent();
            this.coOrganizersCandidates = [];
        },
        searchUsers(query, source) {
            // search for users
            return new Promise((resolve, reject) => {
                // exit if query is less than 2 characters
                if (!query || query.length < 2) {
                    resolve([]);
                    return;
                }
                // fetch the search results
                axios({
                    method: 'GET',
                    url: `/search/user/${query}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source ? source.token : null,
                })
                    .then((response) => {
                        const results = get(response, 'data', []);
                        const mapped = results.map((el) => ({
                            label: get(el, 'Profile.Name', ''),
                            disabled: false,
                            value: el,
                        }));
                        resolve(mapped);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeCandidate(index) {
            this.coOrganizersCandidates.splice(index, 1);
        },
        selectOrganizerCandidate(user) {
            const u = get(user, 'value', null);
            if (!u) {
                return;
            }
            this.coOrganizersCandidates.push({
                ID: get(u, 'ID'),
                Name: get(u, 'Profile.Name'),
                Slug: get(u, 'Slug'),
                ImageURL: get(u, 'Profile.ImageURL'),
                Bio: get(u, 'Profile.Bio'),
                Role: 'organizer',
            });
        },
        showParticipantsOnSeparatePage() {
            this.$router.push(`/events/${this.event.Slug}/participants`);
        },
        showParticipants() {
            this.$refs.participantsModal.show();
        },
        saveSection(section, index) {
            this.event.Sections[index] = section;
            this.sectionsRerenderKey += 1;
            this.updateEvent();
        },
        deleteSection(index) {
            if (!this.event.Sections) {
                this.sectionsRerenderKey += 1;
                return;
            }
            this.event.Sections.splice(index, 1);
            this.sectionsRerenderKey += 1;
            this.updateEvent();
        },
        moveSectionDown(index) {
            if (!this.event.Sections) {
                this.sectionsRerenderKey += 1;
                return;
            }
            const temp = this.event.Sections[index];
            this.event.Sections[index] = this.event.Sections[index + 1];
            this.event.Sections[index + 1] = temp;
            this.sectionsRerenderKey += 1;
            this.updateEvent();
        },
        moveSectionUp(index) {
            if (!this.event.Sections) {
                this.sectionsRerenderKey += 1;
                return;
            }
            const temp = this.event.Sections[index];
            this.event.Sections[index] = this.event.Sections[index - 1];
            this.event.Sections[index - 1] = temp;
            this.sectionsRerenderKey += 1;
            this.updateEvent();
        },
        addSection($event, index) {
            if (!this.event.Sections) {
                this.event.Sections = [];
            }
            if ($event === 'text') {
                this.event.Sections.splice(index + 1, 0, {
                    Type: 0,
                    Content: '',
                    StartEditMode: true,
                });
            } else if ($event === 'image') {
                this.event.Sections.splice(index + 1, 0, {
                    Type: 1,
                    Images: [],
                    StartEditMode: true,
                });
            }

            this.sectionsRerenderKey += 1;
        },
        parseEventDates() {
            const start = new Date(this.event.StartDate * 1000);
            this.startDate = start.toISOString().split('T')[0];
            this.startTime = format(start, 'HH:mm');

            const end = new Date(this.event.EndDate * 1000);
            this.endDate = end.toISOString().split('T')[0];
            this.endTime = format(end, 'HH:mm');
        },
        get,
        map,
        upperFirst,
        toLower,
        async getEvent() {
            this.loadingEvent = true;
            try {
                const response = await axios.get(`/event/by-slug/${this.slug}`);
                this.event = response.data;

                if (get(this.event, 'Sections', []).length === 0 && this.event.Description) {
                    this.event.Sections = [
                        {
                            Type: 0,
                            Content: this.event.Description,
                        },
                    ];
                }

                if (this.eventTicketsOn) {
                    this.getMyTicket();
                }

                this.parseEventDates();
                this.getParticipants().then(() => {
                    if (this.amIattending || this.amIorganising || this.amIAdmin) {
                        this.getUpdates();
                    }
                });
                this.getEventTypes();
                this.getLocations();
            } catch (error) {
                console.error(error);
                if (error.message.includes('404')) {
                    this.$router.push('/feed/error404').catch(() => {});
                }
            }
            this.loadingEvent = false;
        },

        createPost() {
            this.$refs.posteditor.show();
        },
        addedPost(post = {}) {
            if (this.feed && this.feed.length > 0) {
                this.feed.unshift(post);
            } else {
                // celebrate the first post with some confetti :)
                jsConfetti.addConfetti();
                this.feed = [post];
            }
        },
        deletedPost(post) {
            if (this.feed && this.feed.length > 0) {
                const index = this.feed.findIndex((p) => get(p, 'object.ID') === get(post, 'object.ID'));
                if (index > -1) {
                    this.feed.splice(index, 1);
                }
            } else {
                this.feed = [];
            }
        },
        editPost(post = {}) {
            if (!post) return;
            // map post structure to editor structure
            const postToEdit = {
                ...get(post, 'object', {}),
                Files: map(get(post, 'object.Images', []), (i) => ({ url: i.ImageURL })),
                Type: 'post',
                wasUpdated: true,
            };
            this.$refs.posteditor.show(postToEdit, true);
        },
        editedPost(post = {}) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;
            if (post && this.feed) {
                // eslint-disable-next-line dot-notation
                post['wasUpdated'] = true;
                // find the post in the feed by id and update it
                const index = this.feed.findIndex((p) => get(p, 'object.ID') === get(post, 'object.ID'));
                if (index > -1) {
                    that.feed.splice(index, 1, post);
                }
            }
        },

        getUpdates() {
            this.loadingUpdates = true;
            const data = {
                EventID: this.event.ID,
                Params: this.nextParams,
            };

            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: `/event/update/list`,
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(async (response) => {
                        if (response.data.Objects) {
                            response.data.Objects.forEach(function (entry, index) {
                                this.feed.push(entry);
                            }, this);

                            if (response.data.Objects.NextParams) {
                                this.nextParams = response.data.NextParams;
                            } else {
                                this.nextParams = null;
                            }
                            // sort by date
                            this.feed.sort((a, b) => b.object.Created - a.object.Created);

                            // remove duplicates by object.id
                            this.feed = this.feed.filter(
                                (thing, index, self) => index === self.findIndex((t) => t.object.ID === thing.object.ID)
                            );

                            const fetchUsers = [];
                            this.feed.forEach((update) => {
                                fetchUsers.push(update.object.AuthorID);
                            });
                            let users = null;
                            try {
                                users = await axios({
                                    method: 'POST',
                                    url: '/user/listbyids',
                                    data: { IDS: map(uniq(fetchUsers), (id) => ({ ID: id })) },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });

                                users = map(users.data.Users, (user) => ({
                                    ID: get(user, 'ID'),
                                    Name: get(user, 'Profile.Name'),
                                    Slug: get(user, 'Slug'),
                                    ImageURL: get(user, 'Profile.ImageURL'),
                                    Bio: get(user, 'Profile.Bio'),
                                }));
                            } catch (error) {
                                console.log(error);
                            }

                            this.feed.forEach((update) => {
                                if (!update.object) return;

                                if (update.object.AuthorID || update.object.CreatedBy || update.object.AuthorID) {
                                    update.object.Author = users.find(
                                        (user) =>
                                            user.ID === update.object.AuthorID ||
                                            user.ID === update.object.CreatedBy ||
                                            user.ID === update.object.AuthorID
                                    );
                                }

                                update.object.CreatedAt = update.object.Created;

                                const tmpimages = get(update, 'object.Images', null);
                                update.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));
                            }, this);
                            this.updatesRenderKey += 1;
                        }
                        this.loadingUpdates = false;
                    })
                    .catch((error) => {
                        if (this.$route.query.commentid) {
                            this.tabIndex = 2;
                        }
                        this.loadingUpdates = false;
                        console.log(error);
                    });
            });
        },
        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 30) {
                    if (this.isCirclesOn && this.nextParams != null && !this.loading) {
                        this.getUpdates();
                    } else if (this.updatesNextPage != null && !this.isLoading) {
                        this.getUpdates();
                    }
                }
            };
        },
        attendEvent() {
            this.loadingParticipants = true;
            const request = {
                EventID: this.event.ID,
                ParticipantID: this.me.ID,
            };
            axios({
                method: 'POST',
                url: '/event/participant',
                data: request,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.event = response.data;
                        this.getParticipants().then(() => {
                            if (this.amIattending) {
                                this.getUpdates();
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loadingParticipants = false;
                });
        },
        unattendEvent() {
            this.loadingParticipants = true;
            const request = {
                EventID: this.event.ID,
                ParticipantID: this.me.ID,
            };
            axios({
                method: 'DELETE',
                url: '/event/participant',
                data: request,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.event = response.data;
                        this.getParticipants().then(() => {
                            if (!this.amIattending) {
                                this.feed = [];
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loadingParticipants = false;
                });
        },
        removeImage() {
            this.newImage = null;
            this.event.ImageURL = null;
            this.event.Images = [];
            this.updateEvent();
        },
        publishEventAsPublic() {
            this.event.Visibility = 1;
            this.event.Draft = false;
            this.updateEvent();
        },

        publishEventAsCommunityOnly() {
            this.event.Visibility = 0;
            this.event.Draft = false;
            this.updateEvent();
        },
        draftEvent() {
            this.event.Draft = true;
            this.event.Visibility = 0;
            this.updateEvent();
        },
        headerImageInput(image) {
            if (image && image.filePreview) {
                this.newImage = image.filePreview;
                this.newImageUploading = true;
                if (!this.$store) {
                    console.error('Store not available');
                    this.newImageUploading = false;
                    return;
                }
                this.$store
                    .dispatch('imageUpload', {
                        file: image.file,
                        url: '/upload/image/events',
                    })
                    .then((response) => {
                        this.event.ImageURL = response;
                        this.event.Images = [response];
                        this.updateEvent();
                    })
                    .catch((error) => {
                        console.log(error);
                        const errorMsg = {
                            Message: this.$t('messages.imageUploadError'),
                            Details: '',
                        };
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'Request Entity Too Large'
                        ) {
                            errorMsg.Message = this.$t('messages.imageUploadSizeError');
                        }
                        EventBus.$emit('ERROR', errorMsg);
                    })
                    .finally(() => {
                        this.newImageUploading = false;
                        this.newImage = null;
                    });
            }
        },

        updateEvent() {
            this.loadingEvent = true;
            if (!this.$store) {
                console.error('Store not available');
                this.loadingEvent = false;
                return;
            }

            if (this.selectedType) {
                this.event.EventType = [this.selectedType.Value];
            }

            const start = new Date(`${this.startDate}T${this.startTime}`);
            const end = new Date(`${this.endDate}T${this.endTime}`);
            // convert to unix timestamp
            this.event.StartDate = start.getTime();
            this.event.EndDate = end.getTime();

            // devide by 1000 to get seconds and round to integer and covert to string
            this.event.StartDate = Math.round(this.event.StartDate / 1000).toString();
            this.event.EndDate = Math.round(this.event.EndDate / 1000).toString();

            if (get(this.selectedLocation, 'Value', '') === 'custom') {
                this.event.Location = '';
                if (this.customLocation.Name) {
                    this.event.Location += `${this.customLocation.Name}`;
                }
                if (this.customLocation.Address) {
                    this.event.Location += `${this.event.Location ? ', ' : ''}${this.customLocation.Address}`;
                }
                if (this.customLocation.ZipCode) {
                    this.event.Location += `${this.event.Location ? ', ' : ''}${this.customLocation.ZipCode}`;
                }
                if (this.customLocation.City) {
                    if (this.customLocation.ZipCode) {
                        this.event.Location += ` `;
                    } else {
                        this.event.Location += `, `;
                    }
                    this.event.Location += `${this.customLocation.City}`;
                }
                if (this.customLocation.Country) {
                    this.event.Location += `${this.event.Location ? ', ' : ''}${this.customLocation.Country}`;
                }
            } else {
                this.event.Location = this.selectedLocation.Name;
            }

            // combine all sections into description field for backward compatibility
            this.event.Description = '';
            if (this.event.Sections && this.event.Sections.length > 0) {
                this.event.Sections.forEach((section) => {
                    // if image section then add images as html img tags
                    if (section.Type === 1) {
                        section.Images.forEach((img) => {
                            this.event.Description += `<img style="width: 100%;" src="${img}" alt="event image" />\n<br>`;
                        });
                    } else {
                        this.event.Description += section.Content;
                        this.event.Description += '\n<br>';
                    }
                });
            } else {
                this.event.Description = '';
            }

            this.$store
                .dispatch('updateEvent', this.event)
                .then((response) => {
                    const message = {
                        Message: this.$t('labels.changessaved'),
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.loadingEvent = false;
                    this.parseEventDates();
                    this.getParticipants();
                    this.getEventTypes();
                    this.getLocations();
                })
                .catch((error) => {
                    const message = {
                        Message: this.$t('labels.failedToSaveChanges'),
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loadingEvent = false;
                });
        },

        getEventTypes() {
            axios
                .get('/event/type/list')
                .then((response) => {
                    if (response && response.data && response.data.objects) {
                        this.types = response.data.objects.map((type) => ({
                            Name: type.Value,
                            Value: type.Slug,
                            Disabled: false,
                        }));
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {});
        },

        getLocations() {
            this.locations = [];
            axios
                .get('/booking/v2/locations')
                .then((response) => {
                    if (response && response.data && response.data.Locations) {
                        if (this.event.Location) {
                            this.selectedLocation = {
                                Name: this.event.Location,
                                Value: this.event.Location,
                                Disabled: false,
                            };

                            this.locations.push({
                                Name: this.event.Location,
                                Value: this.event.Location,
                                Disabled: false,
                            });
                        }

                        this.locations = this.locations.concat(
                            response.data.Locations.map((location) => ({
                                Name: location.Address ? `${location.Name}, ${location.Address}` : location.Name,
                                Value: location.Address ? `${location.Name}, ${location.Address}` : location.Name,
                                Disabled: false,
                                Address: location.Address ? location.Address : '',
                            }))
                        );

                        this.locations = uniqBy(this.locations, 'Name');

                        // if no custom location in list then
                        //  add custom location
                        if (!this.locations.find((l) => l.Value === 'custom')) {
                            this.locations.push({
                                Name: this.$t('labels.addaddress'),
                                Value: 'custom',
                                Disabled: false,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {});
        },

        getUserRoleInEvent(id) {
            if (this.event.CreatedBy === id) {
                return 'creator';
            }
            if (this.event.Organizers && this.event.Organizers.includes(id)) {
                return 'organizer';
            }
            return 'participant';
        },

        async getParticipants() {
            this.loadingParticipants = true;

            try {
                const users = await axios({
                    method: 'POST',
                    url: '/user/listbyids',
                    data: { IDS: map(uniq(this.allParticipants), (id) => ({ ID: id })) },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                this.participants = map(users.data.Users, (user) => ({
                    ID: get(user, 'ID'),
                    Name: get(user, 'Profile.Name'),
                    Slug: get(user, 'Slug'),
                    ImageURL: get(user, 'Profile.ImageURL'),
                    Bio: get(user, 'Profile.Bio'),
                    Role: this.getUserRoleInEvent(get(user, 'ID')),
                    Score: get(user, 'Score', 0),
                }));

                // sort participants by role creator, organizer, participant
                this.participants = this.participants.sort((a, b) => {
                    if (a.Role === 'creator') {
                        return -1;
                    }
                    if (b.Role === 'creator') {
                        return 1;
                    }
                    if (a.Role === 'organizer') {
                        return -1;
                    }
                    if (b.Role === 'organizer') {
                        return 1;
                    }
                    return 0;
                });

                // find createdBy user
                this.createdBy = this.participants.find((p) => p.ID === this.event.CreatedBy);
                this.loadingParticipants = false;
            } catch (error) {
                console.log(error);
                this.loadingParticipants = false;
            }
        },

        deleteEvent() {
            this.loadingEvent = true;

            axios({
                url: `/event`,
                method: 'DELETE',
                data: this.event,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    this.$router.push('/events');
                    EventBus.$emit('INFO', {
                        Message: this.$t('messages.eventdeleted'),
                        Details: '',
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.loadingEvent = false;

                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedGeneric'),
                        Details: error,
                    });
                });
        },
        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/space/tax/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
    },
};
