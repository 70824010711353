









































































import i18n from 'vue-i18n';
import axios from 'axios';
import { set, clone, uniq, map, get, uniqueId, isArray, update } from 'lodash';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';
import CoPostEditor from '@/components/Organisms/co-post-editor/coPostEditor.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import { User, Image, Space } from '@/types/GlobalTypes.ts';
import EventBus from '../../../../eventBus';

// eslint-disable-next-line import/no-extraneous-dependencies
import JSConfetti from 'js-confetti';
const jsConfetti = new JSConfetti();

export default {
    components: { CoPost, CoPostEditor, CoButton, CoInput, CoCard },
    i18n: {
        messages: {
            en: {
                letscelebrate: "Let's celebrate!",
                whatyourcommunityisabout: 'Start by explaining what this community is about.',
                createfirstpost: 'Create your first post',
            },
            de: {
                letscelebrate: 'Lass uns feiern!',
                whatyourcommunityisabout: 'Starte am besten damit zu erklären, was in deiner Community wichtig ist.',
                createfirstpost: 'Erstelle deinen ersten Post',
            },
        },
    },
    name: 'Timeline',
    data() {
        return {
            feedBank: [],
            feed: [],
            feedNextPage: null,
            loading: true,
            newPost: null,
            isMarketOn: this.$store.state.space.MarkeplaceOn,
            circlesOn: this.$store.state.circlesOn,
            nextParams: null,
            focusPostID: get(this, '$route.query.focuspostid', ''),
        };
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();
        this.loading = true;
        this.feed = [];
        this.feedNextPage = null;
        this.getFeed();

        EventBus.$on('FOCUS_POST', () => {
            this.$refs.createPost.setFocus();
            window.scrollTo(0, this.$refs.createPost.offsetTop);
        });
        // focus create post input if the query is new-post
        if (this.$route.query.a === 'new-post') {
            this.$refs.createPost.setFocus();
        }
    },
    watch: {
        '$route.query.focuspostid': {
            handler: function (to, from) {
                this.focusPostID = to;
            },
            immediate: true,
        },
    },
    methods: {
        generateUniqueID(prefix) {
            return prefix ? uniqueId(prefix) : uniqueId();
        },

        addedPost(post = {}) {
            if (this.feed && this.feed.length > 0) {
                this.feed.unshift(post);
            } else {
                //celebrate the first post with some confetti :)
                jsConfetti.addConfetti();
                this.feed = [post];
            }
        },

        editedPost(post = {}) {
            const that = this;
            if (post && this.feed) {
                post['wasUpdated'] = true;
                // find the post in the feed by id and update it
                const index = this.feed.findIndex((p) => get(p, 'object.ID') === get(post, 'object.ID'));
                if (index > -1) {
                    that.feed.splice(index, 1, post);
                }
            }
        },

        editPost(post: Object = null) {
            if (!post) return;
            // map post structure to editor structure
            const postToEdit = {
                ...get(post, 'object', {}),
                Files: get(post, 'object.Images', []).map((image) => {
                    return { url: image.ImageURL };
                }),
                wasUpdated: true,
            };
            this.$refs.posteditor.show(postToEdit, true);
        },
        scrollRefIntoView(ref) {
            const el = get(this, `$refs[${ref}][0]`, {});
            if (el.$el) el.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        getFeed() {
            this.loading = true;

            const data = {
                Params: this.nextParams,
            };
            axios({
                method: 'POST',
                url: '/dashboard/feed/tmp',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data && response.data.Objects && response.data.Objects.length > 0) {
                        let posts = response.data.Objects;

                        // filter out the jobs if the market is disabled
                        if (!this.isMarketOn) {
                            posts = posts.filter((item) => item.type !== 'job');
                        }
                        // map content to post object structure
                        const fetchUsers = [];
                        posts.forEach((entry) => {
                            // skip if entry.object does not exist
                            if (!entry.object) return;

                            this.feedBank.push(entry);
                            if (entry.object.AuthorID || entry.object.CreatedBy || entry.object.UserID) {
                                fetchUsers.push(entry.object.AuthorID || entry.object.CreatedBy || entry.object.UserID);
                            }
                        }, this);
                        // fetch and populate post author info
                        let users = null;
                        try {
                            users = await axios({
                                method: 'POST',
                                url: '/user/listbyids',
                                data: { IDS: map(uniq(fetchUsers), (id) => ({ ID: id })) },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            users = map(users.data.Users, (user) => ({
                                ID: get(user, 'ID'),
                                Name: get(user, 'Profile.Name'),
                                Slug: get(user, 'Slug'),
                                ImageURL: get(user, 'Profile.ImageURL'),
                                Bio: get(user, 'Profile.Bio'),
                            }));
                        } catch (error) {
                            console.log(error);
                        }
                        // map post object to co-post structure
                        this.feedBank.forEach((entry) => {
                            // skip if entry.object does not exist
                            if (!entry.object) return;
                            // assign author infos
                            if (entry.object.AuthorID || entry.object.CreatedBy || entry.object.UserID) {
                                entry.object.Author = users.find(
                                    (user) =>
                                        user.ID === entry.object.AuthorID ||
                                        user.ID === entry.object.CreatedBy ||
                                        user.ID === entry.object.UserID
                                );
                            }
                            // assign space infos
                            entry.space ? (entry.object.Space = entry.space) : null;
                            // assign images for posts and page-updates (project-updates)
                            let tmpimages = get(entry, 'object.Images', null) || get(entry, 'object.ImageURLs', []);
                            entry.object.Images = map(tmpimages, (i) => {
                                return { ImageURL: i };
                            });
                            //map type

                            entry.object.Type = entry.type;
                            // assign parent content
                            if (entry.type !== 'post') {
                                // generate post text for non post content
                                entry.object.Text ? null : (entry.object.Text = null);
                                // parent content default mapping
                                entry.object.ParentContent = {
                                    ...entry.object,
                                    Type: entry.type,
                                };

                                // specific parent content mapping
                                switch (entry.object.ParentContent.Type) {
                                    case 'job':
                                    case 'event':
                                    case 'market-item':
                                        //remove images from post content if content is not post or project-update
                                        entry.object.Images = null;
                                        break;
                                    case 'project':
                                        entry.object.ParentContent.Images = isArray(entry.object.ParentContent.ImageURL)
                                            ? map(entry.object.ImageURL, (i) => {
                                                  if (typeof i === 'string' && i.match('/img/Platform_Gradients')) {
                                                      return false;
                                                  }
                                                  return { ImageURL: i };
                                              })
                                            : null;
                                        entry.object.CreatedAt = entry.object.Created;
                                        break;
                                    case 'project-update':
                                        entry.object.ParentContent.Fetch = {
                                            ID: entry.object.ProjectID,
                                            Type: 'project',
                                        };
                                        entry.object.CreatedAt = entry.object.Created;
                                        break;
                                }
                            }
                        }, this);
                        this.feedBank.sort((a, b) => a.object.Score - b.object.Score);
                        if (this.feedBank.length > 10) {
                            this.feed.push(...this.feedBank.slice(0, 10));
                            this.feedBank = this.feedBank.slice(10);
                        } else {
                            this.feed.push(...this.feedBank);
                            this.feedBank = [];
                        }

                        if (response.data.NextParams) {
                            this.nextParams = response.data.NextParams;
                        } else {
                            this.nextParams = null;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        scroll() {
            window.onscroll = () => {
                const feedContainer = this.$refs['feed-container'];
                if (!feedContainer) return;

                // get the distance from the bottom of the page to the bottom of the feed container
                const distanceFromBottom = feedContainer.getBoundingClientRect().bottom - window.innerHeight;

                const percentage = (distanceFromBottom * 100) / feedContainer.clientHeight;

                if (percentage < 10) {
                    if (this.feedBank.length !== 0 && this.feedBank.length > 10) {
                        this.feed.push(...this.feedBank.slice(0, 10));
                        this.feedBank = this.feedBank.slice(10);
                    } else if (this.feedBank.length !== 0 && this.feedBank.length <= 10) {
                        this.feed.push(...this.feedBank);
                        this.feedBank = [];
                    } else if (
                        this.feedBank.length === 0 &&
                        (this.feedNextPage != null || this.nextParams != null) &&
                        !this.loading
                    ) {
                        this.getFeed();
                    }
                }
            };
        },
    },
};
