<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="px-sm-4">
            <b-overlay :show="saving">
                <div v-if="user.Profile">
                    <div class="m-0 w-100 px-5 px-sm-4 py-4 mt-4 bg-white round-unify">
                        <div class="d-flex flex-column flex-sm-row w-100">
                            <div class="text-center">
                                <LazyLoadImg
                                    v-if="form.Profile"
                                    :key="form.Profile.ImageURL"
                                    :src="form.Profile.ImageURL"
                                    alt=""
                                    profile-placeholder
                                    round
                                >
                                </LazyLoadImg>

                                <h2 class="mt-3">{{ form.Profile.Name }}</h2>
                                <b-button
                                    :href="`/profile/${form.Slug}`"
                                    class="mt-3"
                                    target="_blank"
                                    variant="primary"
                                >
                                    <b-icon icon="people"></b-icon>
                                    View profile
                                </b-button>
                            </div>
                            <div class="ml-sm-5 mt-5 mt-sm-0">
                                <p v-if="form.Profile.Since">
                                    Active since:
                                    {{ (form.Profile.Since + '000') | readabledate }} |
                                    {{ (form.Profile.Since + '000') | readabletime }}
                                </p>
                                <p>
                                    Last active:
                                    {{ (form.LastActivity + '000') | readabledate }} |
                                    {{ (form.LastActivity + '000') | readabletime }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex w-100 flex-column p-2 mt-4 bg-white round-unify">
                        <h2 class="ml-2 mt-2 mb-4">Change details</h2>
                        <b-form ref="createform" class="px-2 pb-3" @submit.stop.prevent="saveChanges">
                            <div class="d-flex flex-column flex-sm-row cw-100">
                                <b-form-group
                                    id="input-group-1"
                                    class="col-12 col-sm-6 col-md-4 p-0"
                                    label="First Name"
                                    label-class="label-align"
                                    label-for="first-name"
                                >
                                    <b-form-input
                                        id="first-name"
                                        v-model="form.Profile['FirstName']"
                                        class="form-control rounded-pill"
                                        placeholder="First name"
                                        required
                                        type="text"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-2"
                                    class="ml-sm-2 col-12 col-sm-6 col-md-4 p-0"
                                    label="Last Name"
                                    label-class="label-align"
                                    label-for="last-name"
                                >
                                    <b-form-input
                                        id="last-name"
                                        v-model="form.Profile['LastName']"
                                        class="form-control rounded-pill"
                                        placeholder="Last name"
                                        required
                                        type="text"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 p-0">
                                <b-form-group
                                    id="select-group-1"
                                    class=""
                                    label="Gender"
                                    label-class="label-align"
                                    label-for="gender"
                                >
                                    <b-form-select
                                        id="gender"
                                        v-model="form.Profile.Salutation"
                                        class="form-select rounded-pill"
                                        required
                                    >
                                        <option v-if="form.Profile.Salutation === undefined" :value="undefined">
                                            Select
                                        </option>
                                        <option v-if="form.Profile.Salutation === null" :value="null">Select</option>
                                        <option value="x">Non-binary</option>
                                        <option value="f">Female</option>
                                        <option value="m">Male</option>
                                        <option value="u">Undefined</option>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group
                                    v-if="$unleash.isEnabled('ProfileBirthDate')"
                                    id="input-group-1.3"
                                    label="Birthdate"
                                    label-class="label-align"
                                    label-for="birthdate"
                                >
                                    <b-form-input
                                        id="input-1.3"
                                        v-model="form.Profile.BirthDate"
                                        class="form-control rounded-pill"
                                        type="date"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    id="input-group-2"
                                    label="Email"
                                    label-class="label-align"
                                    label-for="email"
                                >
                                    <b-form-input
                                        id="email"
                                        v-model="form['Email']"
                                        class="form-control rounded-pill"
                                        placeholder="Email adress"
                                        required
                                        type="text"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    id="select-group-2"
                                    label="Permissions"
                                    label-class="label-align"
                                    label-for="permissions"
                                >
                                    <b-form-select
                                        id="permissions"
                                        v-model="permissions"
                                        class="form-select rounded-pill"
                                        required
                                    >
                                        <option value="Admin">Admin</option>
                                        <option value="User">User</option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <b-button class="mt-4" type="submit" variant="primary"> Save</b-button>
                        </b-form>
                    </div>
                    <div class="d-flex w-100 flex-column mt-4 round-unify">
                        <CoSkeleton v-if="loadingDelete"></CoSkeleton>
                        <div v-else>
                            <div v-if="isSpaceAdmin">
                                <div
                                    class="
                                        d-flex
                                        p-2 p-md-3
                                        w-100
                                        bg-secondary
                                        has-icon-left
                                        text-muted
                                        align-items-center
                                    "
                                >
                                    <CoIcon
                                        :top="true"
                                        class="icon-left align-self-baseline text-muted"
                                        name="info-circle"
                                    ></CoIcon>
                                    <div class="mt-1">
                                        This account cannot be deleted because it is the admin of this platform.
                                    </div>
                                </div>
                            </div>
                            <div v-else class="d-flex p-3 bg-white align-items-center justify-content-between">
                                <CoText class="m-0" type="p1">Delete Account</CoText>
                                <CoButton label="Delete Account" variant="danger" @click="showModal()"></CoButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>...user does not have a profile</p>
                </div>
            </b-overlay>
        </div>

        <b-modal
            v-if="form.ID"
            :id="`apply-modal-${form.ID}`"
            :ref="`apply-modal-${form.ID}`"
            :busy="deleting"
            centered
            no-fade
            ok-title="Delete"
            ok-variant="danger"
            size="md"
            title="Delete user"
            @ok="deleteUser"
        >
            <b-overlay :show="deleting">
                <p v-if="form.Profile" class="mb-1">Are you sure about deleting {{ form.Profile.Name }} ?</p>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import EventBus from '../../../../eventBus';

Vue.use(Router);
export default {
    name: 'MemberAccount',
    components: { CoButton, CoText, CoSkeleton },
    data() {
        return {
            title: 'Member',
            saving: false,
            user: {},
            form: {},
            showErrors: false,
            isMe: false,
            loader: false,
            deleting: false,
            tags: null,
            tabIndex: 0,
            currentUserValues: {},
            permissions: '',
            ID: '',

            // feed
            feed: [],
            feedNextPage: null,
            loading: false,

            projects: [],
            jobs: null,

            loadingDelete: true,
            isSpaceAdmin: false,
        };
    },
    created() {
        const { ID } = this.$route.params;
        this.adminGetUserByID(ID);
    },
    watch: {
        tabIndex() {},
        user(newVal, oldVal) {},
    },
    methods: {
        showModal() {
            this.$refs[`apply-modal-${this.form.ID}`].show();
        },
        updateName() {
            if (
                this.form.Profile.FirstName !== '' &&
                this.form.Profile.FirstName != null &&
                this.form.Profile.LastName !== '' &&
                this.form.Profile.LastName != null
            ) {
                const names = [];
                names[0] = this.form.Profile.FirstName;
                names[1] = this.form.Profile.LastName;
                this.form.Profile.Name = names.join(' ');
            } else {
                this.form.Profile.FirstName = this.user.Profile.FirstName;
                this.form.Profile.LastName = this.user.Profile.LastName;
            }
        },
        deleteUser() {
            this.deleting = true;
            this.$store
                .dispatch('adminDeleteUser', this.form.ID)
                .then((response) => {
                    this.deleting = false;
                    const message = {
                        Message: 'User deleted',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.$router.push(`/admin/community/member/list`);
                })
                .catch((error) => {
                    this.deleting = false;
                    const message = {
                        Message: 'Could not delete user',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
        saveChanges() {
            this.updateName();
            this.permissionsUpdate();
            this.saving = true;
            this.form.Email = this.form.Email.toLowerCase();
            this.$store
                .dispatch('adminUpdateOtherUser', this.form)
                .then((response) => {
                    // todo delete previuos file
                    this.saving = false;
                    const message = {
                        Message: 'Account details changed',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.$data.loader = false;
                })
                .catch((error) => {
                    this.saving = false;
                    const message = {
                        Message: 'Could not update account details.',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$data.loader = false;
                });
        },

        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.$data.user = response;
                    this.$data.form = response;

                    if (this.$data.user.Permissions.includes('space_admin')) {
                        this.$data.permissions = 'Admin';
                        this.$data.isSpaceAdmin = true;
                    } else {
                        this.$data.permissions = 'User';
                        this.$data.isSpaceAdmin = false;
                    }
                    this.loadingDelete = false;

                    if (this.user.Profile && this.user.Profile.Name) {
                        if (this.user.Profile.FirstName === '' || this.user.Profile.FirstName == null) {
                            const names = this.user.Profile.Name.split(' ');
                            this.user.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.user.Profile.LastName = names.join(' ');
                            } else {
                                this.user.Profile.LastName = names[0];
                            }
                        }
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$router.push(`/admin/community/member/list`);
                })
                .finally(() => {
                    this.loadingDelete = false;
                });
        },
        permissionsUpdate() {
            if (this.permissions === 'User') {
                this.form.Permissions = '';
            } else {
                this.form.Permissions = ' space_admin ';
            }
        },
    },
};
</script>
