























import axios from 'axios';
import Vuei18n from 'vue-i18n';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import { get } from 'lodash';
import EventBus from '../../eventBus';

export default {
    components: { CoHeadline, CoCardPlan },
    i18n: {
        messages: {
            en: {
                messages: {
                    accountexists: 'Already have an account?',
                    jointhecommunity: 'Join the {space} Community',
                    noplanavailable: 'There are currently no plans available.',
                },
            },
            de: {
                messages: {
                    accountexists: 'Hast du schon einen Account?',
                    jointhecommunity: 'Trete der {space} Community bei',
                    noplanavailable: 'Derzeit sind keine Tarife verfügbar.',
                },
            },
        },
    },
    name: 'AvailablePlansList',
    data() {
        return {
            loading: false,
            plans: [],
            spaceName: get(this, '$store.state.space.Name', null),
        };
    },
    mounted() {
        this.$store.commit('RESET_NEW_MEMBERSHIP');
        if (this.$route.query.planid) {
            this.getPlan();
        } else {
            this.listPlans();
        }
    },
    methods: {
        viewPlan(id) {
            this.$router.push(`/plans/${id}`);
        },
        getPlan() {
            axios({
                method: 'GET',
                url: `space/plan/${this.$route.query.planid}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$store.commit('SET_PLAN', response.data);
                    this.$router.push(`/registerv2`);
                })
                .catch((error) => {
                    console.error(error);
                    this.listPlans();
                    this.$router.push(`/plans`);
                });
        },
        listPlans() {
            this.loading = true;

            axios({
                method: 'GET',
                url: '/space/plan/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const plans = response.data;
                        plans.forEach((element) => {
                            this.plans.push(element);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        EventBus.$emit('ERROR', {
                            Message: 'No plans available',
                            Details: '',
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Failed to load plans',
                            Details: '',
                        });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
