




























































































































































































































import JSConfetti from 'js-confetti';

import i18n from 'vue-i18n';

import { get } from 'lodash';
import axios from 'axios';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoCardTicket from '@/components/Molecules/co-card-ticket/CoCardTicket.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import { ca } from 'date-fns/locale';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoMyTicket from '@/components/Molecules/co-my-ticket/CoMyTicket.vue';
const jsConfetti = new JSConfetti();

interface EventTicket {
    ID?: string;
    Name: string;
    Description?: string;
    PriceInCents: number;
    TaxRateID?: string;
    Limit: number;
    CreatedAt?: number;
    UpdatedAt?: number;
    CancellationNotice?: number;
    CancellationFee?: number;
    TermsAndConditions?: string;
    Deleted?: boolean;
    SoldOut?: boolean;
}

export default {
    name: 'CoEventTicketCheckout',
    components: {
        CoText,
        CoCardTicket,
        CoModal,
        CoHeadline,
        CoButton,
        CoCheckbox,
        CoLoadingIndicator,
        CoMyTicket,
    },
    props: {
        eventName: {
            type: String,
            required: false,
            default: '',
        },
        eventID: {
            type: String,
            required: true,
        },
        eventSlug: {
            type: String,
            required: true,
        },
        tickets: {
            type: Array as () => EventTicket[],
            required: false,
            default: () => [] as EventTicket[],
        },
        taxRates: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },
        currency: {
            type: String,
            required: false,
            default: '',
        },
        ticketPurchaseState: {
            type: String,
            required: false,
            default: 'buy',
            validator(value: string) {
                return ['buy', 'success', ''].includes(value);
            },
        },
    },
    data() {
        return {
            loading: false,
            selectedTicket: null as EventTicket | null,
            acceptTermsAndConditions: false,

            loadingMyTicket: false,
            myTicket: null,
        };
    },
    watch: {
        ticketPurchaseState() {
            if (this.ticketPurchaseState === 'success') {
                this.openModal();
                this.getMyTicket();
            }
        },
    },
    computed: {
        total() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            if (this.selectedTicket.TaxRateID) {
                const taxRate = this.taxRates.find((taxRate) => taxRate.ID === this.selectedTicket.TaxRateID);
                if (taxRate && !taxRate.Inclusive) {
                    const percentage = taxRate.Percentage ? taxRate.Percentage : 0;
                    const price = this.selectedTicket.PriceInCents * (1 + taxRate.Percentage / 100);
                    let vatStr = (price / 100).toFixed(2);
                    vatStr = vatStr.replace('.', ',');

                    return `(${percentage}%) ${vatStr} ${this.currencyChar}`;
                }
            }
            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');
            return `${priceStr} ${this.currencyChar}`;
        },
        vat() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            if (this.selectedTicket.TaxRateID) {
                const taxRate = this.taxRates.find((taxRate) => taxRate.ID === this.selectedTicket.TaxRateID);
                if (taxRate) {
                    const percentage = taxRate.Percentage ? taxRate.Percentage : 0;
                    const price = this.selectedTicket.PriceInCents * (taxRate.Percentage / 100);
                    let vatStr = (price / 100).toFixed(2);
                    vatStr = vatStr.replace('.', ',');

                    return `(${percentage}%) ${vatStr} ${this.currencyChar}`;
                }
            }

            return `0,00 ${this.currencyChar}`;
        },
        netPrice() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            if (this.selectedTicket.TaxRateID) {
                const taxRate = this.taxRates.find((taxRate) => taxRate.ID === this.selectedTicket.TaxRateID);
                if (taxRate && taxRate.Inclusive) {
                    const price =
                        this.selectedTicket.PriceInCents -
                        this.selectedTicket.PriceInCents * (taxRate.Percentage / 100);
                    let priceStr = (price / 100).toFixed(2);
                    priceStr = priceStr.replace('.', ',');
                    return `${priceStr} ${this.currencyChar}`;
                }
            }

            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');

            return `${priceStr} ${this.currencyChar}`;
        },

        subtotalTicketPrice() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');

            return `${priceStr} ${this.currencyChar}`;
        },
        ticketPrice() {
            if (!this.selectedTicket) {
                return `0,00 ${this.currencyChar}`;
            }

            let priceStr = (this.selectedTicket.PriceInCents / 100).toFixed(2);
            priceStr = priceStr.replace('.', ',');

            return `${priceStr} ${this.currencyChar}`;
        },

        currencyChar() {
            if (!this.currency) {
                return '€';
            }

            const currency = this.currency.toUpperCase();

            if (currency === 'EUR') {
                return '€';
            }
            if (currency === 'USD') {
                return '$';
            }

            return currency;
        },
        listTickets() {
            // Filter out deleted tickets and sort by price in ascending order also filter sold out tickets as last
            return this.tickets
                .filter((ticket) => !ticket.Deleted)
                .sort((a, b) => a.PriceInCents - b.PriceInCents)
                .sort((a, b) => (a.SoldOut ? 1 : 0) - (b.SoldOut ? 1 : 0));
        },
    },
    mounted() {
        if (this.ticketPurchaseState === 'success') {
            this.openModal();
            this.getMyTicket();
        }
    },
    methods: {
        showEventDetails() {
            // replace url
            this.$router.replace(`/event/${this.eventSlug}`);

            // hide modal
            this.$refs['co-event-ticket-checkout'].hide();
        },
        hideModal() {
            this.$refs['co-event-ticket-checkout'].hide();
        },
        openModal() {
            if (this.$store && this.$store.state.isInMobile) {
                // replace url quiry params with resource id
                const urltoResource = `/event/${this.eventSlug}?tp=buy`;
                const queryParam = { ...this.$route.query, m: 'mobile', openinbrowser: urltoResource };
                this.$router.push({ query: queryParam });
                return;
            }

            this.$refs['co-event-ticket-checkout'].show();
        },
        selectTicket(ticket: EventTicket) {
            if (this.loading) {
                return;
            }
            if (!ticket.PriceInCents) {
                ticket.PriceInCents = 0;
            }

            this.selectedTicket = ticket;
        },
        getMyTicket() {
            this.loadingMyTicket = true;
            axios
                .get(`/event/ticket/my/for-event-id/${this.eventID}`)
                .then((response) => {
                    this.myTicket = get(response, 'data.ticket', null);
                    jsConfetti.addConfetti();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loadingMyTicket = false;
                });
        },

        createOrder() {
            this.loading = true;

            const successURL = `${window.location.origin}/event/${this.eventSlug}?tp=success&ticket_type_id=${this.selectedTicket?.ID}`;
            const cancelURL = `${window.location.origin}/event/${this.eventSlug}?tp=cancel&ticket_type_id=${this.selectedTicket?.ID}`;

            let req = {
                ticket_type_id: this.selectedTicket?.ID,
                event_id: this.eventID,
                success_url: successURL,
                cancel_url: cancelURL,
            };

            axios({
                method: 'POST',
                url: '/event/ticket',
                data: req,
            })
                .then((response) => {
                    // redirect to payment page
                    const paymentURL = get(response, 'data.ticket.PaymentURL', '');
                    if (paymentURL) {
                        window.location.href = paymentURL;
                    } else {
                        console.error('No payment URL found');
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
