<template>
    <div>
        <b-modal
            id="makebookingmodal"
            size="lg"
            modal-class=""
            header-class=""
            footer-class=""
            body-class=""
            scrollable
            :title="'Book ' + resource.Name"
            centered
            hide-footer
            @hidden="hide"
        >
            <!-- booking processing loading modal -->
            <div class="align-items-center justify-content-center w-100 p-5" v-if="loading">
                <!-- loading -->
                <div class="text-center" v-if="!success && !error">
                    <h2 class="mb-3">Your booking in process.</h2>
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>

                <!-- loader success feedback -->
                <div v-else-if="success" class="text-center">
                    <h2 class="mb-3">Your booking was successful.</h2>
                    <b-icon icon="check-circle" variant="primary" font-scale="2.5"></b-icon>
                    <div v-if="resource['AfterBooking']" class="w-100 d-flex flex-column p-3">
                        <h2 class="mb-2">
                            {{ resource['AfterBooking']['Title'] }}
                        </h2>
                        <p style="white-space: pre-wrap">
                            {{ resource['AfterBooking']['Text'] }}
                        </p>
                        <b-button
                            class="my-2"
                            variant="primary"
                            :href="resource['AfterBooking']['CallToActionURL']"
                            v-if="resource['AfterBooking']['CallToActionURL']"
                            >{{ resource['AfterBooking']['CallToActionTitle'] }}
                        </b-button>
                    </div>
                    <div class="mt-2">
                        <b-button class="m-2" @click="closeBookingModal">Close</b-button>
                        <b-button class="m-2" variant="primary" to="/profile/my-bookings">See my bookings</b-button>
                    </div>
                </div>
                <!-- loader error feedback -->
                <div v-else-if="error" class="text-center">
                    <b-icon icon="x-circle" variant="danger" font-scale="2.5"></b-icon>
                    <h2>Unfortunately something went wrong while creating your booking</h2>
                    <button
                        class="btn btn-primary m-2"
                        @click="
                            loading = false;
                            error = false;
                        "
                    >
                        try again
                    </button>
                </div>
            </div>
            <div v-if="!loading">
                <div v-if="resource && timeslot" class="w-100">
                    <!-- booking configuration -->

                    <div class="mt-2">
                        <span v-if="bookingCredits && bookingCredits.PricePerHourGross"
                            >€{{ (bookingCredits.PricePerHourGross / 100).toFixed(2) }}
                            / Hour
                        </span>
                        <span v-else>€ 0 / Hour </span>

                        <span v-if="taxRate && taxRate.Inclusive">incl.</span><span v-else>excl.</span>
                        <span v-if="taxRate"> {{ taxRate.Percentage }}%</span>
                        VAT
                        <span v-if="resource.CancellationPeriod">
                            / Cancellation notice
                            {{ resource.CancellationPeriod }} hours</span
                        >
                        <small v-if="isAdmin">(admin pricing)</small>
                    </div>
                    <div class="row mt-3 align-items-center justify-content-center mx-0 px-0">
                        <div class="col text-center mb-0 mx-0 px-0">
                            <b-form-group
                                id="fieldset-1"
                                label-for="booking-title"
                                label="Title of booking"
                                label-size="sm"
                                label-class="mb-0 label-align"
                                class="text-left mb-0"
                            >
                                <b-form-input
                                    id="booking-title"
                                    type="text"
                                    v-model="booking.Title"
                                    class="form-control rounded-pill"
                                >
                                </b-form-input>
                            </b-form-group>
                            <div class="d-flex w-100 align-items-center mt-2">
                                <div class="row w-100 mx-0 px-0">
                                    <div class="col-6 col-lg-3 p-0 m-0">
                                        <b-form-group
                                            id="startdate"
                                            label-for="startdate"
                                            label="Startdate"
                                            label-size="sm"
                                            label-class="mb-0 label-align"
                                            class="text-left mb-0"
                                        >
                                            <b-form-datepicker
                                                id="select-start"
                                                :date-format-options="{
                                                    year: '2-digit',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                }"
                                                v-model="startDate"
                                                class="rounded-pill"
                                            ></b-form-datepicker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-6 col-lg-3 p-0 m-0">
                                        <b-form-group
                                            id="startTime-group"
                                            label-for="startTime"
                                            label="Time"
                                            label-size="sm"
                                            label-class="mb-0 label-align"
                                            class="text-left mb-0 ml-2"
                                        >
                                            <b-form-select
                                                v-model="bookingStartString"
                                                :options="bookingStartOptions"
                                                class="form-control rounded-pill"
                                                @change="updateToSelect"
                                            >
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                    <div class="col-6 col-lg-3 p-0 m-0">
                                        <b-form-group
                                            id="enddate-group"
                                            label-for="enddate"
                                            label="End date"
                                            label-size="sm"
                                            label-class="mb-0 label-align"
                                            class="text-left mb-0 ml-lg-2"
                                        >
                                            <b-form-datepicker
                                                id="enddate"
                                                v-model="endDate"
                                                :date-format-options="{
                                                    year: '2-digit',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                }"
                                                class="rounded-pill"
                                            ></b-form-datepicker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-6 col-lg-3 p-0 m-0">
                                        <b-form-group
                                            id="endtime-group"
                                            label-for="endTime"
                                            label="Time"
                                            label-size="sm"
                                            label-class="mb-0 label-align"
                                            class="text-left mb-0 ml-2"
                                        >
                                            <b-form-select
                                                v-model="bookingEndString"
                                                :options="bookingEndOptions"
                                                class="form-control rounded-pill"
                                                @change="setToTime"
                                            >
                                            </b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isAdmin" class="w-100 text-left mt-3">
                                <div>Booking by:</div>
                                <div class="d-block rounded-pill bg-grey mt-2 w-100 text-left">
                                    <ProfileCircle
                                        :ImageURL="me.Profile.ImageURL"
                                        :Slug="me.Slug"
                                        :Name="me.Profile.Name"
                                    ></ProfileCircle>
                                </div>
                                <div class="mt-3">Booking for:</div>
                                <CoSelectUser
                                    class="mt-3"
                                    :deletable="true"
                                    ref="userTagList"
                                    placeholder="type anything"
                                ></CoSelectUser>
                            </div>
                            <div class="d-block round-unify bg-grey mt-3 w-100 text-left p-3">
                                <div class="row m-0 p-0 pb-1">
                                    <div class="col-6 col-md-3">
                                        <p class="p-0 m-0">Sum:</p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="p-0 m-0">€{{ priceSum.sum.toFixed(2) }}</p>
                                    </div>
                                </div>
                                <div class="row m-0 p-0 pb-1">
                                    <div class="col-6 col-md-3">
                                        <p class="p-0 m-0">Credits used:</p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="p-0 m-0">
                                            {{ priceSum.usedCredits }}
                                            <small>
                                                / left
                                                {{ priceSum.leftCredits }}</small
                                            >
                                        </p>
                                    </div>
                                </div>
                                <div class="row m-0 p-0 pb-1">
                                    <div class="col-6 col-md-3">
                                        <p class="p-0 m-0">Total:</p>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <p class="p-0 m-0">€{{ priceSum.total }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block round-unify bg-grey mt-3 w-100 text-left p-3">
                                <router-link
                                    to="/account/billing/payment-methods"
                                    style="color: black !important; text-decoration: none"
                                >
                                    <b-icon scale="1" id="edit-payment" icon="pencil" class="mr-2"></b-icon>
                                    Pay with
                                    {{ paymentMethod.Details.CardBrand }}
                                    {{ paymentMethod.Type }} - {{ paymentMethod.Details.Last4Digits }}
                                </router-link>
                            </div>
                            <!-- <div class="d-block round-unify mt-3 w-100 text-left p-3">
              <b-icon scale="1" icon="chevron-down" class="mr-2"></b-icon>
              Advanced options
            </div>
            <div class="d-block round-unify mt-3 w-100 text-left p-3"></div> -->
                            <div
                                class="d-flex align-items-center mt-3 ml-1"
                                v-if="termsAndConditions"
                                @click="changeAcception"
                            >
                                <b-form-checkbox
                                    id="checkbox-2"
                                    name="checkbox-2"
                                    v-model="acceptTermsAndConditions"
                                    @change="changeAcception"
                                    required
                                    :disabled="loading"
                                >
                                </b-form-checkbox>
                                <p class="mb-0" @click="changeAcception(true)">
                                    I accept the
                                    <a class="text-primary mb-0" v-b-modal.terms-and-conditions title="Privacy policy"
                                        >Terms and Conditions</a
                                    >
                                </p>
                            </div>
                            <!-- terms and conditions modal-->
                        </div>
                    </div>
                </div>
                <div class="d-flex w-100">
                    <div class="d-flex align-items-center justify-content-between pt-2 mt-2 w-100">
                        <div>
                            <b-button
                                class="mr-3 ml-0 pl-0"
                                variant="no-outline"
                                style="color: #868685"
                                @click="$bvModal.hide('makebookingmodal')"
                                >Discard
                            </b-button>
                        </div>
                        <div>
                            <b-button
                                @click="makeBooking"
                                :disabled="!valid || tooLong || tooShort || sameTime || !acceptTermsAndConditions"
                                variant="primary"
                                >Book now
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- footer -->
            <template slot="modal-footer"></template>
        </b-modal>
        <b-modal id="terms-and-conditions" hide-footer title="Terms and conditions" size="xl" z-index="100000">
            <div v-if="resource.TermsAndConditions" v-html="resource.TermsAndConditions"></div>
            <div v-if="termsAndConditions" v-html="termsAndConditions.Content"></div>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import { P } from 'vue-long-click';
import { get } from 'lodash';
import { preventDefault } from '@fullcalendar/common';
import EventBus from '../eventBus';

export default {
    name: 'BookingModalV3',
    data() {
        return {
            me: this.$store.state.me,
            bookingStartString: '00:00',
            bookingEndString: '00:00',
            bookingStartOptions: [],
            bookingEndOptions: [],
            booking: {},

            termsAndConditions: null,
            acceptTermsAndConditions: false,
            isReady: false,

            loading: false,
            success: false,
            error: false,
            price: null,
            duration: 0,
            paymentMethods: [],

            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,

            valid: true,
            beginHourString: '',

            bookingCredits: null,
            paymentMethod: {},
            priceString: '',
            priceError: false,
            priceSum: {
                sum: 0.0, // sum of all prices
                usedCredits: 0,
                leftCredits: 0,
                total: 0.0,
            },
            defaultPrice: null,
            sameTime: false,
            tooShort: false,
            tooLong: false,

            taxRate: null,
            coappResourcePrice: '',
            tags: [],
        };
    },
    props: ['bookingTimes', 'timeslot', 'resource', 'space', 'customStyling', 'title', 'isMeeting'],

    mounted() {
        this.getTermsAndConditions();
        this.isAdmin = this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin');

        this.listPaymentMethods();

        this.booking.Title = `${this.$store.state.me.Profile.Name}'s booking`;
    },
    watch: {
        timeslot(newVal, oldVal) {
            this.bookingStartString = this.timeslot.startTime;
            this.bookingEndString = this.timeslot.endTime;

            this.startDate = this.timeslot.start;
            this.endDate = this.timeslot.end;

            this.bookingEndString = newVal.endTime;
            this.getCredits();
            this.parseChoosenSlotData();
        },
        startDate(newVal, oldVal) {
            this.endDate = newVal;
            this.booking.From = moment(newVal)._d;
            this.booking.To = moment(newVal)._d;
        },
        endDate(newVal, oldVal) {
            this.startDate = newVal;
            this.booking.From = moment(newVal)._d;
            this.booking.To = moment(newVal)._d;
        },

        resource(newVal, oldVal) {
            const taxID = newVal.TaxRateID;
            if (taxID) {
                this.getTaxRate(taxID);
            }
            this.getCredits();
        },
    },
    methods: {
        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.termsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        changeAcception(outOfCheckbox) {
            if (outOfCheckbox) {
                this.acceptTermsAndConditions = !this.acceptTermsAndConditions;
            }
            let ready = true;

            if (this.termsAndConditions && !this.acceptTermsAndConditions) {
                ready = false;
            }
            this.isReady = ready;
        },
        listPaymentMethods() {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    const that = this;
                    this.paymentMethods.forEach((item) => {
                        if (item.Default == true) {
                            that.paymentMethod = item;
                        }
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before creating a booking";
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before creating a booking";
                    }
                });
        },
        interval() {
            if (this.$store.state.space.ID === 'localhost:8080') {
                return 30;
            }
            if (
                this.$store.state.space.ID === 'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5' &&
                this.resource.CobotID === '84c900fb6d352df1b1cbc63bac7a3979'
            ) {
                return 60;
            }
            if (
                this.$store.state.space.ID ===
                'hwMwnRdbGeXIido_GODOx_gqSSs4sMMoORTPY7dTJLz7UJCQ4NcwVTSpf0_yLuTiN0ivjTW57YxcjIFKsvRbsQ=='
            ) {
                return 60;
            }
            return 30;
        },

        getTaxRate(taxID) {
            axios({
                method: 'GET',
                url: `/space/tax/${taxID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRate = response.data;

                        this.calculatePrice();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        makeBooking() {
            // start loader
            this.loading = true;

            // add current day to time
            const timeStart = this.bookingStartString.split(':');
            const timeEnd = this.bookingEndString.split(':');

            const from = moment(this.booking.From);
            const to = moment(this.booking.From);

            from.hours(parseInt(timeStart[0]));
            from.minutes(parseInt(timeStart[1]));

            to.hours(parseInt(timeEnd[0]));
            to.minutes(parseInt(timeEnd[1]));

            const booking = {
                From: from.unix().toString(),
                To: to.unix().toString(),
                ResourceID: this.resource.ID,
                Title: this.booking.Title,
            };

            const data = JSON.stringify(booking);

            axios({
                method: 'POST',
                url: '/booking/v2/create',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$emit('bookingCreated');
                    setTimeout(() => {
                        this.error = false;
                        this.success = true;
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.error = true;
                        this.success = false;
                    }, 300);
                });
        },

        calculatePrice() {
            const timeStart = this.bookingStartString.split(':');
            const timeEnd = this.bookingEndString.split(':');
            // keep day information, but update hours and minutes with choosen time from select
            const from = moment(this.booking.From);
            const to = moment(this.booking.From);

            from.hours(parseInt(timeStart[0]));
            from.minutes(parseInt(timeStart[1]));

            to.hours(parseInt(timeEnd[0]));
            to.minutes(parseInt(timeEnd[1]));
            // get duration in minutes
            const duration = moment.duration(to.diff(from));
            const durationInMinutes = duration.asMinutes();

            this.tooShort = false;
            this.tooLong = false;
            if (
                this.resource.MinBookingDuration != null &&
                this.resource.MinBookingDuration != 0 &&
                this.resource.MinBookingDuration > durationInMinutes &&
                this.resource.BookWholeSlot == false
            ) {
                this.tooShort = true;
                return;
            }

            if (
                this.resource.MaxBookingDuration != null &&
                this.resource.MaxBookingDuration !== 0 &&
                this.resource.MaxBookingDuration < durationInMinutes &&
                this.resource.BookWholeSlot === false
            ) {
                this.tooLong = true;
                return;
            }

            // booking credits has price override resource price
            if (this.bookingCredits && this.bookingCredits.PricePerHourGross) {
                this.priceSum.sum = ((this.bookingCredits.PricePerHourGross * (durationInMinutes / 60)) / 100).toFixed(
                    2
                );
            }

            if (this.resource.BookWholeSlot && this.resource.PricePerSlot) {
                this.priceSum.sum = this.bookingCredits.PricePerHourGross.toFixed(2);
            }

            let creditsInMinutes = 0;
            if (this.bookingCredits && this.bookingCredits.HoursRemaining) {
                creditsInMinutes = this.bookingCredits.HoursRemaining * 60;
            }

            this.getDefaultPrice();
            // if credits are more then booking slot duration
            if (creditsInMinutes >= durationInMinutes) {
                const hh = Math.floor(durationInMinutes / 60);

                var usedCredits = durationInMinutes / 60;
                this.priceSum.usedCredits = usedCredits.toFixed(2);
                this.priceSum.leftCredits = (creditsInMinutes / 60 - usedCredits).toFixed(2);
                this.priceSum.total = 0;
            } else {
                var usedCredits = creditsInMinutes / 60;
                this.priceSum.usedCredits = usedCredits.toFixed(2);
                this.priceSum.leftCredits = 0;

                const leftMinutes = durationInMinutes - creditsInMinutes;
                const leftHours = leftMinutes / 60;
                this.priceSum.total = (leftHours * this.defaultPrice).toFixed(2);
            }
        },

        getDefaultPrice() {
            if (this.bookingCredits && this.bookingCredits.HoursRemaining) {
                this.defaultPrice = (this.bookingCredits.PricePerHourGross / 100).toFixed(2);
                return;
            }
            if (this.resource.PriceInCentsPerHour !== null && this.taxRate !== null) {
                if (this.taxRate.Inclusive) {
                    this.defaultPrice = (this.resource.PriceInCentsPerHour / 100.0).toFixed(2);
                } else {
                    this.defaultPrice =
                        (this.resource.PriceInCentsPerHour / 100.0) * (1 + this.taxRate.Percentage / 100.0).toFixed(2);
                }
                return;
            }
            // legacy price calculation
            this.defaultPrice = this.resource.PricePerHour * (1 + this.resource.TaxRate / 100).toFixed(2);
        },

        getCredits() {
            if (!this.resource) {
                return;
            }
            this.$store
                .dispatch('getBookingCreditsV2', this.resource.ID)
                .then((response) => {
                    this.bookingCredits = response;
                    this.resource.PriceInCentsPerHour = get(response, 'PricePerHour', 0);
                    this.calculatePrice();
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Error while getting booking credits',
                    });
                });
        },

        closeBookingModal() {
            this.loading = false;
            this.$bvModal.hide('makebookingmodal');
        },
        startBooking() {
            // initiate new booking process

            this.$bvModal.show('makebookingmodal');

            // this.parseChoosenSlotData();
            if (this.timeslot) this.parseChoosenSlotData();
        },
        setToTime() {
            this.bookingStartString == this.bookingEndString && this.startDate == this.endDate
                ? (this.sameTime = true)
                : (this.sameTime = false);

            this.calculatePrice();
        },
        updateToSelect() {
            // clear booking End Options
            this.bookingEndOptions = [];

            if (this.bookingStartString > this.bookingEndString) {
                this.bookingStartString = this.bookingEndString;
            }
            // get end time of slot with day information (day is necessary for handling 00:00 comparisons)
            const slotEndTime = moment(this.timeslot.end);

            const chosenBeginTime = moment(this.bookingStartString, 'HH:mm');

            // keep day information, but update hours and minutes with choosen time from select
            const finalBeginTime = moment(this.timeslot.From)
                .set('hour', chosenBeginTime.hours())
                .set('minute', chosenBeginTime.minutes());

            const duration = moment.duration(slotEndTime.diff(finalBeginTime)).asMinutes();
            if (duration <= this.interval() || (this.resource.BookWholeSlot && !this.resource.PricePerSlot)) {
                this.bookingEndString = this.timeslot.endTime;
                this.bookingEndOptions.push(this.bookingEndString);

                // do not add more options, cause there is only this option

                this.calculatePrice();

                return;
            }

            // min booking time = start + 15 mins

            let beginTimeToSelect = moment(finalBeginTime).add(this.interval(), 'minute');

            // if MinBookingDuration not equal 0 change beginTimeToSelect to start time + min duration also reset bookingEndString to the same point
            if (this.resource.MinBookingDuration != null && this.resource.MinBookingDuration != 0) {
                beginTimeToSelect = moment(finalBeginTime).add(this.resource.MinBookingDuration, 'minute');
            }
            this.bookingEndOptions = [];
            this.addRangeOfTimeStepsToArray(
                this.bookingEndOptions,
                moment(this.bookingTimes.From, 'HH:mm'),
                moment(this.bookingTimes.To, 'HH:mm').subtract(this.interval(), 'minute')
            );

            this.calculatePrice();
        },
        addRangeOfTimeStepsToArray(array, start, end) {
            const loopHour = moment(start);

            while (loopHour.isSameOrBefore(end)) {
                const str = loopHour.format('HH:mm');
                array.push(str);
                loopHour.add(this.interval(), 'minute');
            }
            array.push(this.bookingTimes.To);
        },

        parseChoosenSlotData() {
            // initial setup on changed time slot data
            // set up the booking params
            this.booking = this.timeslot; // make copy of timeslot object
            this.booking.From = this.timeslot.start; // copy of moment object;

            // update From-Select. max begin time = slot end time - 15 mins
            this.bookingStartString = this.timeslot.startTime;
            this.bookingStartOptions = [];
            this.addRangeOfTimeStepsToArray(
                this.bookingStartOptions,
                moment(this.bookingTimes.From, 'HH:mm'),
                moment(this.bookingTimes.To, 'HH:mm').subtract(this.interval(), 'minute')
            );

            // update To-Select
            this.updateToSelect();

            this.booking.Title = `${this.$store.state.me.Profile.Name}'s booking`;
        },
        retry() {
            this.success = false;
            this.error = false;
            const el1 = this.$el.getElementsByClassName('circle-loader')[0];
            el1.classList.remove('load-complete');
            var el2 = this.$el.getElementsByClassName('checkmark')[0];
            el2.style.display = 'none';
            var el2 = this.$el.getElementsByClassName('xmark')[0];
            el2.style.display = 'none';

            this.confirm();
        },
        hide(trigger) {
            this.success = false;
            this.error = false;
            this.loading = false;
        },
        confirm() {
            // start loader
            this.loading = true;

            // add current day to time
            const timeStart = this.bookingStartString.split(':');
            const timeEnd = this.bookingEndString.split(':');

            const from = moment(this.booking.From);
            const to = moment(this.booking.From);

            from.hours(parseInt(timeStart[0]));
            from.minutes(parseInt(timeStart[1]));

            to.hours(parseInt(timeEnd[0]));
            to.minutes(parseInt(timeEnd[1]));

            const booking = {
                From: from.unix().toString(),
                To: to.unix().toString(),
                ResourceID: this.resource.ID,
                Title: this.booking.Title,
            };

            const data = JSON.stringify(booking);

            axios({
                method: 'POST',
                url: '/booking/v2/create',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$emit('bookingCreated');
                    setTimeout(() => {
                        this.error = false;
                        this.success = true;
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.error = true;
                        this.success = false;
                    }, 300);
                });
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/less/coapp.less';

input:disabled {
    background: transparent;
}

input {
    min-width: 0px;
}

.time-button {
    flex-shrink: 0;
    padding: 0px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding-bottom: 2px;
    padding-left: 0px;
    font-weight: 300;
}

.modal {
    width: max-content;
    height: max-content;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px 3px;
    transition: all 0.2s ease-in;
    font-family: Helvetica, Arial, sans-serif;
    position: absolute;
    left: calc(50% - 25vw);
    top: calc(50% - 25vh);
    z-index: 1024;
    display: block;
}

.modal .title h2 {
    display: inline-block;
    margin-bottom: unset !important;
    line-height: 36px !important;
}

.modal .title button {
    display: inline-block;
}

.modal .body h2 {
    padding: 0 1em;
}

.modal .body {
    margin-top: 35px;
}

.modal .message {
    width: 100%;
    border-color: rgb(225, 225, 225) !important;
    border: solid 2px;
    padding: 0.5em 1.2em;
    top: 0px;
    position: relative;
    font-size: 1em;
    line-height: 1em;
}

.modal .footer {
    z-index: 1024;
}

@media (max-width: 750px) {
    .modal {
        width: calc(100vw - 40px);
        left: calc(50% - 50vw + 20px);
        top: 20px;
    }
}

.slot {
    background: rgb(225, 225, 225);
    color: #212121;
    border-radius: 0px;
    padding: 5px 15px;
    display: inline-block;
    cursor: pointer;
    width: 90%;
    text-align: left;
}

.slot:hover {
    background-color: rgb(255, 71, 19);
}

.slot.busy {
    visibility: hidden;
}

.slot span {
    display: block;
    line-height: 1;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #00000094;
    z-index: 999;
    transition: opacity 0.2s ease;
}

.from span {
    margin: 0 5px;
    cursor: pointer;
}

.to span {
    margin: 0 5px;
    cursor: pointer;
}

.popover-no-margin .popover-content {
    padding: 0px;
    width: auto;
}
</style>
