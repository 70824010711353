









































































import i18n from 'vue-i18n';
import { get, clone } from 'lodash';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoEditor from '@/components/Molecules/co-editor/CoEditor.vue';
import Placeholder from '@tiptap/extension-placeholder';
import { EventTicket } from './models.ts';

export default {
    name: 'CoEventTicketCreateEdit',
    components: {
        CoModal,
        CoFormGroup,
        CoInput,
        CoSelect,
        CoButton,
        CoEditor,
    },
    props: {
        mode: {
            type: String,
            required: true,
            default: 'create',
            validator: (value: string) => ['create', 'edit'].includes(value),
        },
        ticket: {
            type: Object,
            required: false,
            default: () => ({} as EventTicket),
        },
        taxRates: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },
    },
    data() {
        return {
            ticketForm: {
                ID: '',
                Name: '',
                Description: '',
                PriceInCents: 0,
                TaxRateID: '',
                Limit: 100,
                CreatedAt: 0,
                UpdatedAt: 0,
                CancellationNotice: 7,
                CancellationFee: 0,
                TermsAndConditions: '',
                Delete: false,
            },

            priceInWholeUnits: 0,

            selectedTaxRate: null,

            randomNum: Math.floor(Math.random() * 1000),
        };
    },

    computed: {
        modalTitle() {
            return this.ticket && this.ticket.id ? this.$t('labels.ticketedit') : this.$t('labels.ticketcreate');
        },
        taxRateList() {
            return this.taxRates.map((taxrate) => ({
                Value: taxrate.ID,
                Name: `${taxrate.Percentage ? taxrate.Percentage : 0}% - ${
                    taxrate.Inclusive ? 'Inclusive' : 'Exclusive'
                } - ${taxrate.DisplayName}`,
            }));
        },
        validData() {
            // strip white spaces from name
            return !get(this.ticketForm, 'Name', '').trim();
        },
    },

    watch: {
        taxRates: {
            handler() {
                this.getTaxRate();
            },
            immediate: true,
        },

        ticket: {
            handler(ticket) {
                if (this.mode === 'edit') {
                    this.ticketForm = clone(ticket);
                }
                this.getTaxRate();
            },
            immediate: true,
        },
    },

    mounted() {
        if (this.mode === 'edit') {
            this.ticketForm = clone(this.ticket);
        }
        this.getTaxRate();
    },

    methods: {
        open() {
            this.$refs[`event-ticket-modal-${this.randomNum}`].show();
            this.getTaxRate();
        },
        close() {
            this.$refs[`event-ticket-modal-${this.randomNum}`].hide();
        },
        calculatePrice(price: number) {
            this.ticketForm.PriceInCents = Math.round(price * 100);
        },
        selectTaxRate(taxRate: any) {
            this.selectedTaxRate = taxRate;
        },
        // if tax rates a not empty and tax rate id is not empty
        getTaxRate() {
            if (this.mode === 'create') {
                if (this.taxRates.length > 0) {
                    this.selectedTaxRate = this.taxRates[0];
                    return;
                }
                return;
            }

            if (this.taxRates.length > 0 && this.ticketForm.TaxRateID) {
                this.selectedTaxRate = this.taxRates.find((taxRate) => taxRate.id === this.ticketForm.TaxRateID);
            }
        },

        // create
        createTicket() {
            const output = {
                ...this.ticketForm,
            };

            // add random id to ticket
            output.ID = `${Math.floor(Math.random() * 1000)}`;

            if (this.selectedTaxRate) {
                output.TaxRateID = this.selectedTaxRate.Value;
            }
            this.$emit('created', output);
            //  reset form
            this.ticketForm = {
                ID: '',
                Name: '',
                Description: '',
                PriceInCents: 0,
                TaxRateID: '',
                Limit: 100,
                CreatedAt: 0,
                UpdatedAt: 0,
                CancellationNotice: 7,
                CancellationFee: 0,
                TermsAndConditions: '',
                Delete: false,
            };
            this.close();
        },

        // update
        updateTicket() {
            const output = {
                ...this.ticketForm,
            };
            if (this.selectedTaxRate) {
                output.TaxRateID = this.selectedTaxRate.Value;
            }
            this.$emit('updated', output);
            this.close();
        },
    },
};
