





































import Vue from 'vue';
import axios from 'axios';
import CookieLaw from 'vue-cookie-law';
import { get } from 'lodash';
import CookieBanner from '@/components/CookieBanner.vue';
import EventBus from '@/eventBus';
import VueIntercom from 'vue-intercom';
import setCustomColors from './customColors';
import Bugsnag from '@bugsnag/js';
import CoTopNavBarMobile from './components/Organisms/co-top-nav-bar-mobile/CoTopNavBarMobile.vue';

Vue.use(VueIntercom, { appId: 'kttwojyk' });

type IntercomEvent = {
    type: 'update' | 'trackEvent';
    payload: any;
    eventName?: string;
};

export default {
    name: 'App',
    components: {
        CookieBanner,
        CookieLaw,
        CoTopNavBarMobile,
    },
    data() {
        return {
            me: null,
            appVisible: false,
            defaultColors: [
                {
                    name: 'purple',
                    value: '#5100ff',
                },
                {
                    name: 'red',
                    value: '#fd6969',
                },
                {
                    name: 'text',
                    value: '#212529',
                },
            ],
            homePaths: ['', '/', '/channels', '/booking-calendar', '/events', '/market', '/jobs'],

            PrivacyPolicy: null,
            intercomHasBooted: false,
        };
    },
    async beforeCreate() {
        if (this.$route.query.m === 'mobile') {
            this.$store.commit('SET_IS_IN_MOBILE', true);
        } else if (this.$route.query.m === 'desktop') {
            this.$store.commit('SET_IS_IN_MOBILE', false);
        }

        if (
            this.$store &&
            this.$store.state &&
            this.$store.state.space &&
            !this.$router.currentRoute.path.startsWith('/admin')
        ) {
            const colorData = [
                {
                    name: 'purple',
                    value: this.$store.state.space.PrimaryColor,
                },
                {
                    name: 'red',
                    value: this.$store.state.space.SecondaryColor,
                },
                {
                    name: 'text',
                    value: this.$store.state.space.TextColor,
                },
            ];
            setCustomColors(colorData);
        }

        await this.$store
            .dispatch('getSpaceSettings', null)
            .then((response) => {
                this.setFavicon();
                if (
                    this.$store &&
                    this.$store.state &&
                    this.$store.state.space &&
                    !this.$router.currentRoute.path.startsWith('/admin')
                ) {
                    const colorData = [
                        {
                            name: 'purple',
                            value: this.$store.state.space.PrimaryColor,
                        },
                        {
                            name: 'red',
                            value: this.$store.state.space.SecondaryColor,
                        },
                        {
                            name: 'text',
                            value: this.$store.state.space.TextColor,
                        },
                    ];
                    setCustomColors(colorData);
                }
                // update unleash context and start
                this.$unleash.updateContext({
                    userId: this.$store.state.me.ID,
                    remoteAddress: this.$store.state.space.ID, // instead of remoteAddress we use spaceId
                    properties: {
                        spaceID: this.$store.state.space.ID,
                    },
                });
                // Start the background polling
                this.$unleash.start();

                // Check if feature flaging system is available
                this.$unleash.on('ready', () => {
                    if (this.$unleash.isEnabled('frontend.always-on')) {
                        console.info('frontend.always-on is enabled');
                    } else {
                        console.info('frontend.always-on is disabled');
                    }

                    if (this.$unleash.isEnabled('circles.frontend')) {
                        this.$store.commit('SET_CIRCLES_ON', true);
                    }
                });
                // update app visible
                this.appVisible = true;
            })
            .catch((error) => {
                this.appVisible = true;
            })
            .finally(() => {
                this.appVisible = true;
            });
    },
    created() {
        this.getPrivacyPolicy();
        // Intercom initialization for the session if script is ready
        // if not it will be initiated by the the this.$intercom.ready watcher
        if (this.$intercom && this.$intercom.ready && !this.intercomHasBooted) {
            this.bootIntercom();
        }
        //intercom event handlers
        EventBus.$on('intercomUpdate', (data: IntercomEvent) => {
            this.processIntercomMessage({
                type: 'update',
                ...data,
            });
        });
        EventBus.$on('intercomTrackEvent', (data: IntercomEvent) => {
            this.processIntercomMessage({
                type: 'trackEvent',
                ...data,
            });
        });

        if (this.$route && this.$route.query && this.$route.query.intercomevent) {
            this.processIntercomMessage({
                type: 'trackEvent',
                eventName: this.$route.query.intercomevent,
                payload: {
                    info: "event from url query param 'intercomevent'",
                },
            });
        }
    },
    computed: {
        isSaasRoute() {
            const match = get(window, 'location.hostname', '').match(/^app|staging-app\.(coapp\.io|localhost)/gi);
            return !!(match && match.length > 0);
        },
    },
    mounted() {
        this.injectCustomerJavaScript();

        if (this.isLocalStorage()) {
            this.$store.commit('LOCALSTORAGE_TRUE');
        }
        this.getPaymentState();

        this.getPermissionSettings();
    },
    beforeDestroy() {
        // close intercom session
        this.$intercom.shutdown();
    },
    watch: {
        // Intercom initialization on script ready
        '$intercom.ready'(ready) {
            this.bootIntercom();
        },
        // Intercom initialization me ready
        '$store.state.me'(newVal) {
            this.bootIntercom();
        },
        // Intercom initialization on space ready
        '$store.state.space'(newVal) {
            this.bootIntercom();
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        logOut() {
            localStorage.removeItem('_authtoken');
            this.$router.go('/login');
        },
        setFavicon() {
            const faviconurl = get(this.$store, 'state.space.FaviconURL', null);
            if (faviconurl) {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link['rel'] = 'icon';
                    document.head.appendChild(link);
                }
                // icon.value = this.$store.state.space.Favicon;
                link['href'] = faviconurl;
            } else {
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link['rel'] = 'icon';
                    document.head.appendChild(link);
                }
                link['href'] = '/favicon.ico';
            }
        },
        // isLocalStorage checks if local storage available to detect wheather it is CNA popup or not
        isLocalStorage() {
            const test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
                // available
            } catch (e) {
                return false;
                // unavailable
            }
        },

        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    Bugsnag.notify(error);
                });
        },

        getPermissionSettings() {
            axios({
                method: 'GET',
                url: '/space/permissions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const permissions = response.data;
                        this.$store.commit('SET_PERMISSIONS', permissions);
                    }
                })
                .catch((error) => {
                    Bugsnag.notify(error);
                });
        },

        // getPaymentState - returns payment service readyness state
        getPaymentState() {
            axios({
                method: 'GET',
                url: '/user/account/billing/payment-state',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$store.commit('SET_PAYMENT_READY_STATE', response.data.ChargesEnabled);
                    } else {
                        this.$store.commit('SET_PAYMENT_READY_STATE', false);
                    }
                })
                .catch((error) => {
                    this.$store.commit('SET_PAYMENT_READY_STATE', false);
                });
        },
        bootIntercom() {
            // test if all credentials are available skip if not
            if (
                this.intercomHasBooted ||
                !this.$intercom.ready ||
                !get(this.$store.state, 'space.ID', null) ||
                !get(this.$store.state, 'me.ID', null)
            ) {
                // Intercom credentials not ready, skipping boot attempt
                return;
            }
            // Intercom credentials are available ... booting intercom
            // 1. boot if user = admin
            if (
                this.$store.state &&
                this.$store.state.me &&
                this.$store.state.me.Permissions &&
                this.$store.state.me.Permissions.includes('space_admin')
            ) {
                const me = this.$store.state.me || {};
                const intercomUserData = {
                    name: this.$store.state.me.Profile.Name,
                    email: this.$store.state.me.Email,
                    user_id: this.$store.state.me.ID, // coapp user id
                };
                const intercomCommunityData = {
                    spaceID: this.$store.state.space.ID,
                    builtInDomain: this.$store.state.space.BuiltInDomain, // this is the built in domain name of the space as it was configured on community creation
                    // info needed for intercom onboarding messages
                    'Community Name': this.$store.state.space.Name,
                    'Community Created Date': this.$store.state.space.CreatedAt,
                    'Community Domain': this.$store.state.space.DomainName, // this is the actual domain name of the space if it was customized
                };
                // boot intercom with user data
                // should not be booted elsewhere in the app
                this.$intercom.boot({
                    ...intercomUserData,
                    ...intercomCommunityData,
                    hide_default_launcher: this.$isMobile || this.isInMobile, // hide if Vue.prototype.$isMobile flag is set
                });
                this.$intercom.hide(); // make sure messenger is closed on boot

                // 2. check if intercom qeue in store has messages
                // set the intercom ready flag IMPORTANT if this is not set there will be an infinite loop
                this.intercomHasBooted = true;
                // 3. process messages in queue and empty it
                this.$store.state.intercomEventQueue.forEach((item: { id: String; data: IntercomEvent }) => {
                    const { data } = item;
                    if (!data) return;
                    this.processIntercomMessage(data);
                    // 4. remove item from queue
                    this.$store.commit('REMOVE_FROM_INTERCOM_QEUE', item);
                });
            }
        },
        processIntercomMessage(data: IntercomEvent) {
            const { type, payload, eventName = 'defaultEvent' } = data;
            // 1. continue if user = admin
            if (
                this.$store.state &&
                this.$store.state.me &&
                this.$store.state.me.Permissions &&
                this.$store.state.me.Permissions.includes('space_admin')
            ) {
                // 2. check if intercom is ready
                if (this.intercomHasBooted) {
                    // 3. true: process message directly
                    if (type === 'update') {
                        this.$intercom.update(payload);
                    } else if (type === 'trackEvent') {
                        this.$intercom.trackEvent(eventName, payload);
                    }
                } else {
                    this.$store.commit('ADD_TO_INTERCOM_QEUE', data);
                }
            }
        },
        /*
    injectCustomerJavaScript

    Known issues:
    when user state changes from unknown to logged in without App.vue being newly mounted,
    placeholders will remain in unknown user state, result {{placeholders}} are not populated with data

    Description:
    injects customer defined JavaScript with placeholders in <head> with {{placeholders}} populated with user profile data, when available.
    When called in mounted this.$store.state.space and this.$store.state.me are available.
    */
        injectCustomerJavaScript() {
            if (this.$store.state.isInMobile) {
                return;
            }

            let javascript = this.$store.state.space.Javascript;
            const user = this.$store.state.me;

            if (javascript) {
                if (user.Email) {
                    javascript = javascript.replaceAll('{{name}}', `"${this.$store.state.me.Profile.Name}"`);
                    javascript = javascript.replaceAll('{{email}}', `"${this.$store.state.me.Email}"`);
                    javascript = javascript.replaceAll('{{user_id}}', `"${this.$store.state.me.ID}"`);
                } else {
                    javascript = javascript.replaceAll(/\{\{[a-z0-9_-]+\}\}/gi, null);
                }
                // wrap he custom JavaScript in try catch statement to prevent it from breaking the DOM from loading
                javascript = `try {${javascript}} catch (err) { if (console && console.warn) {console.warn('There was an error in your custom JavaScript', err)}}`;

                // add the custom JavaScript to the DOM
                const dynamicScript = document.createElement('script');
                dynamicScript.setAttribute('type', 'text/javascript');
                dynamicScript.setAttribute('data-id', 'customerJavaScriptInjection');
                dynamicScript.innerHTML = javascript;
                document.head.appendChild(dynamicScript);
            }
        },
    },
};
