





































































































































































































































































































































































































































































































































































































































































































































































































































































































import { format, fromUnixTime } from 'date-fns';
import { get } from 'lodash';
import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import EventBus from '../../../../eventBus';
import i18n from 'vue-i18n';

import CoText from '../../../../components/Atoms/co-text/CoText.vue';
import CoPaymentDetails from '../../../../components/Molecules/co-payment-details/CoPaymentDetails.vue';
import CoDropdown from '../../../../components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../../../../components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoThumbnail from '../../../../components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoHeader from '../../../../components/Molecules/co-header/CoHeader.vue';
import CoSubscriptionStatusModal from '../../../../components/Organisms/co-subscription-status-modal/CoSubscriptionStatusModal.vue';
import CoDate from '../../../../components/Molecules/co-date/CoDate.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoForm from '@/components/Molecules/co-form/CoForm.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';

Vue.use(Router);

interface UserMembership {
    subscriptionStatus: string;
    stripeCustomerLink: string;
    stripeCustomerID: string;

    currentBillingPeriodStart: number;
    currentBillingPeriodEnd: number;

    billingCycleAnchor: number;

    subscriptionCancelAt: number; // timestamp when subscription will be cancelled
    subscriptionCanceledAt: number; // timestamp when subscription was cancelled
    subscriptionStartDate: number; // timestamp when subscription was created
    subscriptionEndedAt: number; // timestamp when subscription was ended
    subscriptionCreated: number; // timestamp when subscription was created

    collectionMethod: string;

    parentPlanUserID: string;
}

export default {
    name: 'MemberAccountV2',
    components: {
        CoText,
        CoSubscriptionStatusModal,
        CoDropdown,
        CoDropdownItem,
        CoThumbnail,
        CoHeader,
        CoPaymentDetails,
        CoDate,
        CoSkeleton,
        CoTippy,
        CoForm,
        CoLink,
    },
    i18n: {
        messages: {
            en: {
                manageCustomerVatSettings: 'Manage VAT settings',
            },
            de: {
                manageCustomerVatSettings: 'Manage VAT settings',
            },
        },
    },
    data() {
        return {
            loading: false,
            loadingForRejectOrApprove: false,

            title: 'Member',
            plan: null,
            planLoading: false,
            user: {},

            timepassesWallet: [],
            userNotes: [],
            showErrors: false,
            isMe: false,
            loader: false,
            deleting: false,
            editablePlan: null,
            tags: null,
            newNote: {},
            customizePlanModal: false,
            taxRate: null,
            confirmSelection: true,
            ID: this.$route.params.ID,
            paymentMethods: [],
            upcomingPlan: null,
            userStatus: [
                {
                    displayText: 'Active',
                    value: 0,
                    variant: 'success',
                },
                { displayText: 'Suspended', value: 3, variant: 'warning' },
                {
                    displayText: 'Cancelled',
                    value: 4,
                    variant: 'danger',
                },
                {
                    displayText: 'Waiting for approval',
                    value: 5,
                    variant: 'warning',
                },
            ],

            // feed
            feed: [],
            feedNextPage: null,
            projects: [],
            selectedStatus: {},
            initialStatus: {},
            jobs: null,
            billingAddress: {},
            registrationAddress: null,

            upcomingInvoice: null,
            upcomingInvoiceLoading: false,

            membership: {} as UserMembership | null,
            membershipStatusIcon: 'check-circle',
            membershipLoading: false,
        };
    },
    computed: {
        userName() {
            if (this.user.Profile && this.user.Profile.FirstName && this.user.Profile.LastName) {
                return `${this.user.Profile.FirstName} ${this.user.Profile.LastName}`;
            }

            if (this.user.Profile && this.user.Profile.Name) {
                return this.user.Profile.Name;
            }

            return this.user.Email;
        },
        titleForTimePasses() {
            if (this.timepassesWallet.length > 1) {
                return `${this.timepassesWallet[0].Name} +${this.timepassesWallet.length - 1} more`;
            }
            if (this.timepassesWallet.length === 1) {
                return this.timepassesWallet[0].Name;
            }
            return 'No timepasses';
        },

        titleForNextInvoice() {
            if (this.upcomingInvoiceLoading) {
                return 'Loading...';
            }

            if (this.upcomingInvoice) {
                let timestamp = 0;
                // if this.upcomingInvoice.Created is a string convert it to a number
                if (typeof this.upcomingInvoice.Created === 'string') {
                    timestamp = parseInt(this.upcomingInvoice.Created, 10);
                } else {
                    timestamp = this.upcomingInvoice.Created;
                }

                // if timestamp is in milliseconds convert it to seconds
                if (timestamp > 9999999999) {
                    timestamp /= 1000;
                }

                return `${format(fromUnixTime(timestamp), 'hh:mm yyyy.MM.dd')}`;
            }
            return 'No upcoming invoice';
        },
    },
    created() {
        const { ID } = this.$route.params;
        this.adminGetUserByID(ID);

        // this.membership = {
        //     status: 'UNSPECIFIED',
        //     stripeCustomerLink: null,
        //     stripeCustomerID: 'cus-H4Z2Z2',

        //     currentBillingPeriodStart: null, // unix timestamp in seconds when the current billing period started
        //     currentBillingPeriodEnd: null, // unix timestamp in seconds when the current billing period ends
        //     billingCycleAnchor: null, // unix timestamp in seconds when the billing cycle anchor was set
        //     cancelAt: null, // unix timestamp in seconds when the subscription will be canceled
        //     canceledAt: null, // unix timestamp in seconds when the subscription was canceled
        //     startDate: null, // unix timestamp in seconds when the subscription was created
        //     endedAt: null, // unix timestamp in seconds when the subscription ended
        //     created: null, // unix timestamp in seconds when the membership was created
        // };
    },
    watch: {
        user(newVal, oldVal) {
            if (newVal && newVal.ID) {
                this.getUserPaymentMethods(newVal.ID);
                this.getPlanByID(newVal.ID);
                this.getMembership(newVal.ID);
                this.getFeedForUser(newVal.ID);
                this.getProjectsForUser(newVal.ID);
                this.getUserNotes(newVal.ID);
                this.getUserAdresses(newVal.ID);
                this.getInvoicesByID(newVal.ID);
            }
        },
    },
    mounted() {
        this.getTimepassesWallet();
    },
    methods: {
        get,
        setBreadcrumbs() {
            let name = '';
            if (!this.user.Profile) {
                name = this.user.Email;
            } else {
                name = `${this.user.Profile.FirstName} ${this.user.Profile.LastName}`;
                if (name === ' ') {
                    name = this.user.Profile.Name;
                }

                if (name === ' ') {
                    this.name = this.user.Email;
                }
            }
            this.$store.commit('SET_BREADCRUMBS', [
                {
                    text: 'Manage',
                    to: '/admin',
                },
                {
                    text: 'Members',
                    to: '/admin/community/member/list',
                },
                {
                    text: name,
                },
            ]);
        },
        setMembershipStatusIcon() {
            if (
                !this.membership ||
                !this.membership.subscriptionStatus ||
                this.membership.subscriptionStatus === 'UNSPECIFIED'
            ) {
                this.membershipStatusIcon = 'check-circle';
            }
            switch (this.membership.subscriptionStatus) {
                case 'ACTIVE':
                    this.membershipStatusIcon = 'check-circle';
                    break;
                case 'TRIALING':
                    this.membershipStatusIcon = 'check-circle';
                    break;
                case 'PAST_DUE':
                    this.membershipStatusIcon = 'exclamation-triangle';
                    break;
                case 'UNPAID':
                    this.membershipStatusIcon = 'exclamation-triangle';
                    break;
                case 'CANCELLED':
                    this.membershipStatusIcon = 'exclamation-triangle-fill';
                    break;
                case 'INCOMPLETE':
                    this.membershipStatusIcon = 'clock-circle';
                    break;
                case 'INCOMPLETE_EXPIRED':
                    this.membershipStatusIcon = 'exclamation-triangle';
                    break;
                default:
                    this.membershipStatusIcon = 'exclamation-triangle';
            }
        },
        formatDate(date) {
            if (!date) {
                return '-';
            }

            // parse string to int
            const parsedInt = parseInt(date, 10);
            const parsedDate = fromUnixTime(parsedInt);

            return format(parsedDate, 'HH:mm yyyy.MM.dd');
        },
        getMembership(ID) {
            this.membershipLoading = true;
            axios({
                method: 'GET',
                url: `/admin/community/member/membership/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        this.membership = response.data as UserMembership;
                    }
                })
                .catch((error) => {
                    this.membership = {
                        status: 'UNSPECIFIED',
                        stripeCustomerLink: null,
                        stripeCustomerID: '',

                        currentBillingPeriodStart: null, // unix timestamp in seconds when the current billing period started
                        currentBillingPeriodEnd: null, // unix timestamp in seconds when the current billing period ends
                        billingCycleAnchor: null, // unix timestamp in seconds when the billing cycle anchor was set
                        cancelAt: null, // unix timestamp in seconds when the subscription will be canceled
                        canceledAt: null, // unix timestamp in seconds when the subscription was canceled
                        startDate: null, // unix timestamp in seconds when the subscription was created
                        endedAt: null, // unix timestamp in seconds when the subscription ended
                        created: null, // unix timestamp in seconds when the membership was created
                    };
                    console.log(error);
                })
                .finally(() => {
                    this.setMembershipStatusIcon();
                    this.membershipLoading = false;
                });
        },

        displayMonth(expMonth) {
            if (expMonth.length == 1) {
                let newMonth = '0';
                newMonth += expMonth;
                return newMonth;
            }
            return expMonth;
        },

        getUserPaymentMethods(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/payment/stripe/payment-methods/${userID}?UserID=${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.paymentMethods = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        goToInvoicesOfUser() {
            this.$router.push(`/admin/community/member/invoices/${this.user.ID}`);
        },

        addNewNote() {
            this.getUserNotes(this.user.ID);

            // this.newNote = JSON.parse(JSON.stringify(note));
            // if (this.userNotes) {
            //   this.userNotes.unshift(this.newNote);
            // } else {
            //   this.userNotes = [this.newNote];
            // }
        },

        viewProfile(slug) {
            this.$router.push(`/profile/${slug}`);
        },
        editMember(ID) {
            this.$router.push(`/admin/community/member/edit/${ID}`);
        },

        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.$data.user = response;

                    if (this.user.Profile && this.user.Profile.Name) {
                        if (this.user.Profile.FirstName === '' || this.user.Profile.FirstName == null) {
                            const names = this.user.Profile.Name.split(' ');
                            this.user.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.user.Profile.LastName = names.join(' ');
                            } else {
                                this.user.Profile.LastName = names[0];
                            }
                        }
                        if (this.user.Status) {
                            this.selectedStatus = this.userStatus.find((x) => x.value === this.user.Status);
                            if (!this.selectedStatus) {
                                this.selectedStatus = this.userStatus[0];
                            }
                        } else {
                            // eslint-disable-next-line prefer-destructuring
                            this.selectedStatus = this.userStatus[0];
                        }
                        this.initialStatus = this.selectedStatus;

                        this.setBreadcrumbs();
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$router.push(`/admin/community/member/list`);
                });
        },

        getUserNotes(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/notes/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const notes = response.data;
                        // sort notes by date desc
                        notes.sort((a, b) => b.Timestamp - a.Timestamp);
                        this.userNotes = notes;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getUserAdresses(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/community/member/billing-address/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && Object.keys(response.data).length > 0) {
                        this.billingAddress = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios({
                method: 'GET',
                url: `/admin/community/member/registration-address/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && Object.keys(response.data).length > 0) {
                        this.registrationAddress = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getProjectsForUser(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/profile/projects/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.projects = response.data.objects; // todo support pagination
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        getFeedForUser(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/profile/feed/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.feed = response.data.objects;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        takeContact(userObj) {
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.ID],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const routeData = this.$router.resolve(`/messenger/${response.data.ID}`);
                        window.open(routeData.href, '_blank');
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getPlanByID(id) {
            this.planLoading = true;
            axios({
                method: 'GET',
                url: `/admin/community/member/plan?userid=${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plan = response.data.current.Plan;
                        this.upcomingPlan = response.data.current.Plan;
                        this.editablePlan = response.data.current.Plan;
                        if (this.plan.TaxRate) {
                            this.taxRate = this.planTaxRate;
                        } else if (this.plan.TaxRateID) {
                            this.getTaxRate(this.plan.TaxRateID);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.planLoading = false;
                });
        },
        getTaxRate(taxID) {
            axios({
                method: 'GET',
                url: `/space/tax/${taxID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRate = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getTimepassesWallet() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/space/time-passes/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((timepassesWallet) => {
                    this.loading = false;
                    if (timepassesWallet && timepassesWallet.data && timepassesWallet.data.Passes) {
                        this.timepassesWallet = [];
                        this.timepassesWallet = timepassesWallet.data.Passes;
                    }
                })
                .catch((error) => {
                    this.timepassesWallet = [];
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg = "Can't load time passes in user's wallet";
                    } else {
                        this.errorMsg = "Can't load time passes in user's wallet";
                    }
                });
        },
        saveBillingAddress(address) {
            this.loading = true;

            axios({
                method: 'PUT',
                url: `/admin/community/member/billing-address/${this.user.ID}`,
                data: JSON.stringify(address),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const msg = {
                        Message: 'Billing Address Updated',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.getUserAdresses(this.user.ID);
                    this.$bvModal.hide('modal-edit-billing-address');
                    this.loading = false;
                    //refresh the membership data
                    this.getMembership(this.user.ID);
                })
                .catch((error) => {
                    this.loading = false;

                    EventBus.$emit('ERROR', {
                        Message: 'Could not update billing address. Please try again later.',
                        Details: null,
                    });
                });
        },
        saveRegistrationAddress(address) {
            this.loading = true;
            axios({
                method: 'PUT',
                url: `/admin/community/member/registration-address/${this.user.ID}`,
                data: JSON.stringify(address),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const msg = {
                        Message: 'Registration Address Updated',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.registrationAddress = address;
                    this.$bvModal.hide('modal-edit-registration-address');
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    EventBus.$emit('ERROR', {
                        Message: 'Could not update registration address. Please try again later.',
                        Details: null,
                    });
                });
        },
        cancelBillingAddress() {
            this.$bvModal.hide('modal-edit-billing-address');
        },
        cancelRegistrationAddress() {
            this.$bvModal.hide('modal-edit-registration-address');
        },

        getCardText(brand) {
            switch (brand) {
                case 'amex':
                    return 'American Express';
                case 'diners':
                    return 'Diners';
                case 'discover':
                    return 'Discover';
                case 'jcb':
                    return 'JCB';
                case 'mastercard':
                    return 'Mastercard';
                case 'unionpay':
                    return 'UnionPay';
                case 'visa':
                    return 'VISA';
                case 'maestro':
                    return 'Maestro';
                case 'sepa_debit':
                    return 'Sepa Debit';
            }
        },

        getInvoicesByID(userID) {
            this.upcomingInvoiceLoading = true;
            axios({
                method: 'GET',
                url: `/admin/payment/stripe/invoices/${userID}`,
                timeout: 5000,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.UpcomingInvoice) {
                        this.upcomingInvoice = response.data.UpcomingInvoice;
                        this.upcomingInvoice.Created = this.upcomingInvoice.Created * 1000;
                    }
                    this.upcomingInvoiceLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.upcomingInvoiceLoading = false;
                });
        },
        openModal(status) {
            this.selectedStatus = this.userStatus.find((s) => s.value === status);

            this.$refs['subscription-status-modal'].show();
        },
        discardStatus() {
            this.selectedStatus = this.initialStatus;
            this.$refs['subscription-status-modal'].hide();
        },
        confirmChangeStatus() {
            this.loading = true;
            this.user.Status = this.selectedStatus.value;

            this.$store
                .dispatch('adminUpdateOtherUser', this.user)
                .then((response) => {
                    // todo delete previuos file
                    const message = {
                        Message: 'Account details changed',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.initialStatus = this.selectedStatus;

                    this.$refs['subscription-status-modal'].hide();
                    this.$data.loader = false;
                })
                .catch((error) => {
                    const message = {
                        Message: 'Could not update account details.',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$refs['subscription-status-modal'].hide();
                    this.$data.loader = false;
                });
        },
        detachPaymentMethod(paymentMethodID) {
            this.loading = true;
            this.$store
                .dispatch('detachPaymentMethodAdmin', { userId: this.user.ID, methodID: paymentMethodID })
                .then(() => {
                    this.loading = false;
                    // delete payment method from list
                    this.paymentMethods = this.paymentMethods.filter((pm) => pm.ID !== paymentMethodID);
                    EventBus.$emit('INFO', {
                        Message: 'Payment method detached',
                        Details: null,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Could not detach payment method. Please contact support via Intercom widget.',
                        Details: null,
                    });
                    this.loading = false;
                });
        },

        approveUser() {
            this.loadingForRejectOrApprove = true;

            const data = {
                userID: this.user.ID,
            };

            axios({
                method: 'POST',
                url: `/admin/community/member/approve`,
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const message = {
                        Message: 'User approved',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.adminGetUserByID(this.user.ID);
                })
                .catch((error) => {
                    const message = {
                        Message: 'User approval failed',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', message);
                    console.log(error);
                })
                .finally(() => {
                    this.loadingForRejectOrApprove = false;
                });
        },

        rejectUser() {
            this.loadingForRejectOrApprove = true;
            const data = {
                userID: this.user.ID,
            };

            axios({
                method: 'POST',
                url: `/admin/community/member/reject`,
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const message = {
                        Message: 'User has been rejected',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.$router.push('/admin/community/member/list');
                })
                .catch((error) => {
                    const message = {
                        Message: 'User rejection failed',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', message);
                    console.log(error);
                })
                .finally(() => {
                    this.loadingForRejectOrApprove = false;
                });
        },
    },
};
