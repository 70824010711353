































































































































































import i18n from 'vue-i18n';
import { fromUnixTime, format, sub, isAfter } from 'date-fns';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { get } from 'lodash';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import EventBus from '../../eventBus';

export default {
    components: { CoSkeleton, CoRoundButton },
    i18n: {
        messages: {
            en: {
                nobookings: "You don't have any upcoming bookings. You can create one right now!",
                cancellationinfo:
                    'Booking cancellation less than {hours} hours before the event start time is not allowed.',
            },
            de: {
                nobookings: 'Du hast keine zukünftigen Buchungen. Erstelle einfach eine neue!',
                cancellationinfo:
                    'Eine Stornierung weniger als {hours} Stunden vor dem Buchungsbeginn ist nicht möglich.',
            },
        },
    },
    name: 'MyBookings',
    data() {
        return {
            loading: true,
            bookings: [],
            clickedIndex: null,
            sameDay: false,

            googleCalendarLink: '',
            icsCalendarLink: '',
            outlookCalendarLink: '',
        };
    },
    created() {
        this.getMyBookings();
    },
    methods: {
        cancelBooking(index) {
            this.clickedIndex = index;
            const booking = this.bookings[index];
            this.$store
                .dispatch('cancelBookingV2', booking.ID)
                .then((response) => {
                    const infoMsg = {
                        Message: 'Booking canceled',
                        Details: null,
                    };
                    EventBus.$emit('INFO', infoMsg);
                    this.clickedIndex = null;
                    this.bookings.splice(index, 1);
                })
                .catch((error) => {
                    setTimeout(() => {
                        let infoMsg = {
                            Message: 'Failed to cancel booking. Please contact our team! Sorry for inconvenience.',
                            Details: '',
                        };
                        if (error && error.response && error.response.data) {
                            infoMsg = {
                                Message: `Failed to cancel booking. Please contact our team! Sorry for inconvenience. Error details: ${error.response.data}`,
                                Details: error.response.data,
                            };
                        }
                        EventBus.$emit('Error', infoMsg);
                        this.clickedIndex = null;
                    }, 800);
                });
        },
        getMyBookings() {
            this.$store
                .dispatch('getMyBookingsV2')
                .then((response) => {
                    this.bookings = get(response, 'UpcommingBookings', []).filter((item) => !item.Block);

                    if (this.bookings.length === 0) {
                        return;
                    }

                    this.bookings.forEach((entry, index) => {
                        const start = fromUnixTime(entry.Start);
                        const end = fromUnixTime(entry.End);

                        const dateStrStart = format(start, 'iii, dd.MM.yy');
                        const dateStrEnd = format(end, 'iii, dd.MM.yy');
                        const timeStrStart = format(start, 'HH:mm');
                        const timeStrEnd = format(end, 'HH:mm');

                        this.bookings[index].DateStart = dateStrStart;
                        this.bookings[index].DateEnd = dateStrEnd;
                        this.bookings[index].StartTime = timeStrStart;
                        this.bookings[index].EndTime = timeStrEnd;

                        this.bookings[index].SameDay = dateStrStart === dateStrEnd;

                        const cancelPeriod = start;
                        const diff = sub(cancelPeriod, {
                            hours: entry.Resource.CancellationPeriod,
                        });
                        let canCancel = true;
                        const now = new Date();
                        if (isAfter(now, diff)) {
                            canCancel = false;
                        }

                        this.bookings[index].canCancel = canCancel;
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        convertToPlain(html) {
            const tempDivElement = document.createElement('div');
            tempDivElement.innerHTML = html;
            return tempDivElement.textContent || tempDivElement.innerText || '';
        },
        makeCalendarLink(booking, type) {
            this.calendarEvent = {
                title: booking.Title,
                description: this.convertToPlain(booking.Resource.Name),
                start: new Date(booking.Start * 1000).toISOString(),
                end: new Date(booking.End * 1000).toISOString(),
            };

            // Then fetch the link
            if (type === 'google') {
                this.googleCalendarLink = google(this.calendarEvent);
                window.location.href = this.googleCalendarLink;
            } else if (type === 'outlook') {
                this.outlookCalendarLink = outlook(this.calendarEvent);
                window.location.href = this.outlookCalendarLink;
            } else if (type === 'ics') {
                this.icsCalendarLink = ics(this.calendarEvent);
                window.location.href = this.icsCalendarLink;
            }
        },
    },
};
