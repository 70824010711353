var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-event-view"},[(!_vm.$store.state.isInMobile)?_c('CoCard',{staticClass:"mb-3",attrs:{"variant":"border"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-center w-100"},[_c('CoRoundButton',{staticClass:"mr-2",attrs:{"icon":"chevron-left","variant":"icon"},on:{"click":_vm.backtToEvent}}),_c('CoHeadline',{staticClass:"d-none d-md-block mr-auto",attrs:{"level":3,"trunc":""}},[_vm._v(_vm._s(_vm.event.Title ? _vm.event.Title : _vm.$t('labels.event')))])],1)]):_vm._e(),_c('div',{staticClass:"participants-table mb-3"},[_c('CoTable',{attrs:{"title":_vm.$t('labels.ticketssold'),"items":_vm.ticketsSold,"loading":_vm.loadingTaxRates,"preventHorizontalScroll":true,"resizableColumns":true,"csvDownload":!_vm.$store.state.isInMobile,"columns":[
                {
                    title: _vm.$t('labels.name'),
                    key: 'Name',
                },
                {
                    title: _vm.$t('labels.quantity'),
                    key: 'Quantity',
                },
                {
                    title: _vm.$t('labels.price'),
                    key: 'Price',
                },
                {
                    title: _vm.$t('labels.vat'),
                    key: 'Vat',
                },
                {
                    title: _vm.$t('labels.sum'),
                    key: 'TotalPrice',
                } ]},scopedSlots:_vm._u([{key:"Quantity",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.SoldCount ? item.SoldCount : 0)+" / "+_vm._s(item.Limit)+" ")]}},{key:"Vat",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.TaxRate)+" ")]}}])})],1),_c('div',{staticClass:"participants-table"},[_c('CoTable',{attrs:{"title":_vm.$t('labels.attendees'),"items":_vm.participantslist,"loading":_vm.loadingParticipants,"preventHorizontalScroll":true,"resizableColumns":true,"csvDownload":!_vm.$store.state.isInMobile,"columns":[
                {
                    title: _vm.$t('labels.name'),
                    key: 'UserName',
                },
                {
                    title: _vm.$t('labels.ticket'),
                    key: 'Name',
                },
                {
                    title: _vm.$t('labels.ticketcode'),
                    key: 'ID',
                },
                {
                    title: _vm.$t('labels.price'),
                    key: 'Price',
                } ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }