<style lang="less" scoped>
@import '~@/assets/less/variables.less';
@import '~@/assets/less/coapp.less';

.grey-text {
    color: #868685;
}

.table-height-60 {
    height: 55vh;
}

th,
td:not(:first-of-type) {
    white-space: nowrap;
}
th:not(:first-of-type),
td:not(:first-of-type) {
    padding-left: 1rem;
}
</style>

<template>
    <div class="mb-5 mw-100">
        <!-- header start -->
        <div class="d-flex align-items-center p-3">
            <CoHeadline :level="2" class="flex-shrink-0 mr-auto">{{ $t('labels.billing') }}</CoHeadline>
            <CoButton
                variant="primary"
                @click="$router.push('/account/billing/payment-methods')"
                class="flex-shrink-1 ml-2"
                style="min-width: 0"
                :label="$t('labels.paymentmethods')"
                truncate
            />
        </div>
        <div v-if="billingAddress" class="align-self-start mt-4 px-0 px-sm-4 overflow-hidden round-unify-xs">
            <CoCardBillingAddress
                :billingAddress="billingAddress"
                :user-name="this.$store.state.me.Profile.Name"
                :save-billing-address-data="saveBillingAddressData"
            />
        </div>
        <!-- Invoice Table -->
        <div class="px-sm-4">
            <div class="d-flex flex-wrap p-3 align-items-center justify-content-between">
                <CoText :text="$t('labels.invoices')" variant="mute" class="mb-0" />
            </div>
            <coTable
                :items="allInvoices"
                :sortable="false"
                :loading="invoicesLoading"
                :preventHorizontalScroll="true"
                :resizableColumns="true"
                :columns="[
                    {
                        title: $t('labels.date'),
                        key: 'Created',
                    },
                    {
                        title: $t('labels.status'),
                        key: 'Status',
                        class: 'd-none d-md-table-cell',
                    },
                    {
                        title: $t('labels.amount'),
                        key: 'Total',
                        class: 'text-right',
                    },
                    {
                        title: '',
                        key: 'Action',
                    },
                ]"
            >
                <template v-slot:Created="slotProps">
                    <co-date :date="slotProps.item['Created']" format="exact" />
                </template>
                <template v-slot:Status="slotProps">
                    {{ $t(slotProps.item.Status) }}
                </template>
                <template v-slot:Total="slotProps">
                    <co-price
                        inheritTextSizing
                        :priceObj="{
                            PriceInCents: slotProps.item['Total'],
                            Currency: slotProps.item['Currency'],
                        }"
                    />
                </template>
                <template v-slot:Action="slotProps">
                    <CoLink
                        v-if="
                            slotProps.item && (slotProps.item.Status === 'open' || slotProps.item.Status === 'overdue')
                        "
                        :isExternalLink="true"
                        :newTab="true"
                        :to="slotProps.item.HostedInvoiceURL"
                    >
                        {{ $t('labels.pay') }}
                    </CoLink>
                    <CoLink
                        v-else-if="slotProps.item && slotProps.item.InvoicePDF"
                        :isExternalLink="true"
                        :newTab="true"
                        :to="slotProps.item.HostedInvoiceURL"
                    >
                        {{ $t('labels.view') }}
                    </CoLink>
                    <CoLink
                        v-else-if="slotProps.item && slotProps.item.InvoiceLines"
                        :isExternalLink="true"
                        @click.native.prevent="showInvoicePreview(slotProps.item)"
                    >
                        {{ $t('labels.preview') }}
                    </CoLink>
                </template>
            </coTable>
        </div>

        <CoModal size="lg" ref="invoicePreviewModal" :title="$t('labels.invoicepreview')">
            <template slot="body">
                <co-invoice-detail :invoice="previewInvoice" />
            </template>
        </CoModal>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import Bugsnag from '@bugsnag/js';

import { filter, get } from 'lodash';
import CoCardBillingAddress from '@/components/Organisms/co-card-billing-address/CoCardBillingAddress.vue';
import CoText from '../../components/Atoms/co-text/CoText.vue';
import CoDate from '../../components/Molecules/co-date/CoDate.vue';
import CoPrice from '../../components/Atoms/co-price/coPrice.vue';
import i18n from 'vue-i18n';
import CoLink from '../../components/Atoms/co-link/CoLink.vue';
import CoModal from '../../components/Organisms/co-modal/CoModal.vue';
import CoSubscriptionNextInvoicePreview from '../../components/Organisms/co-subscription-next-invoice-preview/CoSubscriptionNextInvoicePreview.vue';
import CoInvoiceDetail from '../../components/Molecules/co-invoice-detail/CoInvoiceDetail.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

export default {
    i18n: {
        messages: {
            en: {
                noinvoices: "You don't have any upcoming or past invoices yet",
            },
            de: {
                noinvoices: 'Du hast bisher keine Rechnungen.',
                open: 'unbezahlt',
                overdue: 'überfällig',
                draft: 'entwurf',
                paid: 'bezahlt',
            },
        },
    },
    name: 'Invoices',
    components: {
        CoCardBillingAddress,
        CoText,
        CoDate,
        CoPrice,
        CoLink,
        CoModal,
        CoSubscriptionNextInvoicePreview,
        CoInvoiceDetail,
    },
    data() {
        return {
            paymentMethods: [],
            loading: false,
            invoicesLoading: false,
            errorMsg: null,

            publicKey: '',
            accountID: '',

            invoicesOnPage: [],
            previewInvoice: null,
            allInvoices: [],

            billingAddress: null,
        };
    },
    mounted() {
        this.getMyInvoices();
        this.getUser();
    },

    methods: {
        getMyInvoices() {
            this.invoicesLoading = true;
            axios({
                method: 'GET',
                url: '/user/account/billing/invoices',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        if (response.data.PastInvoices) {
                            // hide draft invoices from customers
                            this.allInvoices = filter(response.data.PastInvoices, (o) => o.Status !== 'draft');
                        }
                        if (response.data.UpcomingInvoice) {
                            this.allInvoices.unshift(response.data.UpcomingInvoice);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.invoicesLoading = false;
                });
        },
        formatDate(timestamp) {
            const not_formatted_date = new Date(timestamp * 1000);
            if (this.windowWidth > 1000) {
                return moment(not_formatted_date).format('YYYY-MM-DD');
            }
            return moment(not_formatted_date).format('MM-DD');
        },
        downloadPDF(invoice) {
            window.open(invoice.InvoicePDF, '_blank');
        },
        showModal(invoice) {
            this.upcomingInvoice = invoice;
            this.$refs['view-upcoming-invoice-modal'].show();
        },
        trimString(string) {
            if (string == null) {
                return '';
            }

            if (string.length > 43) {
                if (this.windowWidth > 1000) {
                    string = `${string.substring(0, 43 - 1)}...`;
                } else if (this.windowWidth > 450) {
                    string = `${string.substring(0, 15)}...`;
                }
            }
            if (this.windowWidth <= 450) {
                string = 'view';
            }

            return string;
        },
        getUser() {
            this.$store
                .dispatch('getMe', null)
                .then((response) => {
                    this.$root.$data.$me = response.data;
                    this.billingAddress = response.data.Address;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveBillingAddressData(billingAddress) {
            this.billingAddress = billingAddress;
            const data = { Address: billingAddress };
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/dashboard/me-billing',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data,
                })
                    .then((response) => {
                        EventBus.$emit('INFO', { Message: this.$t('messages.changessaved') });
                    })
                    .catch((error) => {
                        console.log(error);
                        Bugsnag.notify(error);

                        if (get(error, 'response.data', '').match('tax_id_invalid')) {
                            EventBus.$emit('ERROR', {
                                Message: this.$t('labels.vatid') + ' ' + this.$t('errors.invalid'),
                            });
                        } else {
                            EventBus.$emit('ERROR', { Message: this.$t('errors.failedGeneric') });
                        }
                    })
                    .finally(() => {
                        resolve();
                        //update the billing address in the user object
                        this.getUser();
                    });
            });
        },
        showInvoicePreview(invoice) {
            this.previewInvoice = invoice;
            this.$refs.invoicePreviewModal.show();
        },
    },
};
</script>
