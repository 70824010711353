<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

.no-style {
    color: black;
    text-decoration: none;
}

.max-width-700 {
    max-width: 700px;
}

.cancel-plan {
    background: #fd6969;
}
</style>

<template>
    <div class="">
        <!-- search and actions start -->
        <div
            v-if="timepassesWallet && timepassesWallet.length != 0"
            class="d-flex flex-wrap p-4 pr-0 pr-lg-5 align-items-center justify-content-between mb-2 w-100"
        >
            <div class="d-flex flex-column pl-md-2 mt-3 mb-4 w-100">
                <span class="grey-text pl-md-2 ml-2">User's wallet</span>
                <div class="row m-0 p-0 mt-1">
                    <div class="col-12 m-0 p-0 pr-4 mt-2" v-for="timepass in timepassesWallet" :key="timepass.ID">
                        <TimepassCardWallet
                            v-if="
                                activeTimepass &&
                                activeTimepass.WalletItem &&
                                timepass.ID == activeTimepass.WalletItem.ID
                            "
                            :admin="true"
                            :active="true"
                            :userID="user.ID"
                            :timepass="timepass"
                        ></TimepassCardWallet>
                        <TimepassCardWallet
                            v-else
                            :disabled="activeTimepass && activeTimepass.WalletItem ? true : false"
                            :admin="true"
                            :active="false"
                            :userID="user.ID"
                            :timepass="timepass"
                            @updatetimepass="updateWallet()"
                        ></TimepassCardWallet>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-alert class="col-4 ml-5" show variant="danger">No time passes in wallet</b-alert>
        </div>

        <div
            v-if="activeTimepass && activeTimepass.WalletItemID"
            class="d-flex flex-wrap p-4 pr-0 pr-lg-5 align-items-center justify-content-between mb-2 w-100"
        >
            <div class="d-flex flex-column pl-md-2 mt-3 mb-4 w-100">
                <span class="grey-text pl-md-2 ml-2">Active timepass</span>
                <div class="row m-0 p-0 mt-1">
                    <div class="col-12 p-0">
                        <table class="w-100 p-0 m-0 mt-4 resource-table">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50%">Name</th>
                                    <th scope="col" style="width: 25%">Applied at</th>
                                    <th scope="col" style="width: 25%">Expiration at</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ activeTimepass.WalletItem.Name }}</td>
                                    <td>{{ activeTimepass.appliedTime }}</td>
                                    <td>{{ activeTimepass.ValidUntillTime }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="" v-if="user && user.Profile">
            <Header :title="'Buy new Passes for ' + user.Profile.FirstName" icon="cart"></Header>
        </div>

        <div
            v-if="timepassesSpace && timepassesSpace.length != 0"
            class="d-flex flex-wrap p-4 pr-0 pr-lg-5 align-items-center justify-content-between mb-2 w-100"
        >
            <!--                max-width-700-->
            <div class="d-flex flex-column pl-md-2 mt-3 mb-4 w-100">
                <div class="row m-0 p-0 mt-1">
                    <div
                        class="col-12 m-0 p-0 pr-4 mt-2"
                        v-for="(timepass, index) in timepassesSpace"
                        :key="timepass.ID"
                    >
                        <TimepassCard
                            @checkPayment="checkPaymentMethods(timepass.ID)"
                            :timepass="timepass"
                            :isAdminSpace="true"
                            :user="user"
                        ></TimepassCard>
                        <div v-if="errorMsg && index == 0" class="d-flex align-items-center pt-4">
                            <b-alert show variant="danger">{{ errorMsg }}</b-alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-alert class="col-4" show variant="danger">No time passes available in your space</b-alert>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import TimepassCard from '@/components/cards/TimepassCard.vue';
import TimepassCardWallet from '@/components/cards/TimepassCardWallet.vue';
import EventBus from '../../../../../eventBus';
import { format, fromUnixTime } from 'date-fns';

export default {
    name: 'MemberAccountTimePass',
    components: { TimepassCardWallet, TimepassCard },
    data() {
        return {
            me: this.$store.state.me,
            paymentMethods: [],
            errorMsg: '',
            user: {},
            loading: false,

            timepassesWallet: [],
            timepassesSpace: [],
            activeTimepass: {},
        };
    },
    created() {
        const { ID } = this.$route.params;
        this.adminGetUserByID(ID);
    },
    mounted() {
        this.getTimepassesWallet();
        this.getTimepassesSpace();
        this.getActiveTimePass();
    },
    methods: {
        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.$data.user = response;

                    if (this.user.Profile && this.user.Profile.Name) {
                        if (this.user.Profile.FirstName === '' || this.user.Profile.FirstName == null) {
                            const names = this.user.Profile.Name.split(' ');
                            this.user.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.user.Profile.LastName = names.join(' ');
                            } else {
                                this.user.Profile.LastName = names[0];
                            }
                        }
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$router.push(`/admin/community/member/list`);
                });
        },
        getActiveTimePass() {
            this.loading = true;
            const { ID } = this.$route.params;
            axios({
                method: 'GET',
                url: `/admin/time-pass/active/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.activeTimepass = response.data;
                        this.activeTimepass.appliedTime = format(
                            fromUnixTime(this.activeTimepass.Timestamp),
                            'dd.MM.yyyy HH:mm'
                        );
                        this.activeTimepass.ValidUntillTime = format(
                            fromUnixTime(this.activeTimepass.ValidUntil),
                            'dd.MM.yyyy HH:mm'
                        );
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        getTimepassesWallet() {
            this.loading = true;
            const { ID } = this.$route.params;
            axios({
                method: 'GET',
                url: `/admin/time-pass/wallet/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.timepassesWallet = [];
                        response.data.WalletItems.forEach((element) => {
                            this.timepassesWallet.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getTimepassesSpace() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/time-pass/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Passes.forEach((element) => {
                            this.timepassesSpace.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        updateWallet() {
            this.getTimepassesWallet();
            this.getActiveTimePass();
        },
        checkPaymentMethods(ID) {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods && this.paymentMethods.length === 0) {
                        this.$router.push(`/account/time-passes/buy/${ID}`);
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    this.$router.push(`/account/time-passes/buy/${ID}`);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                });
        },
        activeTimepassFunc() {
            this.loading = true;
            // this.$store
            //     .dispatch('getActiveTimepass')
            axios({
                method: 'POST',
                url: 'admin/time-pass/apply',
                withCredentials: true,
                data: JSON.stringify(this.applyTimepassRequest),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((activeTimepass) => {
                    this.loading = false;
                    this.activeTimepass = activeTimepass;
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg = "Can't load active time pass";
                    } else {
                        this.errorMsg = "Can't load active time pass";
                    }
                });
        },
    },
};
</script>
