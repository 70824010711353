<style lang="less" scoped src="@/assets/less/manage-space/base.less">
.calendar-container {
    display: flex;
    // min-height: 100%;
    max-height: 100vh;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}

.calendar {
    max-height: 50vh;
    height: 50vh;
    margin: 0 10px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 1.125rem !important;
    background-color: #fff;

    .fc .fc-toolbar.fc-header-toolbar {
        border-top-left-radius: 1.125rem !important;
        border-top-right-radius: 1.125rem !important;
    }
}

.resources-list {
    max-height: 200px !important;
    height: 200px !important;
    overflow-y: auto;
}

.co {
    .btn .b-icon.bi {
        margin: 0 !important;
    }
}
</style>

<template>
    <b-overlay :show="loading">
        <div>
            <!-- header start -->
            <div class="d-flex justify-content-between w-100 align-items-center px-3 mb-2">
                <h2 class="mb-0"><span>Bookings</span>/ <span>Calendar</span></h2>
                <b-button variant="outline-primary" class="d-block d-lg-none btn-round" alt="Edit">
                    <b-icon
                        icon="filter"
                        class="m-0"
                        style="position: relative"
                        @click="viewFiltersToggle()"
                        scale="1"
                    ></b-icon>
                </b-button>
            </div>

            <!-- header end -->
            <div class="row p-0 m-0 mb-5">
                <div v-show="viewFilter" class="col-12 col-lg-3 col-xl-2">
                    <!-- search and actions start -->
                    <div
                        class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill mb-3 mt-lg-0 w-100"
                        v-if="searchEnabled"
                    >
                        <b-icon class="mr-2" icon="search"></b-icon>
                        <div class="search-input-wrapper">
                            <input
                                class="search-input"
                                type="search"
                                v-model="searchText"
                                placeholder="Search"
                                aria-label="Search"
                            />
                        </div>
                    </div>
                    <div id="locations_selector" class="mb-3 mt-5">
                        <div class="d-flex align-items-center">
                            <div
                                v-if="locationsSelectorExpanded"
                                class="coapp-icon pointer"
                                @click="toggleLocationSelector()"
                            >
                                <b-icon icon="chevron-up"></b-icon>
                            </div>
                            <div v-else class="coapp-icon pointer" @click="toggleLocationSelector()">
                                <b-icon icon="chevron-down"></b-icon>
                            </div>
                            <div class="ml-1">Location</div>
                        </div>
                        <div v-if="locationsSelectorExpanded">
                            <div v-for="(item, index) in locations" :key="item.ID" class="d-flex align-items-center">
                                <CoCheckbox
                                    color="#000"
                                    @change="toggleLocation(item)"
                                    :name="item.Name"
                                    v-model="locations[index].Selected"
                                >
                                </CoCheckbox>
                            </div>
                        </div>
                    </div>

                    <div id="categories_selector" class="mb-3">
                        <div class="d-flex align-items-center">
                            <div
                                v-if="categoriesSelectorExpanded"
                                class="coapp-icon pointer"
                                @click="toggleCategorySelector()"
                            >
                                <b-icon icon="chevron-up"></b-icon>
                            </div>
                            <div v-else class="coapp-icon pointer" @click="toggleCategorySelector()">
                                <b-icon icon="chevron-down"></b-icon>
                            </div>
                            <div class="ml-1">Category</div>
                        </div>
                        <div v-if="categoriesSelectorExpanded">
                            <div
                                v-for="(item, index) in categories"
                                :key="item.ID + index"
                                class="d-flex align-items-center"
                            >
                                <CoCheckbox
                                    color="#000"
                                    @change="toggleCategory(item)"
                                    :name="item.Name"
                                    v-model="categories[index].Selected"
                                >
                                </CoCheckbox>
                            </div>
                        </div>
                    </div>

                    <div id="resources_selector" class="mb-3">
                        <div class="d-flex align-items-center">
                            <div
                                v-if="resourceSelectorExpanded"
                                class="coapp-icon pointer"
                                @click="toggleResourceSelector()"
                            >
                                <b-icon icon="chevron-up"></b-icon>
                            </div>
                            <div v-else class="coapp-icon pointer" @click="toggleResourceSelector()">
                                <b-icon icon="chevron-down"></b-icon>
                            </div>
                            <div class="ml-1">Resources</div>
                        </div>
                        <div v-if="resourceSelectorExpanded">
                            <div class="resources-list" style="max-height: 512px; overflow-y: auto">
                                <div class="d-flex flex-column">
                                    <div
                                        v-for="(item, index) in filteredResources"
                                        :key="item.ID + index"
                                        class="d-flex align-items-center"
                                    >
                                        <CoCheckbox
                                            :name="filteredResources[index].Name"
                                            :color="filteredResources[index].Color"
                                            v-model="filteredResources[index].Selected"
                                            @change="toggleResource(item)"
                                        >
                                        </CoCheckbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="selected_resources_selector">
                        <div class="d-flex align-items-center">
                            <div
                                v-if="selectedResourceSelectorExpanded"
                                class="coapp-icon pointer"
                                @click="toggleSelectedResourceSelector()"
                            >
                                <b-icon icon="chevron-up"></b-icon>
                            </div>
                            <div v-else class="coapp-icon pointer" @click="toggleSelectedResourceSelector()">
                                <b-icon icon="chevron-down"></b-icon>
                            </div>
                            <div class="ml-1">Selected</div>
                        </div>
                        <div v-if="selectedResourceSelectorExpanded">
                            <div class="resources-list" style="max-height: 512px; overflow-y: auto">
                                <div class="d-flex flex-column">
                                    <div
                                        v-for="(item, index) in selectedResources"
                                        :key="item.ID"
                                        class="d-flex align-items-center"
                                    >
                                        <CoCheckbox
                                            :name="item.Name"
                                            :color="selectedResources[index].Color"
                                            :value="true"
                                            @change="toggleResource(item)"
                                        >
                                        </CoCheckbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <span class="text-primary pointer" @click="clearAllFilters()">Clear all filters</span>
                    </div>

                    <!-- search and actions end -->
                </div>

                <div class="col-12 col-lg-9 col-xl-10">
                    <div class="w-100 d-flex mb-3">
                        <b-button-toolbar
                            aria-label="Toolbar with button groups and dropdown menu"
                            class="w-100 d-flex justify-content-between"
                        >
                            <div class="d-flex">
                                <b-button-group class="mx-1">
                                    <b-button @click="prev" class="px-3">
                                        <b-icon icon="chevron-left" class="mr-0"></b-icon>
                                    </b-button>
                                    <b-button @click="next" class="px-3">
                                        <b-icon icon="chevron-right" class="mr-0"></b-icon>
                                    </b-button>
                                </b-button-group>
                                <b-button-group class="mx-1">
                                    <b-button @click="setDateToday" class="px-3">Today</b-button>
                                </b-button-group>
                                <b-button-group class="mx-1" v-if="selectedDate">
                                    <b-form-datepicker
                                        v-model="selectedDate"
                                        button-only
                                        locale="en-US"
                                        @context="onContext"
                                        button-class=" px-0"
                                    >
                                        <template #button-content class="px-2">
                                            <b-icon icon="calendar2-date" class="mr-0"></b-icon>
                                        </template>
                                    </b-form-datepicker>
                                </b-button-group>
                                <CoRoundButton variant="secondary" icon="plus-lg" @click="addBooking()" class="ml-2" />

                                <!-- <b-button-group class="mx-1">
                  <b-button variant="primary" class="btn-round">
                    <b-icon icon="plus" class="m-0" style="position: relative; bottom: 1px" scale="1"></b-icon>
                  </b-button>
                </b-button-group> -->
                            </div>

                            <b-button-group class="mx-1 mt-2 mt-md-0">
                                <b-button @click="changeView('timeGridWeek')" class="pl-3 pr-1">Week</b-button>
                                <b-button @click="changeView('timeGridThreeDays')" class="px-1">3 Days</b-button>
                                <b-button @click="changeView('timeGridOneDay')" class="px-1">Day</b-button>
                                <b-button @click="changeView('listWeek')" class="pr-3 pl-1">List</b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </div>

                    <div class="calendar-container">
                        <FullCalendar
                            class="calendar round-unify"
                            :options="calendarOptions"
                            v-if="calendarOptions.initialView"
                            ref="calendar"
                        >
                            <template v-slot:eventContent="arg" class="">
                                <!-- <div class="bg-white"> -->
                                <!-- <b>{{ arg.timeText }}</b> -->
                                <i>{{ arg.event.title }}</i>
                                <!-- </div> -->
                            </template>
                        </FullCalendar>
                    </div>
                </div>
            </div>

            <!-- resource select modal -->
            <CoResourceSelectModal
                :moreOptions="filteredResources"
                :bestOptions="selectedResources"
                @select="selectResourceToBook"
                ref="resource-select-modal"
                :show="bookingCandidate.showResourceSelector"
            ></CoResourceSelectModal>

            <CoBookingCheckoutForAdmin
                v-if="bookingCandidate && bookingCandidate.resource"
                :bookingCandidate="bookingCandidate"
                @created="bookingCreated"
                @hideModals="hideModals"
            ></CoBookingCheckoutForAdmin>
            <b-modal
                v-if="bookingComponent.ID"
                :id="`cancel-modal-${bookingComponent.ID}`"
                :ref="`cancel-modal-${bookingComponent.ID}`"
                size="md"
                title="Are you sure you want to cancel?"
                @ok="cancelBooking"
                ok-title="Cancel Booking"
                cancel-title="Back"
                ok-variant="danger"
                centered
                :busy="deleting"
                no-fade
                modal-class="cancel-modal"
                hide-footer
            >
                <b-overlay :show="deleting" class="mb-0 pb-0">
                    <p class="mb-1" v-if="bookingComponent && bookedForUser && startDate">
                        Are you sure about canceling
                        {{ bookedForUser.Profile.Name }}'s booking of {{ bookingComponent.Resource.Name }} on
                        {{ startDate }}
                        ?
                    </p>
                    <div class="d-flex w-100">
                        <div class="d-flex align-items-center justify-content-between pt-2 mt-2 w-100">
                            <div>
                                <b-button
                                    class="mr-3 ml-0 pl-0"
                                    variant="no-outline"
                                    style="color: #868685"
                                    @click="$bvModal.hide(`cancel-modal-${bookingComponent.ID}`)"
                                    >Discard
                                </b-button>
                            </div>
                            <div>
                                <b-button
                                    @click="cancelBooking()"
                                    variant="primary"
                                    class="btn btn-primary rounded-pill"
                                    >Cancel and notify
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </b-modal>

            <b-modal
                :ref="`modal-booking-${bookingComponent.ID}`"
                :id="`modal-booking-${bookingComponent.ID}`"
                size="md"
                modal-class=""
                header-class=""
                footer-class=""
                body-class=""
                scrollable
                centered
                :title="bookingComponent.Title"
                hide-footer
            >
                <template>
                    <b-overlay :show="loadingModal" class="p-0 m-0">
                        <div class="m-0">
                            <span>{{ startDate }}</span
                            ><span> <b-icon icon="dot" class="icon-coapp"></b-icon>{{ startTime }} </span>
                            -
                            <span v-if="startDate != endDate">
                                {{ endDate }}
                                <b-icon icon="dot" class="icon-coapp"></b-icon
                            ></span>
                            <span>{{ endTime }}</span>
                        </div>
                        <div class="mt-2">
                            <span class="grey-text" style="white-space: pre-wrap">{{
                                bookingComponent.Description
                            }}</span>
                        </div>
                        <div v-if="bookedByUser && bookedByUser.Profile" class="mt-3">
                            Booked by
                            <ProfileCircle
                                :ImageURL="bookedByUser.Profile.ImageURL"
                                :Slug="`/admin/community/member/view/${bookedByUser.ID}`"
                                IgnoreSlugPrefix
                                :Name="bookedByUser.Profile.Name"
                                class="d-block mt-2"
                            ></ProfileCircle>
                        </div>
                        <div v-if="bookedForUser && bookedForUser.Profile" class="mt-3">
                            Booked for:
                            <ProfileCircle
                                :ImageURL="bookedForUser.Profile.ImageURL"
                                :Slug="`/admin/community/member/view/${bookedForUser.ID}`"
                                IgnoreSlugPrefix
                                :Name="bookedForUser.Profile.Name"
                                class="d-block mt-2"
                            ></ProfileCircle>
                        </div>
                        <div class="mt-3" v-if="bookingComponent && bookingComponent.Resource">
                            <div class="grey-text">Resource</div>
                            <span>{{ bookingComponent.Resource.Name }}</span>
                        </div>
                        <div class="mt-2" v-if="bookingComponent && bookingComponent.Price">
                            <div class="grey-text">Price</div>
                            <span>{{ bookingComponent.Price.toFixed(2) }} EUR</span>
                        </div>
                        <div class="mt-2" v-if="bookingComponent && bookingComponent.Status">
                            <div class="grey-text">Status</div>
                            <span>{{ bookingComponent.Status }}</span>
                        </div>
                        <div class="mt-4">
                            <b-button class="btn btn-outline-dark" @click="cancelModal(bookingComponent.ID)"
                                >Delete booking
                            </b-button>
                        </div>
                    </b-overlay>
                </template>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import FullCalendar, { CalendarApi } from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import VueMoment from 'vue-moment';
import EventBus from '@/eventBus';

import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';

const moment = require('moment');
Vue.use(require('vue-moment'), {
    moment,
});

let eventGuid = 0;

export function createEventId() {
    return String(eventGuid++);
}

export default {
    components: {
        CoRoundButton,
        FullCalendar, // make the <FullCalendar> tag available
    },
    data() {
        return {
            loading: false,

            viewCalendar: true,
            viewFilter: false,

            sort: false,
            searchText: '',
            searchEnabled: false,
            start: null,

            end: null,

            categories: [],
            selectedCategories: [],
            categoriesSelectorExpanded: true,

            locations: [],
            selectedLocations: [],
            locationsSelectorExpanded: true,

            resourceSelectorExpanded: true,
            selectedResourceSelectorExpanded: true,
            selectedResources: [],
            filteredResources: [],

            windowWidth: window.innerWidth,

            bookingCandidate: {
                showResourceSelector: false,
                start: null,
                end: null,
                resource: null,
            },

            // single booking component modal

            loadingModal: false,
            deleting: false,
            clickInfo: null,
            title: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            weekdayEnd: '',

            bookedByUser: {},
            bookedForUser: {},

            bookingComponent: {},

            selectedDate: null,

            // fullcalendar options
            calendarOptions: {
                firstDay: 1,
                selectable: true,
                businessHours: {
                    startTime: '00:00', // a start time (10am in this example)
                    endTime: '24:00',
                },
                height: '1000px',
                contentHeight: '800px',
                nowIndicator: true,
                expandRows: true,
                longPressDelay: 0,
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin, // needed for dateClick
                ],
                navLinks: false,
                headerToolbar: null,

                initialView: '',
                eventOverlap: false,
                slotLabelFormat: { hour12: false, hour: '2-digit' },
                views: {
                    timeGridOneDay: {
                        type: 'timeGrid',
                        duration: { days: 1 },
                        buttonText: '1 day',
                    },
                    timeGridThreeDays: {
                        type: 'timeGrid',
                        duration: { days: 3 },
                        buttonText: '3 Days',
                    },
                },
                // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                // editable: true,
                // selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select: this.handleDateSelect,
                eventColor: '#5100FF',
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                datesSet: this.handleDatesSet,
                /* you can update a remote database when these fire:
            eventAdd:
            eventChange:
            eventRemove:
            */
            },
            currentEvents: [],
            slotsCancelRequests: [], // axios cancel tokens for resources slots requests
            spanCancelRequest: null, // axios cancel token for span request
        };
    },
    created() {},
    mounted() {
        this.loading = false;
        // next tick
        this.$nextTick(() => {
            this.selectedDate = moment().format('YYYY-MM-DD');
        });

        const that = this;
        window.addEventListener('resize', () => {
            that.windowWidth = window.innerWidth;
        });

        if (this.windowWidth < 400) {
            this.viewCalendar = true;
            this.calendarOptions.initialView = 'timeGridOneDay';
        } else if (this.windowWidth < 992 && this.windowWidth >= 400) {
            this.calendarOptions.initialView = 'timeGridThreeDays';
            this.viewCalendar = true;
        } else {
            this.calendarOptions.initialView = 'timeGridWeek';

            this.viewCalendar = true;
            this.viewFilter = true;
        }

        this.listLocations();
        this.getCategories();

        this.listResources();
    },
    watch: {},
    methods: {
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            const formated = ctx.selectedFormatted;
            // The following will be an empty string until a valid date is entered
            this.selectedDate = ctx.selectedYMD;
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.gotoDate(this.selectedDate);
        },
        setDateToday() {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.gotoDate(moment().format('YYYY-MM-DD'));
        },
        next() {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.next();
        },
        prev() {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.prev();
        },
        changeView(view) {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.changeView(view);
        },
        cancelAllOngoingRequests() {
            // Cancel all existing requests and set their cancel functions to null
            for (let i = 0; i < this.slotsCancelRequests.length; i += 1) {
                if (this.slotsCancelRequests[i]) {
                    this.slotsCancelRequests[i]('Canceled due to new request');
                    this.$set(this.slotsCancelRequests, i, null);
                }
            }
            if (this.spanCancelRequest) {
                this.spanCancelRequest('Canceled due to new request');
                this.spanCancelRequest = null;
            }
        },
        selectResourceToBook(resource) {
            this.bookingCandidate.selectResourceToBook = false;
            this.bookingCandidate.resource = resource;
        },

        search() {
            this.filterResourcesList();
        },
        toggleSelectedResourceSelector() {
            this.selectedResourceSelectorExpanded = !this.selectedResourceSelectorExpanded;
        },
        toggleResource(resource) {
            this.cancelAllOngoingRequests();
            if (this.selectedResources.includes(resource)) {
                this.selectedResources = this.selectedResources.filter((r) => r.ID !== resource.ID);
            } else {
                this.selectedResources.push(resource);
            }

            this.getBookings();
        },
        toggleResourceSelector() {
            this.resourceSelectorExpanded = !this.resourceSelectorExpanded;
        },
        toggleLocationSelector() {
            this.locationsSelectorExpanded = !this.locationsSelectorExpanded;
        },

        toggleLocation(location) {
            if (this.selectedLocations.includes(location)) {
                this.selectedLocations = this.selectedLocations.filter((l) => l.ID !== location.ID);
            } else {
                this.selectedLocations.push(location);
            }
            this.filterResourcesList();
        },

        toggleCategorySelector() {
            this.categoriesSelectorExpanded = !this.categoriesSelectorExpanded;
        },

        toggleCategory(category) {
            if (this.selectedCategories.includes(category)) {
                this.selectedCategories = this.selectedCategories.filter((c) => c.ID !== category.ID);
            } else {
                this.selectedCategories.push(category);
            }

            this.filterResourcesList();
        },

        filterResourcesList() {
            const obj = {};

            if (this.searchText) {
                obj.Search = this.searchText;
            }
            obj.SpaceID = this.$store.state.space.ID;

            obj.Categories = this.selectedCategories.map((c) => c.ID);

            obj.Location = this.selectedLocations.map((l) => l.ID);

            const object = JSON.stringify(obj);

            const selectIDs = this.selectedResources.map((resource) => resource.ID);
            axios({
                method: 'POST',
                url: `/booking/v2/resources/filter`,
                withCredentials: true,
                data: object,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.filteredResources = [];

                        this.filteredResources = response.data.map((item, index) => {
                            if (selectIDs.includes(item.ID)) {
                                item.Selected = true;
                                return item;
                            }
                            item.Selected = false;
                            return item;
                        });
                    } else {
                        this.filteredResources = [];
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        viewFiltersToggle() {
            if (this.viewFilter) {
                this.viewFilter = false;
            } else {
                this.viewFilter = true;
            }
        },

        bookingCreated() {
            const selectModal = this.$refs['resource-select-modal'];
            selectModal.hide();
            this.getBookings();

            this.next();
            this.prev();
            this.bookingCandidate = {
                selectResourceToBook: false,
                resource: null,
            };
        },

        getBookingsForResource(resource, resourceIndex) {
            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            // Store the cancel function for this ID
            this.$set(this.slotsCancelRequests, resourceIndex, source.cancel);

            const calendarApi = this.$refs.calendar.getApi();
            axios({
                method: 'GET',
                url: `/admin/booking/list/${resource.ID}/${this.start}/${this.end}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                cancelToken: source.token,
            })
                .then((response) => {
                    if (response && response.data && response.data.Bookings) {
                        // map bookings to Full Calendar bookings
                        response.data.Bookings.forEach((item, index) => {
                            const event = {
                                id: item.ID,
                                title: item.Title,
                                start: new Date(item.Start * 1000),
                                end: new Date(item.End * 1000),
                                originalObject: item,
                                backgroundColor: resource.Color,
                            };
                            calendarApi.addEvent(event);
                        });

                        this.loading = false;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    if (axios.isCancel(error)) {
                        // console.log(`Request #${resourceIndex} canceled:`, error.message);
                    } else {
                        // console.log(error);
                    }
                })
                .finally(() => {
                    // Set the cancel function to null for the completed request
                    this.$set(this.slotsCancelRequests, resourceIndex, null);
                });
        },
        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data.Resources.length != 0) {
                        this.filteredResources = response.data.Resources;

                        this.filteredResources.sort((a, b) => a.Name.localeCompare(b.Name));
                    } else {
                        this.filteredResources = [];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        listLocations() {
            this.$store
                .dispatch('listLocations')
                .then((locations) => {
                    this.locations = locations.map((item, index) => {
                        item.Selected = false;
                        return item;
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getCategories() {
            axios({
                method: 'GET',
                url: '/admin/booking/category/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Categories) {
                        const categories = response.data.Categories;

                        this.categories = categories.map((item, index) => {
                            item.Selected = false;
                            return item;
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getBookings() {
            // get calendar api
            const calendarApi = this.$refs.calendar.getApi();
            // get current view
            const { view } = calendarApi;
            // get start and end dates
            this.start = view.activeStart.getTime() / 1000;
            this.end = view.activeEnd.getTime() / 1000;

            this.cancelAllOngoingRequests();
            // clear calendar
            calendarApi.removeAllEvents();

            // get bookings for each resource
            this.selectedResources.forEach((resource, index) => {
                this.getBookingsForResource(resource, index);
            });
        },
        handleDatesSet(event) {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.removeAllEvents();
            const error = calendarApi.removeAllEvents();
            if (error) {
                console.log(error);
            }
            const startUTC = new Date(
                event.start.getUTCFullYear(),
                event.start.getUTCMonth(),
                event.start.getUTCDate(),
                event.start.getUTCHours(),
                event.start.getUTCMinutes(),
                event.start.getUTCSeconds()
            );
            const endUTC = new Date(
                event.end.getUTCFullYear(),
                event.end.getUTCMonth(),
                event.end.getUTCDate(),
                event.end.getUTCHours(),
                event.end.getUTCMinutes(),
                event.end.getUTCSeconds()
            );
            const start = Math.floor(startUTC.getTime() / 1000);
            const end = Math.floor(endUTC.getTime() / 1000);
            this.start = start;
            this.end = end;

            if (this.selectedResources.length === 0) {
                this.listBookingsInSpan(start, end);
            } else {
                this.loading = true;
                this.cancelAllOngoingRequests();
                const calendarApi = this.$refs.calendar.getApi();
                calendarApi.removeAllEvents();
                const that = this;
                this.selectedResources.forEach((item, index) => {
                    that.getBookingsForResource(item, index);
                });
            }
        },

        listBookingsInSpan(start, end) {
            this.loading = true;

            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            const error = calendarApi.removeAllEvents();
            if (error) {
                console.log(error);
            }

            if (this.spanCancelRequest) {
                this.spanCancelRequest('Canceled due to new request');
            }

            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            // Store the cancel function for this ID
            this.spanCancelRequest = source.cancel;

            axios({
                method: 'GET',
                url: `/admin/booking/list?start=${start}&end=${end}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                cancelToken: source.token,
            })
                .then((response) => {
                    if (response && response.data && response.data.Bookings) {
                        // map bookings to Full Calendar bookings
                        response.data.Bookings.forEach((item, index) => {
                            const event = {
                                id: item.ID,
                                title: item.Title,
                                start: new Date(item.Start * 1000),
                                end: new Date(item.End * 1000),
                                originalObject: item,
                            };

                            if (item.Resource && item.Resource.Color) {
                                event.backgroundColor = item.Resource.Color;
                                event.borderColor = item.Resource.Color;
                            } else {
                                event.backgroundColor = '#5100FF';
                                event.borderColor = '#5100FF';
                            }

                            calendarApi.addEvent(event);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    if (axios.isCancel(error)) {
                        // console.log('Request canceled', error.message);
                    } else {
                        // console.log(error);
                    }
                })
                .finally(() => {
                    // Set the cancel function to null for the completed request
                    this.spanCancelRequest = null;
                });
        },

        handleWeekendsToggle() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
        },
        hideModals() {
            const selectModal = this.$refs['resource-select-modal'];
            selectModal.hide();
        },
        addBooking() {
            const today = new Date();
            today.setHours(today.getHours() + Math.round(today.getMinutes() / 60));
            today.setMinutes(0, 0, 0);

            const selectModal = this.$refs['resource-select-modal'];
            selectModal.show();

            const todayPlusHour = new Date(JSON.parse(JSON.stringify(today)));

            todayPlusHour.setTime(todayPlusHour.getTime() + 1 * 60 * 60 * 1000);

            this.bookingCandidate.start = today;
            this.bookingCandidate.end = todayPlusHour;
        },
        handleDateSelect(selectInfo) {
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.unselect(); // clear date selection
            const selectModal = this.$refs['resource-select-modal'];
            selectModal.show();
            this.bookingCandidate.start = selectInfo.start;
            this.bookingCandidate.end = selectInfo.end;
            this.bookingCandidate.resource = null;
            this.bookingCandidate.allDay = selectInfo.allDay;
        },

        // handleEventClick(clickInfo) {
        //   if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove();
        //   }
        // },
        handleEvents(events) {
            this.currentEvents = events;
        },
        cancelSelection() {
            this.sort = false;

            this.bookingResources.forEach((item) => {
                item.Selected = false;
            });
        },
        clearAllFilters() {
            if (this.windowWidth < 992) {
                this.viewFilter = false;
            }
            this.loading = true;
            const that = this;
            this.selectedLocations = [];
            this.selectedCategories = [];
            this.selectedResources = [];
            this.locations = [];
            this.categories = [];

            this.listLocations();
            this.getCategories();

            this.listResources();

            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.removeAllEvents();

            this.listBookingsInSpan(this.start, this.end);
        },
        adminGetForUserByID(ID) {
            this.loadingModal = true;
            this.bookedForUser = null;
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.loadingModal = false;
                    this.bookedForUser = response;
                    if (this.bookedForUser.Profile && this.bookedForUser.Profile.Name) {
                        if (
                            this.bookedForUser.Profile.FirstName === '' ||
                            this.bookedForUser.Profile.FirstName == null
                        ) {
                            const names = this.bookedForUser.Profile.Name.split(' ');
                            this.bookedForUser.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.bookedForUser.Profile.LastName = names.join(' ');
                            } else {
                                this.bookedForUser.Profile.LastName = names[0];
                            }
                        }
                    }
                })
                .catch((error) => {
                    // const message = {
                    //     Message: 'Can`t get user data',
                    //     Details: error.response.data,
                    // };
                    // EventBus.$emit('ERROR', message);
                    this.loadingModal = false;
                });
        },
        adminGetByUserByID(ID) {
            this.loadingModal = true;
            this.bookedByUser = null;
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.bookedByUser = response;
                    this.loadingModal = false;
                    if (this.bookedByUser.Profile && this.bookedByUser.Profile.Name) {
                        if (this.bookedByUser.Profile.FirstName === '' || this.bookedByUser.Profile.FirstName == null) {
                            const names = this.bookedByUser.Profile.Name.split(' ');
                            this.bookedByUser.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.bookedByUser.Profile.LastName = names.join(' ');
                            } else {
                                this.bookedByUser.Profile.LastName = names[0];
                            }
                        }
                    }
                })
                .catch((error) => {
                    // const message = {
                    //     Message: 'Can`t get user data',
                    //     Details: error.response.data,
                    // };
                    // EventBus.$emit('ERROR', message);
                    this.loadingModal = false;
                    // this.$router.push(`/admin/community/member/list`);
                });
        },
        cancelModal(ID) {
            this.$bvModal.show(`cancel-modal-${ID}`);
        },
        cancelBooking() {
            this.loading = true;
            this.$bvModal.hide(`modal-booking-${this.bookingComponent.ID}`);
            this.$bvModal.hide(`cancel-modal-${this.bookingComponent.ID}`);

            axios({
                method: 'DELETE',
                url: `/admin/booking/${this.bookingComponent.ID}`,

                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const message = {
                        Message: 'Booking successfully cancelled',
                    };
                    EventBus.$emit('INFO', message);
                    this.loading = false;
                    this.clickInfo.event.remove();
                    this.$bvModal.hide(`modal-booking-${this.bookingComponent.ID}`);
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t cancel booking',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },
        handleEventClick(clickInfo) {
            const that = this;
            setTimeout(() => {
                that.$bvModal.show(`modal-booking-${clickInfo.event.id}`);
            }, 100);

            //  this.$bvModal.show();
            this.clickInfo = clickInfo;

            this.bookingComponent = clickInfo.event.extendedProps.originalObject;

            const start = new Date(clickInfo.event.start);
            const end = new Date(clickInfo.event.end);
            this.startDate = moment(start).format('dddd, MMMM Do');
            this.endDate = moment(end).format('dddd, MMMM Do');
            this.startTime = moment(start).format('HH:mm');
            this.endTime = moment(end).format('HH:mm');

            const { UserID } = this.bookingComponent;
            const { CreatedByUserID } = this.bookingComponent;

            this.adminGetForUserByID(UserID);
            this.adminGetByUserByID(CreatedByUserID);
        },
    },
};
</script>
