/*
vue-i18n (v8.x) guide
https://kazupon.github.io/vue-i18n/guide/formatting.html
*/
import { lang } from 'moment';
import { formattedDate } from './dateTimeFormatter.ts';
import { arch } from 'os';

const deDE = {
    labels: {
        about: 'Über',
        aboutme: 'Über mich',
        acceptprivacy: 'Ich akzeptiere die {0}',
        acceptprivacyandterms: 'Ich akzeptiere die {0} und die {1}',
        acceptterms: 'Ich akzeptiere die {0}',
        account: 'Konto',
        accountsettings: 'Kontoeinstellungen',
        active: 'Aktiv',
        activity: 'Aktivität',
        activemembers: 'Aktive Mitglieder',
        activatepayments: 'Zahlungsmethode aktivieren',
        add: 'Hinzufügen',
        address: 'Adresse',
        addressadditionalinformation: 'Adresszusatz',
        addsection: 'Abschnitt hinzufügen',
        all: 'Alle',
        textSection: 'Textabschnitt',
        imageSection: 'Bildabschnitt',
        amount: 'Betrag',
        and: 'und',
        announcement: 'Ankündigung',
        announcementmake: 'Zur @:labels.announcement machen',
        announcementsentby: '@:labels.announcement gesendet von {name}',
        archived: 'Archiviert',
        archive: 'Archivieren',
        unarchive: 'Archivierung aufheben',
        attend: 'Teilnehmen',
        attendees: 'Teilnehmende',
        author: 'Autor',
        availability: 'Verfügbarkeit',
        awaynotice: 'Abwesenheitsnotiz',
        back: 'Zurück',
        birthdate: 'Geboren am',
        billing: 'Abrechnung',
        billingaddress: 'Rechnungsadresse',
        billingaddressedit: '@:labels.billingaddress @.lower:labels.edit',
        book: 'Buchen',
        booking: 'Buchung',
        bookingcancel: '@:labels.booking stornieren',
        bookingconditions: 'Buchungskonditionen',
        bookingconfirm: 'Buchen',
        bookingconfirmpaid: 'Kostenpflichtig buchen',
        bookings: 'Buchungen',
        bookingsmy: 'Meine Buchungen',
        by: 'Von',
        byme: 'von mir',
        cancel: 'Abbrechen',
        cancellationnotice: 'Kündigungsfrist',
        cancellationnoticemessage: 'Kuenigungs ist bis {days} Tage vor der Veranstaltung möglich.',
        cancellationpolicy: 'Stornierungsbedingungen',
        cancellationpolicyindays: 'Stornierungsbedingungen (in Tagen)',
        changes: 'Änderungen',
        changessaved: '@:messages.changessaved',
        channel: 'Kanal',
        channelname: 'Kanalname',
        channelnew: '@:labels.channel @.lower:labels.create',
        channeladd: '@:labels.channel @.lower:labels.add',
        channels: 'Kanäle',
        chat: 'Chat',
        chatnew: 'Neuer @:labels.chat',
        checkout: 'Zur Kasse',
        category: 'Kategorie',
        city: 'Stadt',
        clear: 'Zurücksetzen',
        clearall: 'Alles zurücksetzen',
        close: 'Schließen',
        closed: 'Geschlossen',
        community: 'Community',
        communityrecentlyseen: 'Zuletzt online',
        company: 'Firma',
        comment: 'Kommentar',
        commentdelete: '@:labels.comment @.lower:labels.delete',
        comments: 'Kommentare',
        complete: 'Erledigen',
        configuration: 'Einstellungen',
        confirm: 'Bestätigen',
        contact: 'Kontakt',
        contributor: 'Mitwirkende:r',
        contributers: 'Mitwirkende',
        copy: 'Kopieren',
        couponcode: 'Rabatt-Code',
        country: 'Land',
        create: 'Erstellen',
        creator: 'Ersteller:in',
        created: 'Erstellt',
        createdat: '@:labels.created um',
        createdon: '@:labels.created am',
        credit: 'Guthaben',
        credits: 'Guthaben',
        day: 'Tag | Tage',
        date: 'Datum',
        dateempty: 'Kein Datum gewählt',
        description: 'Beschreibung',
        delete: 'Löschen',
        discard: 'Abbrechen',
        draft: 'Entwurf',
        dragdropimage: 'Ziehe ein Bild hierher oder klicke zum Auswählen.',
        edit: 'Bearbeiten',
        email: 'E-Mail',
        enabled: 'aktiviert',
        enddate: 'Enddatum',
        endtime: 'Endzeit',
        ended: 'Beendet',
        eventtime: 'Zeit',
        event: 'Veranstaltung',
        eventcreate: '@:labels.event @.lower:labels.create',
        eventcreated: '@:labels.event @.lower:labels.created',
        eventdelete: '@:labels.event @.lower:labels.delete',
        eventedit: '@:labels.event @.lower:labels.edit',
        events: 'Veranstaltungen',
        eventsnext: 'Kommende Veranstaltungen',
        eventOngoing: 'Findet statt',
        eventsupcoming: 'Als nächstes',
        eventyour: 'deine @:labels.event',
        failedGeneric: '@:errors.failedGeneric', // 'An error occurred. Please try again.',
        failedToSaveChanges: '@:errors.failedToSaveChanges',
        finish: 'Abschließen',
        follow: 'Folgen',
        followerrole: 'Follower:in',
        follower: 'keine Follower | ein Follower | {n} Follower',
        followers: 'Follower',
        following: 'Du folgst',
        free: 'kostenlos',
        from: 'Von',
        general: 'Allgemein',
        getstarted: 'Hier starten',
        getticket: 'Tickets kaufen',
        going: 'Du gehst hin',
        goingcount: 'geht hin | gehen hin',
        group: 'Gruppe',
        groupmember: 'Gruppenmitglied | Gruppenmitglieder',
        groupnew: 'Neue @:labels.group',
        hashtag: 'Hashtag',
        hashtagadd: '@:labels.hashtag @.lower:labels.add',
        headline: 'Überschrift',
        hour: 'Stunde | Stunden',
        image: 'Bild',
        imageadd: '@:labels.image @.lower:labels.add',
        imprint: 'Impressum',
        including: 'inkl.',
        interested: 'Interresse bekunden',
        interestedpeople: 'Interressenten',
        interestedsent: 'Anfrage gesendet',
        invalid: 'ungültig',
        invitemember: 'Mitglied einladen',
        invoice: 'Rechnung',
        invoices: 'Rechnungen',
        invoicepreview: 'Rechnungsvorschau',
        itemscount: 'keine Einträge | 1 Eintrag | {n} Einträge',
        join: 'Beitreten',
        language: 'Sprache',
        letstalkabout: 'Ich unterhalte mich gerne über',
        link: 'Link',
        linkadd: '@:labels.link @.lower:labels.add',
        linkcopy: '@:labels.link @.lower:labels.copy',
        loading: 'laden',
        location: 'Ort',
        login: 'Anmelden',
        logout: 'Ausloggen',
        markallasread: 'Alle als gelesen markieren',
        market: 'Markt',
        marketitem: 'Marktartikel',
        marketitems: '@:labels.marketitem',
        marketitemyour: 'deinen @:labels.marketitem',
        marketitemclose: '@:labels.marketitem @.lower:labels.close',
        marketitemcreate: '@:labels.marketitem @.lower:labels.create',
        marketitemdelete: '@:labels.marketitem @.lower:labels.delete',
        marketitemedit: '@:labels.marketitem @.lower:labels.edit',
        member: 'Mitglied | Mitglieder',
        members: 'Mitglieder',
        membership: 'Mitgliedschaft',
        membershipyour: 'Deine Mitgliedschaft',
        message: 'Nachricht',
        messagenew: 'neue @:labels.message',
        messenger: 'Nachrichten',
        month: 'Monat',
        morecount: '{count} weitere',
        mentionsmo: 'Person erwähnen',
        my: 'Meine',
        mybookings: '@:labels.my @:labels.bookings',
        myevents: '@:labels.my @:labels.events',
        mypages: '@:labels.my @:labels.pages',
        myticket: 'Mein Ticket',
        name: 'Name',
        namefirst: 'Vorname',
        namelast: 'Nachname',
        numberoftickets: 'Anzahl der Tickets',
        next: 'Weiter',
        net: 'Netto',
        no: 'Nein',
        notificationpreferences: 'Benachrichtigungen konfigurieren',
        notifications: 'Benachrichtigungen',
        notificationspause: 'Benachrichtigungen pausieren',
        notificationspaused: 'Benachrichtigungen pausiert',
        notifymewhen: 'Benachrichtige mich, wenn',
        notifymewhenasadmin: 'Als Administator',
        notprovided: 'nicht angegeben',
        others: 'niemand | eine weitere | {n} weitere',
        ordersummary: 'Bestellübersicht',
        page: 'Seite',
        pagecreate: '@:labels.page @.lower:labels.create',
        pagecreated: '@:labels.page @.lower:labels.created',
        pagedelete: '@:labels.page @.lower:labels.delete',
        pageedit: '@:labels.page @.lower:labels.edit',
        pagepublish: '@:labels.page @.lower:labels.publish ',
        pagepublished: '@:labels.page @.lower:labels.published ',
        pages: 'Seiten',
        pagesmy: '@:labels.mypages',
        pageyour: 'deine @:labels.page',
        paid: 'bezahlt',
        password: 'Passwort',
        passwordreset: 'Passwort zurücksetzen',
        passwordsave: 'Passwort speichern',
        passwordsetnew: 'Neues Passwort festlegen',
        pause: 'Pausieren',
        payment: 'Zahlung',
        paymentmethod: 'Zahlungsmethode',
        paymentmethodadd: '@:labels.paymentmethod @.lower:labels.add',
        paymentmethods: 'Zahlungsmethoden',
        phoneNumber: 'Telefonnummer',
        phonenumber: '@:labels.phoneNumber',
        phonenumbernotprovided: '@:labels.phonenumber @:labels.notprovided',
        pay: 'Bezahlen',
        plan: 'Tarif',
        plancancel: 'Tarif kündigen',
        planchange: 'Tarif ändern',
        planchangecancel: 'Tarifwechsel stornieren',
        planchoose: 'Tarif wählen',
        plancurrent: 'Dein @:labels.plan',
        planreactivate: 'Kündigung rückgängig machen',
        planselection: 'Wähle deinen Tarif',
        planupcoming: 'Dein neuer Tarif',
        planupgrades: 'Tarif-Upgrades',
        planupgradechose: 'Wähle deine Upgrades',
        pushnotification: 'Push-Benachrichtigung',
        push: 'Push',
        post: 'Beitrag',
        postpublished: '@:labels.post @.lower:labels.published',
        postalcode: 'Postleitzahl',
        postyour: 'deinen @:labels.post',
        postbtn: 'Posten',
        postedit: '@:labels.post @.lower:labels.edit',
        preview: 'Vorschau',
        previous: 'Zurück',
        profile: 'Profil',
        profileedit: '@:labels.profile @.lower:labels.edit',
        profilepublish: '@:labels.profile @.lower:labels.publish',
        profilepublished: '@:labels.profile @.lower:labels.published',
        profileupdated: '@:labels.profile @.lower:labels.updated',
        profilename: 'Profilname',
        price: 'Preis',
        pricesubtotal: 'Zw.Summe',
        pricetotal: 'Gesamt',
        pricevaries: 'Preise variieren',
        privacypolicy: 'Datenschutzerklärung',
        publish: 'Veröffentlichen',
        published: 'Veröffentlicht',
        readmore: 'mehr anzeigen',
        of: 'von',
        or: 'oder',
        orderconfirm: 'Bestellung abschließen',
        ordercompletesubcription: 'kostenpflichtig abschließen',
        ordercompletesubcriptionfree: 'kostenlos abschließen',
        orderreview: 'Bestellung überprüfen',
        ordercheckout: 'Zur Kasse',
        organizer: 'Organisator:in',
        organizers: 'Organisierende',
        organizersadd: '@:labels.organizers @.lower:labels.add',
        owner: 'Inhaber:in',
        owners: 'Inhaber',
        help: 'Hilfe',
        quantity: 'Anzahl',
        readless: 'Weniger lesen',
        readmore: 'Mehr lesen',
        redeem: 'Einlösen',
        reportbug: 'Fehler melden',
        refresh: 'neu laden',
        registrationconfirmation: 'Registrierungsbestätigung',
        remove: 'Entfernen',
        remaining: 'übrig',
        reopen: 'Wieder öffnen',
        reopenItem: 'Anzeige öffnen',
        reply: 'Antworten',
        reportbug: 'Fehler melden',
        reset: 'Zurücksetzen',
        resolve: 'Lösen',
        resource: 'Ressource',
        resources: 'Ressourcen',
        review: 'Zusammenfassung',
        required: 'Pflichtfeld',
        salutation: 'Anrede',
        salutationfemale: 'Frau',
        salutationmale: 'Herr',
        salutationnonbinary: 'Divers',
        save: 'Speichern',
        saved: 'Gespeichert',
        seeall: 'Alle ansehen',
        send: 'Senden',
        share: 'Teilen',
        signup: 'Registrieren',
        skip: 'Überspringen',
        start: 'Start',
        status: 'Status',
        startdate: 'Startdatum',
        starttime: 'Startzeit',
        submit: 'Senden',
        subscriptioncreate: 'Mitgliedschaft abschließen',
        suggestedcontent: 'Vorgeschlagene Inhalte',
        summary: 'Zusammenfassung',
        sold: 'Verkauft',
        soldout: 'Ausverkauft',
        tax: 'Steuern',
        terms: 'Bedingungen',
        termsandconditions: 'Nutzungsbedingungen',
        termsofservice: '@:labels.termsandconditions',
        ticket: 'Ticket',
        tickets: 'Tickets',
        ticketurl: 'Ticket-Link',
        ticketcreate: 'Ticket erstellen',
        ticketedit: 'Ticket bearbeiten',
        ticketcode: 'Ticketcode',
        time: 'Zeit',
        timepass: 'Zeitpass',
        timepasses: 'Zeitpässe',
        timezone: 'Zeitzone',
        title: 'Titel',
        to: 'Bis',
        today: 'Heute',
        topics: 'Themen',
        total: 'Summe',
        tryagain: 'Erneut probieren',
        type: 'Typ',
        unfollow: 'Entfolgen',
        unsavedchanges: 'Ungespeicherte Änderungen',
        updated: 'Aktualisiert',
        updatedat: '@:labels.updated um',
        updatedon: '@:labels.updated am',
        update: 'Update',
        updatecreate: '@:labels.update @.lower:labels.create',
        updatedelete: '@:labels.update @.lower:labels.delete',
        updateedit: '@:labels.update @.lower:labels.edit',
        updates: 'Updates',
        vat: 'MwSt.',
        vatid: 'USt-IdNr.',
        vatidnotprovided: 'keine USt-IdNr. angegeben',
        view: 'Ansehen',
        vieweventdetails: 'Veranstaltungsdetails ansehen',
        week: 'Woche',
        yes: 'Ja',
        year: 'Jahr',
    },
    placeholders: {
        selectTimezone: 'Wähle deine Zeitzone',
        communityname: 'Gib deiner Community einen Namen',
        address: 'Straße und Hausnummer',
        comment: 'Hinterlasse einen Kommentar',
        couponcode: 'Rabatt-Code eingeben',
        date: 'Wähle ein Datum',
        email: 'mail@beispiel.de',
        file: 'Keine Datei gewählt',
        image: 'Klicke um das Bild zu ändern',
        marketitemcontact: 'Link zu einer Kontaktseite oder eine E-Mail',
        marketitemdescription:
            'Die Beschreibung deines Artikels wird auf der Detailseite deines Artikels angezeigt und sollte ihn ausführlich beschreiben.',
        marketitemtitle: 'Wähle einen guten namen :)',
        marketitemsummary: 'Die Zusammenfassung deines Artikels wird im Feed und in der Marktliste angezeigt.',
        messagecreate: 'Sende eine Nachricht',
        optional: 'optional',
        password: 'gib dein @:labels.password ein',
        post: '@:labels.post @.lower:labels.create',
        search: 'Suche',
        searchmembers: 'Nach Personen suchen',
        select: '@:placeholders.selectplease',
        selectplease: 'Bitte wähle eine Option',
        selecttaxrate: 'Wähle den Steuersatz',
        tags: 'Tippe hier',
        updatecreate: '@:labels.update @.lower:labels.create',
        ticketurlplaceholder: 'Link zu den Tickets',
        ticketname: 'e.g. Early Bird',
        vatid: 'DE123456789',
    },
    messages: {
        accountexists: 'Hast du schon einen Account?',
        cookiebanner:
            'Diese Website verwendet Cookies 🍪, um Informationen auf deinem Computer zu speichern. Einige sind unerlässlich, damit diese Website funktioniert; andere helfen uns, die Benutzerfreundlichkeit zu verbessern. Durch die Nutzung dieser Website erklärst du dich mit der Platzierung dieser Cookies einverstanden. Mehr dazu erfährst du in unserer {privacypolicy}.',
        copiedtoclipboard: 'In die Zwischenablage kopiert',
        deleteImageHeaderWarning: 'Möchtest du das Titelbild wirklich löschen?',
        deletewarning: 'Das Löschen kann nicht rückgängig gemacht werden. Bitte sei also sicher.',
        event: {
            attendanceCancellation:
                'Bist du dir sicher, dass du das willst? Diese Veranstaltung wäre viel schöner mit dir!',
        },
        imageguidelines:
            'Bitte vermeide Text im Bild. Das empfohlene Seitenverhältnis ist 3:2 und die empfohlene Bildgröße 1800x1200 Pixel.',
        marketiterestsubmit: 'Wir werden den Autor über dein Interesse informieren.',
        maxcharacters: 'max. {count} Zeichen',
        searchnomatches: 'Es gibt Keine Treffer für deine Suchanfrage.',
        changessaved: '@:labels.changes @.lower:labels.saved',
        unsavedwarning: 'Deine Änderungen gehen verloren. Bist du sicher, dass du forfahren möchtest?',
        usernotexists: 'User existiert nicht oder wurde gelöscht.',
        userstatus: {
            3: 'Dein Konto ist derzeit gesperrt. Dies ist wahrscheinlich auf ein Zahlungsproblem zurückzuführen. Bitte kontaktiere den Kundenservice, um die Situation zu klären.',
            4: 'Dein Konto ist derzeit gekündigt. Bitte abonniere einen Tarif, um deine Mitgliedschaft zu reaktivieren.',
            5: 'Dein Konto wartet derzeit auf die Freigabe durch einen Admin. Sobald die Freigabe für dein Konto erteilt wurde, erhältst du eine E-Mail. Bitte stelle sicher, dass du deine E-Mail-Adresse bestätigt hast. Hierzu hast du bereits eine separate E-Mail erhalten.',
        },
        imageUploadError: 'Fehler beim Hochladen des Bildes',
        imageUploadSizeError: 'Das Bild ist zu groß. Bitte wähle ein Bild mit einer maximalen Größe von 10 MB.',
        copyright: '© 2022 coapp GmbH. Alle Rechte vorbehalten.',
        passwordlength: 'Passwort darf nicht länger als 56 Zeichen sein',
        toUseThisFeatureActivatePayments: 'Um diese Funktion zu nutzen, aktiviere bitte die Zahlungsmethode.',
        registrationconfirmationmessage: 'Du findest dein Ticket jederzeit auf der Veranstaltungsseite.',
    },
    errors: {
        invalid: '@:labels.invalid',
        inputinvalid: 'Ungültige Eingabe',
        inputrequired: 'Pflichtfeld',
        failedGeneric: 'Es ist ein Fehler aufgetreten.',
        failedToSaveChanges: 'Änderungen konnten nicht gespeichert werden.',
        invalidLoginCredentials: 'Ungültige Anmeldedaten',
        mustBeValidEmail: 'Muss eine gültige E-Mail-Adresse sein',
    },
    meta_title: {
        createYourCommunity: 'Erstelle deine Community',
    },
    languages: {
        de: 'Deutsch',
        en: 'Englisch',
    },
    datetime: ({ named }) =>
        formattedDate(named('date'), named('format'), named('locale') ? named('locale') : 'de', named('customFormat')),
};
export default deDE;
