





































































































































































import axios from 'axios';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import {
    parse as parsedatefns,
    format,
    addDays,
    setHours,
    setMinutes,
    setSeconds,
    isAfter,
    differenceInHours,
    differenceInMinutes,
    addMinutes,
} from 'date-fns';

// import {  } from 'date-fns';
import { clone, get } from 'lodash';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import EventBus from '../../../eventBus';
import CoBookingCheckoutForUser from '../co-booking-checkout-for-user/CoBookingCheckoutForUser.vue';

import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoButtonGroup from '../../Molecules/co-button-group/CoButtonGroup.vue';

interface Slot {
    start: Date;
    end: Date;
    resource: any;
    free: boolean;
}

export default {
    name: 'CoBookingCalendarEnduser',
    components: {
        FullCalendar,
        CoBookingCheckoutForUser,
        CoHeadline,
        CoButton,
        CoButtonGroup,
    },
    props: {
        // resources to be displayed in the calendar
        resources: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            // fullcalendar options
            calendarOptions: {
                firstDay: 1,
                selectable: true,
                editable: false,
                businessHours: {
                    daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday - Friday
                    startTime: '00:00', // a start time (10am in this example)
                    endTime: '24:00',
                },
                height: '72vh',
                nowIndicator: true,
                expandRows: true,
                longPressDelay: 0,
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin, // needed for dateClick
                ],
                navLinks: false,
                headerToolbar: null,
                initialView: '',
                eventOverlap: false,
                slotLabelFormat: { hour12: false, hour: '2-digit' },
                dayHeaderFormat: {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'numeric',
                },
                views: {
                    timeGridOneDay: {
                        type: 'timeGrid',
                        duration: { days: 1 },
                        dayHeaderContent: (args) =>
                            this.$t('datetime', {
                                date: args.date,
                                customFormat: 'EEEE P',
                            }),
                    },
                    timeGridThreeDays: {
                        type: 'timeGrid',
                        duration: { days: 3 },
                        dayHeaderContent: (args) =>
                            this.$t('datetime', {
                                date: args.date,
                                customFormat: 'E P',
                            }),
                    },
                    timeGridWeek: {
                        type: 'timeGrid',
                        duration: { days: 7 },
                        dayHeaderContent: (args) =>
                            this.$t('datetime', {
                                date: args.date,
                                customFormat: 'E d',
                            }),
                        firstDay: 1,
                    },
                },
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,

                eventColor: '#5100FF',

                select: this.selectHandler,
                selectAllow: this.selectAllow,
                // eventClick: this.handleEventClick,
                selectOverlap: this.selectOverlapCheck,
                datesSet: this.handleDatesSet,

                slotDuration: '00:30:00',

                allDaySlot: false,
            },
            selectedDate: new Date(),
            bookingCandidate: {
                start: null,
                end: null,
                resource: null,
                startTimeSlot: null as Slot | null,
                endTimeSlot: null as Slot | null,
                display: false,
            },

            freeBusySlots: [] as Slot[],
            windowWidth: 0,
            mobileView: false,
            slotsCancelRequests: [], // axios cancel tokens

            calendarView: '',
            currentViewTitleKey: 0,
        };
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        window.addEventListener('resize', () => {
            that.windowWidth = window.innerWidth;
        });
        that.windowSizeHander();
        // set selectedDate to today
        this.selectedDate = new Date();
    },
    watch: {
        resources: {
            handler() {
                this.getFreeBusySlotsForResources();
                // this.calendarOptions.slotDuration = this.interval();
            },
            deep: true,
        },
    },

    methods: {
        // returns the title of the current view in forma Oct 31 – Nov 6, 2022
        getCurrentViewTitle() {
            // get calendar instance
            const { calendar } = this.$refs;
            if (!calendar) {
                return '';
            }
            const calendarApi = calendar.getApi();
            if (!calendarApi) {
                return '';
            }
            const { view } = calendarApi;
            // get start and end date of current view
            const start = view.activeStart;
            const end = view.activeEnd;

            if (start.getMonth() === end.getMonth()) {
                return this.$t('datetime', { date: start, customFormat: 'MMMM yyyy' });
            }
            return `${this.$t('datetime', { date: start, customFormat: 'MMM' })} - ${this.$t('datetime', {
                date: end,
                customFormat: 'MMM yyyy',
            })}`;
        },

        dayHeaderContent(args) {
            return this.$t('datetime', { date: args.date, customFormat: 'EEEEEE p asd' });
        },
        back() {
            this.$emit('back');
        },
        interval() {
            if (this.resources.length > 0) {
                const r = this.resources[0];
                if (r.MinBookingDuration) {
                    // r.MinBookingDuration converts to hours and then to minutes
                    const minBookingDurationHours = Math.floor(r.MinBookingDuration / 60);
                    const minBookingDurationMinutes = r.MinBookingDuration % 60;
                    let result = ':00';
                    // add leading zero if minutes are less than 10
                    if (minBookingDurationMinutes < 10) {
                        result = `:0${minBookingDurationMinutes}${result}`;
                    } else {
                        result = `:${minBookingDurationMinutes}${result}`;
                    }
                    // add leading zero if hours are less than 10
                    if (minBookingDurationHours < 10) {
                        result = `0${minBookingDurationHours}${result}`;
                    } else {
                        result = `${minBookingDurationHours}${result}`;
                    }
                    return result;
                }
            }

            return '00:30:00';
        },

        bookingCreated(payload) {
            this.bookingCandidate.display = false;
            this.getFreeBusySlotsForResources();
        },
        selectAllow(selectInfo) {
            const { start, end, resource } = selectInfo;
            const now = new Date();
            if (isAfter(now, start)) {
                EventBus.$emit('ERROR', {
                    Message: 'You cannot book in the past',
                });
                return false;
            }
            return true;
        },

        selectHandler(event) {
            let { start, end } = event;

            // find time slot in freeBusySlots array that matches the selected time slot
            const startTimeSlot = this.freeBusySlots.find(
                (slot) => slot.start.getTime() <= start.getTime() && slot.end.getTime() >= start.getTime() && slot.free
            );

            const endTimeSlot = this.freeBusySlots.find(
                (slot) => slot.start.getTime() <= end.getTime() && slot.end.getTime() >= end.getTime() && slot.free
            );

            const resource = this.resources[0];
            if (resource.BookWholeSlot) {
                // if resource.BookWholeSlot is true, then the whole time slot must be booked
                const selectedSlot = this.freeBusySlots.find(
                    (slot) =>
                        slot.start.getTime() <= start.getTime() && slot.end.getTime() >= end.getTime() && slot.free
                );
                start = selectedSlot.start;
                end = selectedSlot.end;
            }

            if (resource.MinBookingDuration) {
                const minBookingDuration = resource.MinBookingDuration;

                const duration = differenceInMinutes(end, start);
                // add minimum booking duration to start time
                const minBookingEnd = addMinutes(start, minBookingDuration);

                if (duration < minBookingDuration) {
                    end = minBookingEnd;
                    //     EventBus.$emit('ERROR', {
                    //         Message: `This resource requires a minimum booking duration of ${minBookingDuration} minutes`,
                    //     });
                    //     return;
                }
            }

            if (startTimeSlot && endTimeSlot) {
                this.bookingCandidate = {
                    start,
                    end,
                    resource,
                    startTimeSlot,
                    endTimeSlot,
                    display: true,
                };
            } else {
                EventBus.$emit('ERROR', {
                    Message: 'You cannot book outside of the available time slots',
                });
            }
        },

        windowSizeHander() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 992) {
                // set calendar view to day view
                this.calendarOptions.initialView = 'timeGridThreeDays';
                this.calendarView = 'timeGridThreeDays';

                const { calendar } = this.$refs;
                if (calendar) {
                    calendar.getApi().changeView('timeGridThreeDays');
                    this.calendarView = 'timeGridThreeDays';
                }
                this.mobileView = true;
                this.calendarOptions.height = 'auto';
            } else {
                this.calendarOptions.initialView = 'timeGridWeek';
                this.calendarView = 'timeGridWeek';
                const { calendar } = this.$refs;
                if (calendar) {
                    calendar.getApi().changeView('timeGridWeek');
                    this.calendarView = 'timeGridWeek';
                }

                this.calendarOptions.height = '72vh';
                this.mobileView = false;
            }
        },

        selectResourceToBook(resource) {
            this.bookingCandidate.selectResourceToBook = false;
            this.bookingCandidate.resource = resource;
        },
        // calendar control
        onContext(ctx) {
            this.cancelAllOngoingRequests();
            if (ctx.selectedDate === null) return;
            this.selectedDate = ctx.selectedDate;
            const { calendar } = this.$refs;
            if (!calendar) return;
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.gotoDate(this.selectedDate);
            this.getFreeBusySlotsForResources();
        },
        setDateToday() {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.gotoDate(new Date());
        },
        next() {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.next();
        },
        prev() {
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.prev();
        },
        changeView(view) {
            this.calendarView = view;
            this.cancelAllOngoingRequests();
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.changeView(view);
        },

        cancelAllOngoingRequests() {
            // Cancel all existing requests and set their cancel functions to null
            for (let i = 0; i < this.slotsCancelRequests.length; i += 1) {
                if (this.slotsCancelRequests[i]) {
                    this.slotsCancelRequests[i]('Canceled due to new request');
                    this.$set(this.slotsCancelRequests, i, null);
                }
            }
        },

        /**
         * Called when the user selects a date range in the calendar view by clicking
         * previous/next buttons, today or a view change (day/week/3days)
         */
        handleDatesSet() {
            this.currentViewTitleKey += 1;
            this.getFreeBusySlotsForResources();
        },

        getVisibleDateRange() {
            const { calendar } = this.$refs;
            if (!calendar) {
                // return default date range
                const today = new Date();
                const start = Math.floor(today.getTime() / 1000);
                const end = start + 3600 * 24 * 7;
                return { start, end };
            }

            const calendarApi = this.$refs.calendar.getApi();
            const { view } = calendarApi;

            const startUTC = view.currentStart;
            const endUTC = view.currentEnd;

            const start = Math.floor(startUTC.getTime() / 1000);
            const end = Math.floor(endUTC.getTime() / 1000);
            return { start, end };
        },

        getFreeBusySlotsForResources() {
            const { start, end } = this.getVisibleDateRange();
            // get list dates in the visible range
            const dates = this.eachDayOfInterval(new Date(start * 1000), new Date(end * 1000));

            this.freeBusySlots = [];
            this.cleanCalendar();
            this.resources.forEach((resource) => {
                dates.forEach((date, index) => {
                    // format date to YYYY-MM-DD
                    this.getSlots(date, resource, index);
                });
            });
            this.greyoutEventForPast(new Date(start * 1000), new Date(end * 1000));
        },

        convertFreeSlotToBusySlots(slots: Slot[], date: Date, resource: any): Slot[] {
            const busySlots: Slot[] = [];
            let busyStart = null;
            let busyEnd = null;

            if (slots.length === 0) {
                // no free slot means the whole day is busy
                busyStart = setHours(date, 0);
                busyStart = setMinutes(busyStart, 0);
                busyStart = setSeconds(busyStart, 0);

                busyEnd = addDays(busyStart, 1);
                busyEnd = setHours(busyEnd, 0);
                busyEnd = setMinutes(busyEnd, 0);
                busyEnd = setSeconds(busyEnd, 0);

                const busySlot: Slot = {
                    start: busyStart,
                    end: busyEnd,
                    free: false,
                    resource,
                };
                busySlots.push(busySlot);

                return busySlots;
            }

            // if there is only one free slot and it is the whole day, then there is no busy slot
            if (slots.length === 1) {
                const { start } = slots[0];
                const { end } = slots[0];

                const diffHours = differenceInHours(end, start);
                if (diffHours === 24) {
                    // whole day free slot
                    return busySlots;
                }

                if (
                    start.getHours() === 0 &&
                    start.getMinutes() === 0 &&
                    (end.getHours() !== 0 || end.getMinutes() !== 0)
                ) {
                    // free slot from day start to some time
                    busyEnd = addDays(end, 1);
                    busyEnd = setHours(busyEnd, 0);
                    busyEnd = setMinutes(busyEnd, 0);
                    busyEnd = setSeconds(busyEnd, 0);
                    const busySlot: Slot = {
                        start: end,
                        end: busyEnd,
                        resource: slots[0].resource,
                        free: false,
                    };
                    busySlots.push(busySlot);
                }

                if (
                    (start.getHours() !== 0 || start.getMinutes() !== 0) &&
                    end.getHours() === 0 &&
                    end.getMinutes() === 0
                ) {
                    busyStart = start;
                    busyStart = setHours(busyStart, 0);
                    busyStart = setMinutes(busyStart, 0);
                    busyStart = setSeconds(busyStart, 0);
                    // free slot from some time to day end
                    const busySlot: Slot = {
                        start: busyStart,
                        end: start,
                        resource: slots[0].resource,
                        free: false,
                    };
                    busySlots.push(busySlot);
                }

                if (busySlots.length > 0) return busySlots;
            }
            slots.forEach((slot, index) => {
                // this adds's a busy slot before free current slot if busy slot is not null
                if (busyStart !== null) {
                    // if last slot from day start to slot start
                    busyEnd = slot.start;
                    const busySlot: Slot = {
                        start: busyStart,
                        end: busyEnd,
                        resource: slot.resource,
                        free: false,
                    };
                    busySlots.push(busySlot);
                    busyStart = slot.end;
                    busyEnd = null;
                }

                if (index === 0) {
                    // first slot from day start to slot start
                    busyStart = slot.start;
                    // day start for busyStart
                    busyStart = setHours(busyStart, 0);
                    busyStart = setMinutes(busyStart, 0);
                    busyStart = setSeconds(busyStart, 0);

                    busyEnd = slot.start;
                    const res = clone(slot.resource);
                    // res.Color = '#ddd';
                    if (busyStart.getTime() !== busyEnd.getTime()) {
                        const busySlot: Slot = {
                            start: busyStart,
                            end: busyEnd,
                            resource: res,
                            free: false,
                        };
                        busySlots.push(busySlot);
                    }

                    busyStart = slot.end;
                }

                if (index === slots.length - 1) {
                    // if last slot from slot end to day end means there is no any busy slot for this day
                    busyStart = slot.end;
                    // this checks if last free slot is till day end or not
                    // if not, then we need to add a busy slot from last free slot end to day end
                    if (busyStart.getHours() !== 0 || busyStart.getMinutes() !== 0) {
                        busyEnd = slot.end;
                        busyEnd = addDays(busyEnd, 1);

                        busyEnd = setHours(busyEnd, 0);
                        busyEnd = setMinutes(busyEnd, 0);
                        busyEnd = setSeconds(busyEnd, 0);
                        const res = clone(slot.resource);
                        const busySlot: Slot = {
                            start: busyStart,
                            end: busyEnd,
                            resource: res,
                            free: false,
                        };

                        if (busyStart.getTime() !== busyEnd.getTime()) {
                            busySlots.push(busySlot);
                        }
                        busyStart = null;
                        busyEnd = null;
                    }
                }

                if (busyStart === null) {
                    // if slot start is not first slot
                    busyStart = slot.end;
                }
            });

            return busySlots;
        },

        cleanCalendar() {
            this.cancelAllOngoingRequests();
            const { calendar } = this.$refs;
            if (!calendar) return;
            const calendarApi = this.$refs.calendar.getApi();
            calendarApi.removeAllEvents();
        },
        eachDayOfInterval(start, end) {
            const dates = [];
            let currentDate = start;
            while (currentDate <= end) {
                dates.push(currentDate);
                currentDate = addDays(currentDate, 1);
            }
            return dates;
        },
        getSlots(date, resource, index) {
            // Create a new CancelToken
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();

            // Store the cancel function for this ID
            this.$set(this.slotsCancelRequests, index, source.cancel);

            const dateStr = format(date, 'yyyy-MM-dd');

            axios({
                method: 'GET',
                url: `/booking/v2/resources/free-slots/${resource.Id}/${dateStr}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                cancelToken: source.token,
            })
                .then((response) => {
                    const slots = get(response, 'data.FreeSlots', []);
                    const freeSlots = [];
                    slots.forEach((entry) => {
                        const startstr = entry.StartTime.replace('UTC', '');
                        const endstr = entry.EndTime.replace('UTC', '');
                        const startdate = parsedatefns(startstr, 'yyyy-MM-dd HH:mm:ss XX', new Date());
                        const enddate = parsedatefns(endstr, 'yyyy-MM-dd HH:mm:ss XX', new Date());
                        const freeBusySlot: Slot = {
                            start: startdate,
                            end: enddate,
                            free: true,
                            resource,
                        };

                        freeSlots.push(freeBusySlot);
                    });

                    const busySlots = this.convertFreeSlotToBusySlots(freeSlots, date, resource);
                    let tmp = busySlots;
                    tmp = tmp.concat(freeSlots);

                    // sort slots by start date
                    tmp.sort((a, b) => a.start.getTime() - b.start.getTime());
                    tmp.forEach((slot) => {
                        this.addBackgroundEvent(slot);
                        // if free and less than min booking duration, then grey out
                        if (slot.free && resource.MinBookingDuration) {
                            const minBookingDuration = resource.MinBookingDuration;
                            const duration = differenceInMinutes(slot.end, slot.start);
                            if (duration < minBookingDuration) {
                                this.greyoutEventForBlockedSlot(slot.start, slot.end);
                            }
                        }
                    });

                    this.freeBusySlots = this.freeBusySlots.concat(tmp);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        // console.log(`Request #${index} canceled:`, error.message);
                    } else {
                        // console.log(error);
                    }
                })
                .finally(() => {
                    // Set the cancel function to null for the completed request
                    this.$set(this.slotsCancelRequests, index, null);
                });
        },
        // getFreeBusySlots(resource, start, end) {
        //     axios({
        //         method: 'GET',
        //         url: `/booking/free-busy`,
        //         withCredentials: true,
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         params: {
        //             start,
        //             end,
        //             resourceid: resource.Id,
        //         },
        //     })
        //         .then((response) => {
        //             if (response && response.data && response.data.Slots && response.data.Slots.length > 0) {
        //                 const slots = response.data.Slots;
        //                 slots.forEach((item) => {
        //                     // create vareable that implements the Slot interface
        //                     // parse string to int
        //                     const startTime = parseInt(item.StartTime, 10);
        //                     const endTime = parseInt(item.EndTime, 10);
        //                     const freeBusySlot: Slot = {
        //                         start: new Date(startTime * 1000),
        //                         end: new Date(endTime * 1000),
        //                         free: item.Free,
        //                         resource,
        //                     };
        //                     if (!freeBusySlot.free) {
        //                         this.freeBusySlots.push(freeBusySlot);
        //                         this.addBackgroundEvent(freeBusySlot);
        //                     }
        //                 });
        //             }
        //         })
        //         .catch((error) => {
        //             console.error(error);
        //         });
        // },
        selectOverlapCheck(event) {
            return this.resources.length > 1;
        },

        handleEventClick(clickInfo) {
            if (clickInfo.event.source.id !== 'user') return;

            this.bookingComponent = clickInfo.event.extendedProps.originalObject;

            this.bookingComponentStartDate = clickInfo.event.start;
            this.bookingComponentEndDate = clickInfo.event.end;

            setTimeout(() => {
                this.$bvModal.show(`modal-booking-${clickInfo.event.id}`);
            }, 100);
        },

        greyoutEventForPast(start, end: Date) {
            const now = new Date();
            if (start > now) {
                // for future calendar nothing to grey out
                return;
            }
            let endOfPast = end;
            if (end > now) {
                endOfPast = now;
            }

            const { calendar } = this.$refs;
            if (!calendar) return;
            const calendarApi = this.$refs.calendar.getApi();
            // generate a random id
            const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            const event = {
                title: 'past',
                id,
                start,
                end: endOfPast,
                // display: slot.free ? 'inverse-background' : 'background',
                display: 'background',
                color: '#ddd',
            };
            calendarApi.addEvent(event);
        },
        greyoutEventForBlockedSlot(start, end: Date) {
            const { calendar } = this.$refs;
            if (!calendar) return;
            const calendarApi = this.$refs.calendar.getApi();
            // generate a random id
            const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            const event = {
                title: '',
                id,
                start,
                end,
                display: 'background',
                color: '#ddd',
                extendedProps: {
                    isInactive: true,
                },
            };
            calendarApi.addEvent(event);
        },

        addBackgroundEvent(slot: Slot) {
            if (!slot) return;
            if (!slot.resource) return;
            if (slot.free) return;

            const { calendar } = this.$refs;
            if (!calendar) return;
            const calendarApi = this.$refs.calendar.getApi();
            // generate a random id
            const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            const event = {
                title: slot.free ? 'free' : 'busy',
                groupId: slot.resource.Id,
                id,
                start: slot.start,
                end: slot.end,
                // display: slot.free ? 'inverse-background' : 'background',
                // display: 'background',
                color: !slot.free ? slot.resource.Color : '#f00',
            };
            calendarApi.addEvent(event);
        },
    },
};
