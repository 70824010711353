var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5 mw-100"},[_c('div',{staticClass:"d-flex align-items-center p-3"},[_c('CoHeadline',{staticClass:"flex-shrink-0 mr-auto",attrs:{"level":2}},[_vm._v(_vm._s(_vm.$t('labels.billing')))]),_c('CoButton',{staticClass:"flex-shrink-1 ml-2",staticStyle:{"min-width":"0"},attrs:{"variant":"primary","label":_vm.$t('labels.paymentmethods'),"truncate":""},on:{"click":function($event){return _vm.$router.push('/account/billing/payment-methods')}}})],1),(_vm.billingAddress)?_c('div',{staticClass:"align-self-start mt-4 px-0 px-sm-4 overflow-hidden round-unify-xs"},[_c('CoCardBillingAddress',{attrs:{"billingAddress":_vm.billingAddress,"user-name":this.$store.state.me.Profile.Name,"save-billing-address-data":_vm.saveBillingAddressData}})],1):_vm._e(),_c('div',{staticClass:"px-sm-4"},[_c('div',{staticClass:"d-flex flex-wrap p-3 align-items-center justify-content-between"},[_c('CoText',{staticClass:"mb-0",attrs:{"text":_vm.$t('labels.invoices'),"variant":"mute"}})],1),_c('coTable',{attrs:{"items":_vm.allInvoices,"sortable":false,"loading":_vm.invoicesLoading,"preventHorizontalScroll":true,"resizableColumns":true,"columns":[
                {
                    title: _vm.$t('labels.date'),
                    key: 'Created',
                },
                {
                    title: _vm.$t('labels.status'),
                    key: 'Status',
                    class: 'd-none d-md-table-cell',
                },
                {
                    title: _vm.$t('labels.amount'),
                    key: 'Total',
                    class: 'text-right',
                },
                {
                    title: '',
                    key: 'Action',
                } ]},scopedSlots:_vm._u([{key:"Created",fn:function(slotProps){return [_c('co-date',{attrs:{"date":slotProps.item['Created'],"format":"exact"}})]}},{key:"Status",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.$t(slotProps.item.Status))+" ")]}},{key:"Total",fn:function(slotProps){return [_c('co-price',{attrs:{"inheritTextSizing":"","priceObj":{
                        PriceInCents: slotProps.item['Total'],
                        Currency: slotProps.item['Currency'],
                    }}})]}},{key:"Action",fn:function(slotProps){return [(
                        slotProps.item && (slotProps.item.Status === 'open' || slotProps.item.Status === 'overdue')
                    )?_c('CoLink',{attrs:{"isExternalLink":true,"newTab":true,"to":slotProps.item.HostedInvoiceURL}},[_vm._v(" "+_vm._s(_vm.$t('labels.pay'))+" ")]):(slotProps.item && slotProps.item.InvoicePDF)?_c('CoLink',{attrs:{"isExternalLink":true,"newTab":true,"to":slotProps.item.HostedInvoiceURL}},[_vm._v(" "+_vm._s(_vm.$t('labels.view'))+" ")]):(slotProps.item && slotProps.item.InvoiceLines)?_c('CoLink',{attrs:{"isExternalLink":true},nativeOn:{"click":function($event){$event.preventDefault();return _vm.showInvoicePreview(slotProps.item)}}},[_vm._v(" "+_vm._s(_vm.$t('labels.preview'))+" ")]):_vm._e()]}}])})],1),_c('CoModal',{ref:"invoicePreviewModal",attrs:{"size":"lg","title":_vm.$t('labels.invoicepreview')}},[_c('template',{slot:"body"},[_c('co-invoice-detail',{attrs:{"invoice":_vm.previewInvoice}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }