<template>
    <div class="d-flex justify-content-center align-items-md-center w-100">
        <vue-headful
            :title="'Login: ' + this.$store.state.space.Name + ' Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />
        <!-- user login -->
        <div class="login-form">
            <CoCard>
                <div class="d-flex alig-items-center justify-content-center">
                    <CoHeadline :level="2" class="px-2">{{ $t('labels.login') }}</CoHeadline>
                </div>

                <!-- error -->
                <div v-if="error">
                    <CoAlert
                        size="md"
                        variant="red"
                        :text="
                            error.response && error.response.status === 401
                                ? $t('messages.invalidcredentials')
                                : error.message
                        "
                    />
                </div>

                <!-- email -->
                <CoFormGroup :label="$t('labels.email')" class="mb-0">
                    <CoInput
                        id="email"
                        ref="email"
                        v-model="username"
                        name="email"
                        type="email"
                        :placeholder="$t('placeholders.email')"
                        required
                        :disabled="loading"
                        :validator="validateEmail"
                        :validationHint="$t('errors.mustBeValidEmail')"
                        autocomplete="email"
                    ></CoInput>
                </CoFormGroup>

                <!-- password -->
                <CoFormGroup :label="$t('labels.password')">
                    <CoInput
                        id="password"
                        ref="password"
                        v-model="password"
                        name="password"
                        type="password"
                        :placeholder="$t('placeholders.password')"
                        required
                        :disabled="loading"
                        :validationHint="$t('messages.passwordlength')"
                        :validator="validatePassword"
                        autocomplete="current-password"
                        @enter="login"
                    ></CoInput>
                </CoFormGroup>

                <!-- login button -->
                <div class="d-flex justify-content-between">
                    <!-- password reset -->
                    <CoButton variant="text" @click="routerPush('/reset-password')" class="mr-2" :disabled="loading">
                        <CoText type="p2" style="color: var(--c-darkgrey)">{{ $t('labels.passwordreset') }}</CoText>
                    </CoButton>
                    <CoButton :disabled="loading" :loading="loading" variant="primary" @click="login">{{
                        $t('labels.login')
                    }}</CoButton>
                </div>

                <OA2Providers :is-login="true" back-ground-color="white" />
            </CoCard>
        </div>
        <!-- user login -->

        <!-- modal alert about invalid link -->
        <CoModal ref="invalidInviteLinkMessageModal" background="white">
            <template v-slot:header>
                <CoHeadline :level="2" class="px-2">{{ $t('labels.linkInvalid') }}</CoHeadline>
            </template>
            <template v-slot:body>
                <CoText class="px-2">
                    {{ $t('labels.linkInvalidDescription') }}
                </CoText>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between align-items-center">
                    <CoButton variant="text" @click="removeInvalidInviteLink()">Close</CoButton>
                    <CoButton variant="primary" @click="retryInvalidInviteLink()">Try again</CoButton>
                </div>
            </template>
        </CoModal>
    </div>
</template>

<style lang="less" scoped>
.login-form {
    width: 100%;
    @media (min-width: 500px) {
        width: 340px;
    }
}
</style>

<script>
import i18n from 'vue-i18n';
import { get } from 'lodash';
import { isEmail } from 'validator';
import Bugsnag from '@bugsnag/js';
import OA2Providers from '@/components/auth_methods/OA2Providers.vue';

import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Login',
    i18n: {
        // `i18n` option, setup component specific language and translations
        messages: {
            en: {
                messages: {
                    invalidcredentials:
                        'This combination of email and password does not exist. Please double-check and try again.',
                    newhere: 'New to {space}?',
                    signup: 'Sign up here.',
                },
            },
            de: {
                messages: {
                    invalidcredentials:
                        'Diese Kombination aus E-Mail und Passwort existiert nicht. Bitte überprüfe die Eingaben und versuche es erneut.',
                    newhere: 'Neu bei {space}?',
                    signup: 'Registriere dich hier.',
                },
            },
        },
    },
    components: {
        OA2Providers,
        CoButton,
        CoModal,
        CoHeadline,
        CoText,
        CoCard,
        CoFormGroup,
        CoInput,
        CoAlert,
    },
    data() {
        return {
            username: null,
            password: null,
            error: null,

            loading: false,
            redirect: null,
            messageHeading: 'Du bist noch nicht Mitglied?',
            messageText: 'Einfach ab 15 € monatlich ausprobieren',
            btnText: 'Mitglied werden',
            btnTitle: 'Hafven Mitgliedschaftstarife ansehen',
            btnLink:
                'https://hafven.de/tarife?utm_source=community%20web%20app&utm_medium=login%20screen&utm_campaign=mitglied%20werden',

            invalidInviteLink: this.$route.query.invalidInviteLink,
        };
    },
    mounted() {
        this.$store.commit('RESET_MEMBERSHIP_PROVIDER');

        if (this.invalidInviteLink) {
            this.$refs.invalidInviteLinkMessageModal.show();
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // eslint-disable-next-line no-param-reassign
            vm.redirect = to.query.redirect;
        });
    },
    methods: {
        // routerPush function that wraps the router push function and catches errors
        // it will ignore the error if the error is a NavigationDuplicated error or Redundant Navigation error and will not log it to bugsnag
        // this is because the error is thrown when the user tries to navigate to the same route they are currently on and is not a real error
        routerPush(route) {
            this.$router.push(route).catch((error) => {
                if (
                    error.name !== 'NavigationDuplicated' &&
                    error.name !== 'NavigationCancelled' &&
                    error.name !== 'Redundant Navigation'
                ) {
                    Bugsnag.notify(error);
                }
            });
        },
        removeInvalidInviteLink() {
            this.invalidInviteLink = null;
            // remove from url
            this.$router.replace({ query: {} });
            this.$refs.invalidInviteLinkMessageModal.hide();
        },
        retryInvalidInviteLink() {
            this.$refs.invalidInviteLinkMessageModal.hide();
            this.$router.go(this.invalidInviteLink);
        },

        login() {
            this.error = null;
            if (
                !this.username ||
                !this.validateEmail(this.username) ||
                !this.password ||
                !this.validatePassword(this.password)
            ) {
                this.error = {
                    message: this.$t('errors.invalidLoginCredentials'),
                };
                // set focus to first invalid input
                if (!this.username || !this.validateEmail(this.username)) {
                    this.$refs.email.focus();
                } else if (!this.password || !this.validatePassword(this.password)) {
                    this.$refs.password.focus();
                }

                return;
            }
            // remove whitespace from email
            const username = this.username.trim().toLowerCase();

            this.loading = true;
            this.$store
                .dispatch('loginV2', {
                    email: username,
                    password: this.password,
                })
                .then(() => {
                    this.$store
                        .dispatch('getMe', null)
                        .then((response2) => {
                            this.$root.$data.$me = response2.data;
                            this.loading = false;
                            if (this.redirect && this.redirect !== '%2F' && this.redirect !== '/') {
                                this.routerPush(this.redirect);
                            } else {
                                this.routerPush('/');
                            }
                        })
                        .catch((error) => {
                            this.routerPush('/');
                            const { message } = error.response.data;
                            const err = String('missing or malformed jwt');

                            if (message === err) {
                                this.$store.commit('LOGOUT');
                            }
                        });
                })
                .catch((error) => {
                    // if status is 401 and error code 10003 redirect to password reset
                    this.loading = false;
                    // remove from error all config data
                    delete error.config;
                    delete error.request;
                    delete error.headers;

                    this.error = error;

                    Bugsnag.notify(error);

                    console.log(`Login error: ${error}`);

                    if (
                        (get(error, 'response.status') === 401 && get(error, 'response.data.Code') === '10003') ||
                        get(error, 'response.data').includes('must change password')
                    ) {
                        this.routerPush('/force-password-reset');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        validateEmail(value) {
            if (!value) {
                return false;
            }
            return isEmail(value);
        },

        validatePassword(value) {
            if (!value) {
                return false;
            }

            if (value.length < 1) {
                return false;
            }

            if (value.length > 56) {
                return false;
            }

            return true;
        },
    },
};
</script>
