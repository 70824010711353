import { render, staticRenderFns } from "./Bookings.vue?vue&type=template&id=03464a16&scoped=true&"
import script from "./Bookings.vue?vue&type=script&lang=js&"
export * from "./Bookings.vue?vue&type=script&lang=js&"
import style0 from "@/assets/less/manage-space/base.less?vue&type=style&index=0&id=03464a16&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03464a16",
  null
  
)

export default component.exports