import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { get, isNil } from 'lodash';
import { permission } from 'process';
import EventBus from './eventBus';
import Bugsnag from '@bugsnag/js';
import { v4 as uuidv4 } from 'uuid';

Vue.use(Vuex);

const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';
const SET_THREADS = 'SET_THREADS';

const errorMessages = {
    400: 'The combination of user name and password is not valid. Try again?',
};

const PlaceholderPlugin = (store) => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    });
};

// isLocalStorage checks if local storage available to detect wheather it is CNA popup or not
function isLocalStorage() {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
        // available
    } catch (e) {
        return false;
        // unavailable
    }
}

let presistant = PlaceholderPlugin;
if (isLocalStorage()) {
    try {
        presistant = createPersistedState();
    } catch (error) {
        Bugsnag.notify(error);
    }
}

export default new Vuex.Store({
    plugins: [presistant],
    state: {
        isLoggedIn: false,
        isLocalStorage: false,
        isInMobile: false,
        me: { Profile: { Initials: 'NA' } },
        notificationsNumber: 0,
        projectToEdit: null,
        space: {},
        eventDuplicate: null,

        // new membership
        newMembership: {
            Step: 0, // 0 select plan, 1 plan upgrades, 2 account, 3 address, 4 payment, 5 review
            Plan: null,
            Extras: [],
            Account: null,
            Address: null,
            CustomerID: '',
            PaymentMethodeID: '',
            ClientSecret: '',
            ExpriresAt: 0,
            OA2UserData: null,
            IsFromOA2Provider: false,
            InviteID: '',
        },
        // web socket state
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
        threads: null,
        newMessages: null,

        billingTurnedOn: false,
        circlesOn: false,

        breadcrumbs: [],

        invite: null, // invite object with prepopulated data

        permissions: {},

        intercomEventQueue: [],
    },
    /* eslint-disable no-param-reassign */
    mutations: {
        SET_PERMISSIONS(state, permissions) {
            state.permissions = permissions;
        },
        // Set isInMobile state
        SET_IS_IN_MOBILE(state, value) {
            state.isInMobile = value;
        },

        SET_SPACE_OBJECT_FOR_ADMIN(state, adminspaceobject) {
            state.space_configuration_admin = adminspaceobject;
        },
        SET_BREADCRUMBS(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        SET_CIRCLES_ON(state, value) {
            state.circlesOn = value;
        },
        // new membership mutations
        SET_INVITE_ID(state, id) {
            state.newMembership.InviteID = id;
        },
        SET_INVITE(state, invite) {
            state.invite = invite;
        },
        RESET_MEMBERSHIP_PROVIDER(state) {
            state.newMembership.IsFromOA2Provider = false;
            state.newMembership.OA2UserData = null;
        },
        SET_NEW_MEMBERSHIP_ACCOUNT_DATA(state, userData) {
            state.newMembership.IsFromOA2Provider = true;
            state.newMembership.OA2UserData = {
                FirstName: userData.FirstName,
                LastName: userData.LastName,
                Email: userData.Email,
            };
        },
        SET_COUPON(state, coupon) {
            state.newMembership.Coupon = coupon.Coupon;
        },
        RESET_NEW_MEMBERSHIP(state) {
            state.newMembership = {
                ...state.newMembership,
                Step: 0,
                Plan: null,
                Extras: [],
                Account: null,
                Address: null,
                CustomerID: '',
                PaymentMethodeID: '',
                ClientSecret: '',
                ExpriresAt: 0,
                Coupon: null,
            };
            if (state.newMembership.IsFromOA2Provider) {
                state.newMembership.Account = state.newMembership.OA2UserData;
            }
        },
        SET_STEP(state, step) {
            state.newMembership.Step = step;
        },
        PREVIOUS_STEP(state) {
            if (state.newMembership.Step === 3 && state.newMembership.IsFromOA2Provider) {
                if (get(state, 'newMembership.Plan.Extras', []).length === 0) {
                    state.newMembership.Step = 0;
                } else {
                    state.newMembership.Step = 1;
                }
                return;
            }
            if (state.newMembership.Step !== 0) {
                state.newMembership.Step -= 1;
            }
        },
        SET_PLAN(state, plan) {
            state.newMembership.Plan = plan;
            state.newMembership.Extras = [];
            const now = Math.round(new Date().getTime() / 1000);
            const later = now + 60 * 60;
            state.newMembership.ExpriresAt = later;
            state.newMembership.Step = 1;
        },
        SET_EXTRAS(state, extras) {
            state.newMembership.Extras = extras;
        },
        SAVE_EXTRAS(state, extras) {
            state.newMembership.Extras = extras;
            const now = Math.round(new Date().getTime() / 1000);
            const later = now + 60 * 60;
            state.newMembership.ExpriresAt = later;
            if (state.newMembership.IsFromOA2Provider) {
                state.newMembership.Step = 3;
            } else {
                state.newMembership.Step = 2;
            }
        },
        SET_ACCOUNT(state, account) {
            state.newMembership.Account = account;
            const now = Math.round(new Date().getTime() / 1000);
            const later = now + 60 * 60;
            state.newMembership.ExpriresAt = later;
            state.newMembership.Step = 3;
        },

        SET_ADDRESS(state, address) {
            state.newMembership.Address = address;
            const now = Math.round(new Date().getTime() / 1000);
            const later = now + 60 * 60;
            state.newMembership.ExpriresAt = later;
            state.newMembership.Step = 4;
        },

        SAVE_CUSTOMER_AND_CLIENT_SECRET(state, data) {
            state.newMembership.CustomerID = data.CustomerID;
            state.newMembership.ClientSecret = data.ClientSecret;
        },
        SET_PAYMENT_METHOD_ID(state, id) {
            state.newMembership.PaymentMethodeID = id;
        },
        CLEAR_CUSTOMER_AND_CLINENT_SECRET(state) {
            state.newMembership.CustomerID = null;
            state.newMembership.ClientSecret = null;
            state.newMembership.PaymentMethodeID = null;
        },
        TO_REVIEW(state) {
            const now = Math.round(new Date().getTime() / 1000);
            const later = now + 60 * 60;
            state.newMembership.ExpriresAt = later;
            state.newMembership.Step = 5;
        },

        SET_NEW_NOTIFICATIONS(state, n) {
            state.notificationsNumber = n;
            EventBus.$emit('NEWNOTIFICATIONS', n);
        },
        SET_TASKS_COUNT(state, n) {
            state.tasksNumber = n;
            EventBus.$emit('NEWTASKS', n);
        },
        DECREMENT_UNSEEN_NOTIFICATIONS(state) {
            if (state.notificationsNumber > 0) {
                state.notificationsNumber--;
            }
        },

        LOCALSTORAGE_TRUE(state) {
            state.isLocalStorage = true;
        },
        [LOGIN](state) {
            state.pending = true;
        },
        [LOGIN_SUCCESS](state) {
            state.isLoggedIn = true;
            state.pending = false;
        },
        LOGIN_SUCCESS(state) {
            state.isLoggedIn = true;
            state.pending = false;
        },
        SET_JWT(state, token) {
            window.localStorage.setItem('_authtoken', token);
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            state.isLoggedIn = true;
            state.pending = false;
        },
        [LOGOUT](state) {
            if (state.isLocalStorage) {
                window.localStorage.removeItem('vuex');
            }
            state.isLoggedIn = false;
            state.pending = false;
            state.me = { Profile: { Initials: 'NA' } };
        },
        GETME(state, me) {
            state.me = me;
        },
        GETSPACE(state, space) {
            state.space = space;
        },

        /**
         * websocket mutations
         */
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget;
            state.socket.isConnected = true;
        },
        SOCKET_ONCLOSE(state, event) {
            state.socket.isConnected = false;
        },
        SOCKET_ONERROR(state, event) {
            state.socket.isConnected = false;
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message;
            const msgObj = JSON.parse(message.data);
            if (msgObj.thread_id != null) {
                EventBus.$emit(`MESSAGE_${msgObj.thread_id}`, msgObj);
                EventBus.$emit(`MESSAGETOAST`, msgObj);
                EventBus.$emit('NEWMESSAGES', 1);
            }
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {},
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },

        SET_THREADS(state, threads) {
            state.threads = threads;
        },
        SET_THREAD(state, thread, key) {
            state.threads[key] = thread;
        },

        SET_NEW_MESSAGES_NUMBERS(state, data) {
            state.newMessages = data;
            EventBus.$emit('NEWMESSAGES', get(data, 'TotalNumber', 0));
        },
        DECREMENT_UNSEEN_MESSAGES(state, threadID) {
            if (state.newMessages != null && state.newMessages.TotalNumber && state.newMessages.TotalNumber != 0) {
                state.newMessages.TotalNumber--;
            }

            if (
                state.newMessages != null &&
                state.newMessages.MessagesPerThread &&
                state.newMessages.MessagesPerThread[threadID] != 0
            ) {
                state.newMessages.MessagesPerThread[threadID]--;
            }
            EventBus.$emit('NEWMESSAGES', get(state, 'newMessages.TotalNumber', 0));
        },

        SET_PAYMENT_READY_STATE(state, value) {
            state.billingTurnedOn = value;
        },
        // intercom event qeue
        ADD_TO_INTERCOM_QEUE(state, value) {
            state.intercomEventQueue.push({
                id: uuidv4(),
                data: value,
            });
        },
        REMOVE_FROM_INTERCOM_QEUE(state, value) {
            if (!value) return;
            state.intercomEventQueue = state.intercomEventQueue.filter((item) => item.id !== value.id);
        },
    },
    /* eslint-disable no-param-reassign */

    actions: {
        /**
         *
         * @param {*} param0
         * @returns
         */
        getDashboardURL({ _ }, dashboardNumber) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/statistics?dashboard=${dashboardNumber}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data.DashboardURL);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        sendMessage(context, message) {
            Vue.prototype.$socket.send(message);
        },
        getOpenGraph({ commit }, url) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/dashboard/og?url=${url}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getNewMessagesNumbers({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/chat/new',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.data) {
                            commit('SET_NEW_MESSAGES_NUMBERS', response.data);
                        }
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // spaces
        getSpaceSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/space/settings',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        commit('GETSPACE', response.data);
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // dahboard
        getFeed({ commit }, next) {
            let url = '/dashboard/feed/v2';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // actions for projects
        getProjectsByNewest({ commit }, next) {
            let url = '/project/list/newest';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // actions for profiles
        getCommunity({ commit }, next) {
            let url = '/user/profile/list';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // actions for Comment
        deleteComment({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/comment',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        deleteCommentAdmin({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/admin/comment',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createComment({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/comment',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        listCommentsForPost({ commit }, url) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url, // todo make comments pagination
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // actions for Posts(ShoutOut)
        getPostByID({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/post/by-id/${id}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deletePost({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/post',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deletePostAdmin({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/admin/post',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createUserNoteAdmin({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/admin/notes',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getPostBySlug({ commit }, slug) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/post/by-slug/${slug}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createPost({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/post',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        updatePost({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/post',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getPosts({ commit }, next) {
            let url = '/post/list';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        addEmoji({ commit }, reaction) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/v1/reactions',
                    data: reaction,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        deleteEmoji({ commit }, reaction) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/v1/reactions',
                    data: reaction,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // action for events
        createEvent({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/event',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        updateEvent({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/event',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        adminUpdateEvent({ commit }, object) {
            const data = JSON.stringify(object);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/admin/event',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /**
         * getEventBySlug return event for given slug name
         * @param {*} param0
         * @param {*} slug
         */
        getEventBySlug({ commit }, slug) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/event/by-slug/${slug}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // getevents v2
        getEventsV2({ commit }, next) {
            let url = '/event/list';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        searchEvents(_, input) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/search/event/${input}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        searchMembers(_, input) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/search/user/${input.searchText}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: input.cancelToken,
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminSearchMembers(_, input) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/member/search/${input.searchText}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: input.cancelToken,
                })
                    .then((response) => {
                        resolve(response.data.objects);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        addParticipant({ commit }, request) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/event/participant',
                    data: request,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeParticipant({ commit }, request) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/event/participant',
                    data: request,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        addOrganizer({ commit }, request) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/admin/event/organizer',
                    data: request,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeOrganizer({ commit }, request) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/admin/event/organizer',
                    data: request,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // get past events
        getPastEvents({ commit }, next) {
            let url = '/event/list/past';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // getevents
        getEvents({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/event/list',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Actions for events
        listEventTypes({ commit }, path) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/event/type/list?${path}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createEventType({ commit }, name) {
            const data = JSON.stringify({ Value: name });
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/admin/event/type',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        listEventCategories({ commit }, path) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/event/category/list?${path}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createEventCategory({ commit }, name) {
            const data = JSON.stringify({ Value: name });
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/admin/event/category',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // actions for plans

        editPlan({ commit }, object) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/admin/plan/update',
                    data: object,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getPlanByID({ commit }, ID) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/plan/${ID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Actions for Time Passes

        editTimepass({ commit }, object) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/admin/time-pass',
                    data: object,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createTimepass({ commit }, object) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/admin/time-pass',
                    data: object,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deleteTimepass({ commit }, object) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: 'admin/time-pass',
                    data: object,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // Actions for Tags
        /**
         * getTagsByIDs - return tags for given ids
         * @param {*} param0
         * @param {*} IDs {"IDS": [strings]}
         */
        getTagsByIDs({ commit }, IDs) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/tags/listbyids',
                    data: IDs,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        /**
         * createTag - creates tag for input vaue
         * @param {"Value": "string"}
         */
        createTag({ commit }, tag) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/tags',
                    data: tag,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /**
         * searchTag - returns search result and autocomplete suggestions for input
         * @param queryparam term
         */
        searchTag({ commit }, term) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/tags/search?term=${term}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // Captive page methodes
        checkin({ commit }, wifievent) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/guest/checkin',
                    data: wifievent,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        logincheckin({ commit }, wifievent) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/guest/login-and-checkin',
                    data: wifievent,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        loginWithHardwareSession({ commit }, wifievent) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/guest/login-with-hardware-session',
                    data: wifievent,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // Notifications methodes
        setNotificationAsSeen({ commit }, notification) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/notification',
                    data: notification,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getGetNewNotifications({ commit }) {
            return new Promise(async (resolve, reject) => {
                let notifications = {};
                let notificationsCount = 0;
                let agreements = {};
                let agreementsCount = 0;

                try {
                    notifications = await axios.get('/notification/new');
                } catch (err) {}

                try {
                    agreements = await axios.get('/user/new-agreements');
                } catch (err) {}

                notificationsCount = get(notifications, 'data.Notifications.length', 0);
                agreementsCount = get(agreements, 'data.length', 0);

                commit('SET_NEW_NOTIFICATIONS', notificationsCount);
                commit('SET_TASKS_COUNT', agreementsCount);
                resolve(get(notifications, 'data', {}));
            });
        },

        getAllNotifications({ commit }, next) {
            let url = '/notification/list';
            if (next) {
                url = next;
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getMyProjects({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/project/my',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getMyBookings({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/booking/my',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getBookingCreditsV2({ commit }, resourceID) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/booking/v2/credits/${resourceID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getMyBookingsV2({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/booking/v2/my',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        createBookingV2({ commit }, booking) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/booking/v2/resources',
                    data: booking,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        cancelBooking({ commit }, bookingid) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: `/booking/${bookingid}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        cancelBookingV2({ commit }, bookingid) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: `/booking/v2/${bookingid}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getSlots({ commit }, requestparams) {
            const { resourceID } = requestparams;
            delete requestparams.resourceID;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    params: requestparams,
                    url: `/booking/resources/${resourceID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /*
     getResources return list of bookable resources sorted by spaces(booking groups)
     for given resource type `machines` or `formeeting`
   */

        getResources({ commit }, type) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/booking/resources/${type}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        // project updates
        getProjectUpdate({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/project/update/by-id/${id}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deleteProjectUpdate({ commit }, projectUpdate) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/project/update',
                    data: projectUpdate,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        adminDeleteProjectUpdate({ commit }, projectUpdate) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/admin/project-update',
                    data: projectUpdate,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        updateProjectUpdate({ commit }, projectUpdate) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/project/update',
                    data: projectUpdate,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        addUpdate({ commit }, update) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/project/update',
                    data: update,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getUpdates({ commit }, queryparams) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/project/update/list?${queryparams}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getUpdatesCircle({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: `/project/update/list/circles`,
                    data: JSON.stringify(params),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /**
         *
         * @param {*} param0
         * @param {*} owner
         */
        addOwner({ commit }, owner) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/project/owners',
                    data: owner,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        addContributor({ commit }, contributor) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/project/contributors',
                    data: contributor,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeOwner({ commit }, owner) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/project/owners',
                    data: owner,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        removeContributor({ commit }, contributor) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/project/contributors',
                    data: contributor,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        deleteProject({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/project',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        deleteProjectAdmin({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/admin/project',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateProject({ commit }, project) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/project',
                    data: project,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /*
    listProjectsByIDs reqiests list of Projects for given id list from backend
    :param IDs should have format below

    {
      "IDS" :[
      {
        "ID": "testid"
      },
      {
        "ID": "testid2"
      },
      {
        "ID": "testi3"
      }
      ]
    }
    */
        listProjectsByIDs({ commit }, IDs) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/project/listbyids',
                    data: IDs.ids,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getProjectBySlug({ commit }, slug) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/project/by-slug/${slug}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /*
    listUsersByIDs reqiests list of users for given id list from backend
    :param IDs should have format below

    {
      "IDS" :[
      {
        "ID": "testid"
      },
      {
        "ID": "testid2"
      },
      {
        "ID": "testi3"
      }
      ]
    }
    */
        deleteJobAdmin({ commit }, job) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: '/admin/job',
                    data: job,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        listUsersByIDs({ commit }, IDs) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/user/listbyids',
                    data: IDs.ids,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getUserBySlug({ commit }, slug) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/user/profile/by-slug/${slug}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        detachPaymentMethod({ commit }, methodID) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: `/user/account/billing/delete-payment-method`,
                    data: JSON.stringify({ ID: methodID }),
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        detachPaymentMethodAdmin({ commit }, body) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'DELETE',
                    url: `/admin/payment/stripe/payment-methods/${body.userId}/${body.methodID}`,
                    data: JSON.stringify({ ID: body.methodID }),
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        adminGetUserByID({ commit }, userID) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/community/member/${userID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        /*
      imageUpload uplodes image to s3 backed through gateway
      :param file should be an image file fro input type file
      :param url it is a path which describes in which folder on s3 will be hosted file
      url example /dashboard/image/projects-headers =>
      'projects-headers'  here is a name for folder, if folder does not exist, it will be created
      ':response return a json with url inside with key url
    */
        imageUpload({ commit }, kwargs) {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                data.append('file', kwargs.file);
                axios({
                    method: 'POST',
                    url: kwargs.url,
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'image/*',
                    },
                })
                    .then((response) => {
                        resolve(response.data.url);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateUser({ commit }, userForm) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/dashboard/me',
                    withCredentials: true,
                    data: userForm,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        const string = response.data.Profile.Name.split(' ');
                        let initials = '';
                        for (let i = 0; i < string.length; i++) {
                            initials += string[i][0];
                        }
                        response.data.Profile.Initials = initials;
                        commit('GETME', response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminDeleteUser({ commit }, ID) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: `/admin/community/member/delete/${ID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateEmail({ commit }, userForm) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/user/set-new-email',
                    withCredentials: true,
                    data: userForm,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminUpdateOtherUser({ commit }, userForm) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'PUT',
                    url: '/admin/community/member/',
                    withCredentials: true,
                    data: userForm,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        searchUser({ commit }, input) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/search/user/${input.input}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        searchAutocomplete({ commit }, input) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/search/autocomplete/${input.input}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getMe({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/dashboard/me',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        const string = response.data.Profile.Name.split(' ');
                        let initials = '';
                        for (let i = 0; i < string.length; i++) {
                            initials += string[i][0];
                        }
                        response.data.Profile.Initials = initials;
                        commit(LOGIN_SUCCESS);
                        commit('GETME', response.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getSignupSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/space/signup-settings',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        validateSignupInvite({ commit }, token) {
            const data = {
                InviteID: token,
            };
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: `/user/invite/validate`,
                    withCredentials: true,
                    data,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response.data) {
                            commit('SET_INVITE', response.data);
                            resolve(response.data);
                        } else {
                            commit('SET_INVITE', null);
                            reject(response);
                        }
                    })
                    .catch((error) => {
                        commit('SET_INVITE', null);
                        reject(error);
                    });
            });
        },
        signup({ commit }, data) {
            commit(LOGIN); // show spinner
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/user/signup',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response) {
                            const token = response.data;
                            commit('SET_JWT', token);
                        }
                        commit(LOGIN_SUCCESS);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        isAuthenticated({ commit }, token) {
            if (token == null) {
                token = localStorage.getItem('_authtoken');
            }
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/user/is-authenticated',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        loginV2({ commit }, creds) {
            commit(LOGIN); // show spinner
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/user/loginV2',
                    data: creds,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        const { token } = response.data;
                        if (isLocalStorage()) {
                            localStorage.setItem('_authtoken', token);
                            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
                            commit(LOGIN_SUCCESS);
                            resolve();
                        } else {
                            reject({ message: 'Application requires acces to local storage.' });
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status && errorMessages[error.response.status]) {
                            reject({ message: errorMessages[error.response.status] });
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/user/logout',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        commit(LOGOUT);
                        resolve();
                    })
                    .catch((error) => {
                        if (error.response && error.response.status && errorMessages[error.response.status]) {
                            reject({ message: errorMessages[error.response.status] });
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        getInvitedUsers({ _ }, url) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        getPaymentMethods() {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/user/account/billing/payment-methods',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        adminGetPaymentMethods({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/admin/payment/stripe/payment-methods/${data.ID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        listLocations() {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/admin/location/list',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data.Locations);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        listZapierApiKeys() {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/admin/zapier/api-key/list',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        createZapierApiKey() {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/admin/zapier/api-key',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        fetchAuthMethods() {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: '/auth-method/',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getAuthMethodURL(_, methodID) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'GET',
                    url: `/auth-method/${methodID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(get(response, 'data.url'));
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        authenticateWithOA2Provider({ commit }, body) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'POST',
                    url: '/auth-method/authenticate',
                    data: body,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        // login
                        if (!isNil(get(response, 'data.token', null))) {
                            localStorage.setItem('_authtoken', response.data.token);
                            axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
                            commit(LOGIN_SUCCESS);
                            resolve({
                                isLogin: true,
                            });
                        }
                        // sign up
                        if (!isNil(get(response, 'data.userData', null))) {
                            commit('SET_NEW_MEMBERSHIP_ACCOUNT_DATA', response.data.userData);
                            resolve({
                                isLogin: false,
                            });
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
    getters: {
        state: (state) => state,
        isAuthenticated: (state) => state.isLoggedIn,
    },
});
