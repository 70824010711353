

















































































































































































































































































































































































































































































































































import Vue from 'vue';
import i18n from 'vue-i18n';

import EventBus from '@/eventBus';
import axios from 'axios';
import CoSkeleton from '../../../../Atoms/co-skeleton/CoSkeleton.vue';
import CoCard from '../../../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../../../Atoms/co-headline/CoHeadline.vue';
import CoButton from '../../../../Atoms/co-button/CoButton.vue';
import CoCheckbox from '../../../../Atoms/co-checkbox/CoCheckbox.vue';

export default {
    i18n: {
        messages: {
            en: {
                commentOnPost: 'Someone has commented on my post',
                mentionInPost: 'Someone mentioned me in a post',
                mentionInComment: 'Someone mentioned me in a comment',
                commentUnderPostIInteractedWith: 'Someone commented under a post I interacted with',
                reactionOnPost: 'Someone reacted on a post',
                reminderAboutUpcommingEvents: 'Reminder about upcoming events',
                newRSVPinMyEvent: 'New attendee in my event',
                post_in_event_i_attend: 'New post in an event I attend',
                newFollowerInMyPage: 'New follower in my page',
                iWasAddedToPage: 'I was added to a page as owner or contributor',
                postInPageIFollow: 'New post created in a page I follow',
                marketItemInterest: 'New Interest in my market item',
                announcement: 'Announcement was made',
                newMessage: 'New message',
                membershipPlanChange: 'Membership plan changed',
                membershipPlanRenewal: 'Membership plan renewed',
                membershipPlanCancellation: 'Membership plan cancelled',
                membershipPlanUpcomingChangeCancelled: 'Membership plan upcoming change cancelled',
                membershipPlanCreated: 'Membership plan created',
                userCreated: 'User created',
                userWaitingForApproval: 'User waiting for approval',
                userWaitingForApprovalButApprovedDomain: 'User waiting for approval but with approved domain email',
                content_that_might_interest: 'Content that might interest me',

                admin_post_created: 'User created a post',
                admin_public_page_created: 'User created a page',
                admin_event_created: 'User created an event',
                admin_update_in_public_page_created: 'User posted an update in a page',
                admin_market_item_created: 'User created a market item',
            },
            de: {
                commentOnPost: 'Jemand hat meinen Post kommentiert',
                mentionInPost: 'Jemand hat mich in einem Post erwähnt',
                mentionInComment: 'Jemand hat mich in einem Kommentar erwähnt',
                commentUnderPostIInteractedWith:
                    'Jemand hat unter einem Post kommentiert, mit dem ich interagiert habe',
                reactionOnPost: 'Jemand hat auf einen Post reagiert',
                reminderAboutUpcommingEvents: 'Erinnerung an bevorstehende Veranstaltungen',
                newRSVPinMyEvent: 'Neuer Teilnehmer an meiner Veranstaltung',
                post_in_event_i_attend: 'Neuer Beitrag in einer Veranstaltung, an der ich teilnehme',
                newFollowerInMyPage: 'Neuer Follower auf meiner Seite',
                iWasAddedToPage: 'Ich wurde als Besitzer oder Mitwirkender zu einer Seite hinzugefügt',
                postInPageIFollow: 'Neuer Beitrag in einer Seite, der ich folge',
                marketItemInterest: 'Neues Interesse an meinem Marktartikel',
                announcement: 'Ankündigung wurde gemacht',
                newMessage: 'Neue Nachricht',
                membershipPlanChange: 'Mitgliedschaftsplan geändert',
                membershipPlanRenewal: 'Mitgliedschaftsplan erneuert',
                membershipPlanCancellation: 'Mitgliedschaftsplan gekündigt',
                membershipPlanUpcomingChangeCancelled: 'Bevorstehende Änderung des Mitgliedschaftsplans abgesagt',
                membershipPlanCreated: 'Mitgliedschaftsplan erstellt',
                userCreated: 'Benutzer erstellt',
                userWaitingForApproval: 'Benutzer wartet auf Genehmigung',
                userWaitingForApprovalButApprovedDomain:
                    'Benutzer wartet auf Genehmigung, aber mit genehmigter Domain-E-Mail',
                content_that_might_interest: 'Inhalt, der mich interessieren könnte',

                admin_post_created: 'Benutzer hat einen Beitrag erstellt',
                admin_public_page_created: 'Benutzer hat eine Seite erstellt',
                admin_event_created: 'Benutzer hat eine Veranstaltung erstellt',
                admin_update_in_public_page_created: 'Benutzer hat ein Update auf einer Seite veröffentlicht',
                admin_market_item_created: 'Benutzer hat einen Marktartikel erstellt',
            },
        },
    },
    name: 'NotificationsPreferences',

    components: {
        CoSkeleton,
        CoCard,
        CoHeadline,
        CoButton,
        CoCheckbox,
    },
    props: {
        admin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            // default settings for all keys used to display the checkboxes
            settingsAllKeys: {
                announcement: { push: true, email: true },
                comment_on_post: { push: true, email: true },
                comment_under_post_i_interacted_with: { push: true, email: true },
                i_was_added_to_page: { push: true, email: true },
                market_item_interest: { push: true, email: true },
                membership_plan_cancellation: { push: true, email: true },
                membership_plan_change: { push: true, email: true },
                membership_plan_created: { push: true, email: true },
                membership_plan_renewal: { push: true, email: true },
                membership_plan_upcoming_change_cancelation: { push: true, email: true },
                mention_in_comment: { push: true, email: true },
                mention_in_post: { push: true, email: true },
                new_follower_in_page: { push: true, email: true },
                new_message: { push: true, email: true },
                new_rsvp_in_event: { push: true, email: true },
                post_in_event_i_attend: { push: true, email: true },
                post_in_page_i_follow: { push: true, email: true },
                reaction_on_post: { push: true, email: true },
                reminder_about_upcoming_events: { push: true, email: true },
                user_created: { push: true, email: true },
                user_waiting_for_approval: { push: true, email: true },
                user_waiting_for_approval_but_approved_domain: { push: true, email: true },

                content_that_might_interest: { push: true, email: true },
                admin_post_created: { push: true, email: true },
                admin_public_page_created: { push: true, email: true },
                admin_event_created: { push: true, email: true },
                admin_update_in_public_page_created: { push: true, email: true },
                admin_market_item_created: { push: true, email: true },
            },
            settings: {
                announcement: { push: true, email: true },
                comment_on_post: { push: true, email: true },
                comment_under_post_i_interacted_with: { push: true, email: true },
                i_was_added_to_page: { push: true, email: true },
                market_item_interest: { push: true, email: true },
                membership_plan_cancellation: { push: true, email: true },
                membership_plan_change: { push: true, email: true },
                membership_plan_created: { push: true, email: true },
                membership_plan_renewal: { push: true, email: true },
                membership_plan_upcoming_change_cancelation: { push: true, email: true },
                mention_in_comment: { push: true, email: true },
                mention_in_post: { push: true, email: true },
                new_follower_in_page: { push: true, email: true },
                new_message: { push: true, email: true },
                new_rsvp_in_event: { push: true, email: true },
                post_in_event_i_attend: { push: true, email: true },
                post_in_page_i_follow: { push: true, email: true },
                reaction_on_post: { push: true, email: true },
                reminder_about_upcoming_events: { push: true, email: true },
                user_created: { push: true, email: true },
                user_waiting_for_approval: { push: true, email: true },
                user_waiting_for_approval_but_approved_domain: { push: true, email: true },

                content_that_might_interest: { push: true, email: true },
                admin_post_created: { push: true, email: true },
                admin_public_page_created: { push: true, email: true },
                admin_event_created: { push: true, email: true },
                admin_update_in_public_page_created: { push: true, email: true },
                admin_market_item_created: { push: true, email: true },
            },
            originalSettings: {},
        };
    },
    mounted() {
        this.getSettings();
    },
    methods: {
        wereChanged() {
            return JSON.stringify(this.settings) !== JSON.stringify(this.originalSettings);
        },
        getSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/notification/preferencesV2',
            })
                .then((response) => {
                    if (response && response.data && response.data.settings) {
                        // if settings do not contain all keys, add them with default values
                        const tmp = JSON.parse(JSON.stringify(this.settingsAllKeys));
                        this.settings = response.data.settings;
                        for (const key in tmp) {
                            if (!this.settings[key]) {
                                this.settings[key] = tmp[key];
                            }
                        }
                        this.originalSettings = JSON.parse(JSON.stringify(this.settings));
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                });
        },
        saveChanges() {
            this.loading = true;
            axios({
                method: 'PUT',
                data: { settings: this.settings },
                url: '/notification/preferencesV2',
            })
                .then((response) => {
                    if (response && response.data && response.data.settings) {
                        const tmp = JSON.parse(JSON.stringify(this.settingsAllKeys));
                        this.settings = response.data.settings;
                        for (const key in tmp) {
                            if (!this.settings[key]) {
                                this.settings[key] = tmp[key];
                            }
                        }
                        this.originalSettings = JSON.parse(JSON.stringify(this.settings));

                        EventBus.$emit('INFO', {
                            Message: this.$t('labels.changessaved'),
                        });
                    }
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedToSaveChanges'),
                    });
                })
                .finally(() => {
                    // timeout
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                });
        },
        discard() {
            this.getSettings();
        },
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
    },
};
