



































































import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';

// Item represents a single item in the selector
interface Item {
    Name: string;
    Value: string | number | boolean | null | undefined | object;
    Disabled: boolean;
}

export default {
    name: 'CoSelect',
    components: { CoIcon, CoText },
    props: {
        value: {
            type: [String, Number, Boolean, Object, null, undefined],
            default: null,
        },
        items: {
            type: Array,
            default: () => [] as Item[],
        },
        minWidth: {
            type: Boolean,
            default: false,
        },

        variant: {
            type: String,
            default: 'default',
            validator: (value: string) => ['default', 'outline', 'grey'].includes(value),
        },
        placeholder: {
            type: String,
            default: 'Select',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: true,
        },
        iconPosition: {
            type: String,
            default: 'right',
            validator: (value: string) => ['left', 'right'].includes(value),
        },
        // if set to true, the select will select the first item in the list if no value is set
        // (if no value is set and this is false, the select will be empty)
        selectFirstItem: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            selectedItem: null as Item | null,
            selectOpen: false,
            selectedNative: null,
        };
    },
    watch: {
        value(newValue) {
            if (newValue && this.items.length > 0) {
                this.selectedItem = this.items.find((item) => item.Value === newValue.Value);
            } else if (this.items.length > 0) {
                this.selectedItem = this.items.find((item) => item.Value === newValue.Value);
            } else {
                this.selectedItem = null;
            }
        },
        items(newValue) {
            if (this.value && newValue.length > 0) {
                this.selectedItem = newValue.find((item) => item.Value === this.value.Value);
            } else if (newValue.length > 0 && this.selectFirstItem) {
                this.selectedItem = newValue[0];
            } else {
                this.selectedItem = null;
            }
        },
    },
    computed: {
        classes() {
            return {
                'bg-grey': this.variant === 'grey',
                'outline-grey': this.variant === 'outline',
                'with-min-width': this.minWidth,
                'icon-left': this.iconPosition === 'left',
                'icon-right': this.iconPosition === 'right',
                expanded: this.selectOpen,
                disabled: this.disabled,
            };
        },
    },
    created() {
        if (this.items.length > 0 && this.value) {
            this.selectedItem = this.items.find((item) => item.Value === this.value.Value);
        } else if (this.items.length > 0 && this.selectFirstItem) {
            this.selectedItem = this.items[0];
        }
    },
    mounted() {
        if (this.value && this.items.length > 0) {
            this.selectedItem = this.items.find((item) => item.Value === this.value.Value);
        } else if ((!this.placeholder || this.placeholder === '') && this.items[0]) {
            [this.selectedItem] = this.items;
        }

        // handle keyboard events
        document.addEventListener('keydown', this.handleKeyDown);
    },

    methods: {
        handleKeyDown(event) {
            if (!this.selectOpen) {
                return;
            }
            // if user pressed letter keys, focus the first item that starts with that letter
            if (event.key.length === 1 && !event.ctrlKey && !event.metaKey && !event.altKey && !event.shiftKey) {
                const firstItem = this.items.find((item) => item.Name[0].toLowerCase() === event.key.toLowerCase());
                if (firstItem) {
                    const option = this.$refs[firstItem.Name];
                    // scroll to the option if it's not visible
                    if (option) {
                        option[0].scrollIntoView();
                    }
                }
            }
        },
        openSelectItems(event) {
            if (this.disabled) {
                return;
            }
            // Use native selects (which pop up large native UIs to go through the options ) on iOS/Android
            if (navigator && navigator.userAgent && navigator.userAgent.match(/iPad|iPhone|Android/i)) {
                const new_event = new MouseEvent('mousedown');
                this.$refs.nativeSelect.dispatchEvent(new_event);
            } else {
                this.selectOpen = !this.selectOpen;
            }
        },
        select(item) {
            this.selectedItem = item;
            this.$emit('select', item);
            this.selectOpen = false;
        },
        nativeSelectHandler() {
            if (this.selectedNative) {
                this.select(this.selectedNative);
            }
        },
    },
};
